export enum PaymentProviderType {
  VivaWallet = 1,
  Stripe = 2,
}

export function paymentProviderTypeToString(pt: PaymentProviderType): string {
  switch (pt) {
    case PaymentProviderType.VivaWallet:
      return 'VivaWallet';
    case PaymentProviderType.Stripe:
      return 'Stripe';
    default:
      return 'Unknown';
  }
}
