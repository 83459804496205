import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { SnackBarInfo } from '../../Model/SnackBarInfo';
import { EmptyUserInfo } from '../../Model/UserInfo';
import { useGetKBQuery } from '../../Service/KB';
import {
  UpdateMeRequest,
  useGetMeQuery,
  useUpdateMeMutation,
} from '../../Service/Me';

export const EditProfile = () => {
  const schema = Yup.object().shape({
    FirstName: Yup.string().max(50).required('First name is required'),
    LastName: Yup.string().max(50).required('Last name is required'),
    PhoneNumber: Yup.string(),
    StreetAddress: Yup.string(),
    City: Yup.string().max(50),
    Province: Yup.string().max(50),
    CountryID: Yup.number(),
    PostalCode: Yup.string().max(5),
  });

  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarInfo>();

  const handleOnCloseSnackBar = (): void => {
    setSnackBarInfo({
      message: '',
      severity: 'success',
      open: false,
    });
  };

  const { data: me, isLoading, isSuccess } = useGetMeQuery({});
  const { data: kb } = useGetKBQuery();
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: EmptyUserInfo,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (me) {
      reset(me);
    }
  }, [me, reset]);

  const [updateMe, { isLoading: isUpdating }] = useUpdateMeMutation();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const meReq: UpdateMeRequest = {
      City: data.City,
      FirstName: data.FirstName,
      LastName: data.LastName,
      PhoneNumber: data.PhoneNumber,
      PostalCode: data.PostalCode,
      Province: data.Province,
      StreetAddress: data.StreetAddress,
      CountryID: data.CountryID,
      Avatar: '',
    };

    try {
      await updateMe(meReq);

      setSnackBarInfo({
        message: 'Profile updated.',
        severity: 'success',
        open: true,
      });
    } catch (e: any) {
      if (e.response) {
        setError('root', { message: e.response.data.message });
      } else if (e.message) {
        setError('root', { message: e.message });
      } else {
        setError('root', { message: 'Something went wrong.' });
      }
      console.log(e);
    }
  };

  return (
    <>
      <Card sx={{ marginX: 'auto', maxWidth: '500px' }}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            {isLoading && <LinearProgress />}
            {isSuccess && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Edit Profile</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={Boolean(errors.FirstName)}>
                    <InputLabel htmlFor="first-name">First Name</InputLabel>
                    <Controller
                      name="FirstName"
                      control={control}
                      render={({ field }) => (
                        <OutlinedInput
                          {...field}
                          id="first-name"
                          label="First Name"
                          type="text"
                          disabled={isUpdating}
                          autoComplete="first-name"
                        />
                      )}
                    />
                    {errors.FirstName && (
                      <FormHelperText error>
                        {errors.FirstName.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={Boolean(errors.LastName)}>
                    <InputLabel htmlFor="last-name">Last Name</InputLabel>
                    <Controller
                      name="LastName"
                      control={control}
                      render={({ field }) => (
                        <OutlinedInput
                          {...field}
                          id="last-name"
                          label="Last Name"
                          type="text"
                          disabled={isUpdating}
                          autoComplete="last-name"
                        />
                      )}
                    />
                    <FormHelperText error>
                      {errors.LastName?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="street-address">
                      Street Address
                    </InputLabel>
                    <Controller
                      name="StreetAddress"
                      control={control}
                      render={({ field }) => (
                        <OutlinedInput
                          {...field}
                          id="street-address"
                          label="Street Address"
                          type="text"
                          name="StreetAddress"
                          autoComplete="street-address"
                        />
                      )}
                    />
                    <FormHelperText error>
                      {errors.StreetAddress?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="city">City</InputLabel>
                    <Controller
                      name="City"
                      control={control}
                      render={({ field }) => (
                        <OutlinedInput
                          {...field}
                          id="city"
                          label="City"
                          type="text"
                          autoComplete="city"
                        />
                      )}
                    />
                    <FormHelperText error>
                      {errors.City?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="province">Province</InputLabel>
                    <Controller
                      name="Province"
                      control={control}
                      render={({ field }) => (
                        <OutlinedInput
                          {...field}
                          id="province"
                          type="text"
                          label="Province"
                          autoComplete="province"
                        />
                      )}
                    />
                    <FormHelperText error>
                      {errors.Province?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="country">Country</InputLabel>
                    <Controller
                      name="CountryID"
                      control={control}
                      render={({ field }) => (
                        <Select
                          value={field.value ? field.value : ''}
                          onChange={field.onChange}
                          id="country"
                          label="Country"
                          autoComplete="country"
                        >
                          {kb?.Countries.map((country) => (
                            <MenuItem key={country.ID} value={country.ID}>
                              {country.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <FormHelperText error>
                      {errors.CountryID?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="phone-number">Phone Number</InputLabel>
                    <Controller
                      name="PhoneNumber"
                      control={control}
                      render={({ field }) => (
                        <OutlinedInput
                          {...field}
                          id="phone-number"
                          label="Phone Number"
                          type="text"
                          autoComplete="phone-number"
                        />
                      )}
                    />
                    <FormHelperText error>
                      {errors.PhoneNumber?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {errors.root && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.root.message}</FormHelperText>
                  </Grid>
                )}
              </Grid>
            )}
          </CardContent>
          <CardActions sx={{ justifyContent: 'center' }}>
            <LoadingButton
              loading={isUpdating}
              disabled={!isDirty}
              type="submit"
            >
              Update
            </LoadingButton>
          </CardActions>
        </form>
      </Card>
      <Snackbar
        open={snackBarInfo?.open}
        autoHideDuration={6000}
        onClose={handleOnCloseSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleOnCloseSnackBar}
          severity={snackBarInfo?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackBarInfo?.message}
        </Alert>
      </Snackbar>
    </>
  );
};
