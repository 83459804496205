export const apiBaseURL = (): string => {
  return process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:8081';
};

export const toApiUrl = (path: string): string => {
  return apiBaseURL() + path;
};

export enum OpStatus {
  Idle = 'IDLE',
  Loading = 'LOADING',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export type ApiOp<T> = {
  status: OpStatus,
  data?: T,
  message?: string,
}