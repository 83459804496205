import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import * as Yup from 'yup';
import { DurationInput } from '../../Components/UI/DurationInput';
import { SiteContext } from '../../Contexts/SiteContext';
import { PeriodType } from '../../Model/Time/PeriodType';
import { UserMembership } from '../../Model/UserMembership';
import {
  UpdateMembershipRequest,
  useUpdateMembershipMutation,
} from '../../Service/MemberTypes';
import { getErrorMessage } from '../../Util/Error';

type Props = {
  membership?: UserMembership;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};
export const EditMemberDialog = (props: Props) => {
  const schema = Yup.object().shape({
    ExpirationDate: Yup.date()
      .required('Expiration Date is required')
      .min(new Date(), 'Expiration Date must be in the future'),
    GracePeriod: Yup.object().shape({
      Value: Yup.number()
        .required('Period type value is required')
        .min(0, 'Period type value must be at least 0')
        .max(100, 'Period type value must be at most 100'),
      PeriodType: Yup.number()
        .required('Period type unit is required')
        .oneOf([
          PeriodType.Hour,
          PeriodType.Day,
          PeriodType.Week,
          PeriodType.Month,
          PeriodType.Year,
        ]),
    }),
  });

  const defaultValues = useMemo(() => {
    const now = new Date().toString();
    const defaultExpirationDate = new Date(
      props.membership?.ExpirationDate || now
    );
    return {
      ExpirationDate: defaultExpirationDate,
      GracePeriod: {
        Value: props.membership?.GracePeriodValue || 30,
        PeriodType: props.membership?.GracePeriodType || PeriodType.Day,
      },
    };
  }, [
    props.membership?.ExpirationDate,
    props.membership?.GracePeriodType,
    props.membership?.GracePeriodValue,
  ]);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (props.open) {
      const now = new Date().toString();
      const expirationDate = new Date(props.membership?.ExpirationDate || now);
      reset({
        ExpirationDate: expirationDate,
        GracePeriod: {
          Value: props.membership?.GracePeriodValue || 30,
          PeriodType: props.membership?.GracePeriodType || PeriodType.Day,
        },
      });
    }
  }, [props.membership, props.open, reset]);

  const [updateMembership, { isLoading: isLoadingUpdateMembership }] =
    useUpdateMembershipMutation();

  const siteID = useContext(SiteContext).site.id;
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    console.debug('Edit Member Data', data);

    const req: UpdateMembershipRequest = {
      ID: props.membership?.ID || 0,
      UserID: props.membership?.UserID || 0,
      SiteID: siteID,
      MemberTypeID: props.membership?.MemberTypeID || 0,
      ExpirationDate: data.ExpirationDate,
      GracePeriodValue: data.GracePeriod.Value,
      GracePeriodType: data.GracePeriod.PeriodType,
    };

    try {
      await updateMembership(req);

      props.setOpen(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      setError('root', { message });
    }
  };

  return (
    <Dialog
      aria-label="edit-member-dialog"
      open={props.open}
      onClose={() => props.setOpen(false)}
      fullWidth
    >
      <DialogTitle>Edit Member</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogContent>
          <Grid
            container
            spacing={2}
            paddingY={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            justifyItems={'center'}
          >
            <Grid item xs={12} paddingBottom={2}>
              <Typography variant="h6" textAlign={'center'}>
                {props.membership?.FirstName} {props.membership?.LastName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl error={!!errors.ExpirationDate}>
                <Controller
                  name="ExpirationDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      label="Expiration Date"
                      value={DateTime.fromJSDate(value)}
                      onChange={(date) =>
                        onChange(date?.isValid ? date.toJSDate() : null)
                      }
                      onAccept={(date) =>
                        onChange(date?.isValid ? date.toJSDate() : null)
                      }
                    />
                  )}
                />
                <FormHelperText error>
                  {errors.ExpirationDate?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormControl
                style={{ minWidth: 150 }}
                error={!!errors.GracePeriod}
              >
                <Controller
                  name="GracePeriod"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DurationInput value={value} onChange={onChange} />
                  )}
                />
                <FormHelperText error>
                  {errors.GracePeriod?.Value?.message}
                </FormHelperText>
                <FormHelperText error>
                  {errors.GracePeriod?.PeriodType?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="submit"
            color="primary"
            loading={isLoadingUpdateMembership}
          >
            Save
          </LoadingButton>
          <Button color="inherit" onClick={() => props.setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
