import { Menu } from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
import Logo from './Logo';
import './Logo.css';
import { ProfileMenu } from './ProfileMenu';

interface HeaderProps {
  onMenuButtonClick?: () => void;
  children?: React.ReactNode;
}
const Header = (props: HeaderProps) => {
  function handleMenuButtonClick(): void {
    if (props.onMenuButtonClick) {
      props.onMenuButtonClick();
    }
  }

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      marginRight={{ xs: 2 }}
      justifyContent={'space-between'}
    >
      <Grid container alignItems="center" sx={{ px: 1, py: 1 }}>
        <Grid item>
          <IconButton aria-label="open drawer" onClick={handleMenuButtonClick}>
            <Menu />
          </IconButton>
        </Grid>
        <Grid item>
          <Logo />
        </Grid>
        <Grid item>{props.children}</Grid>
      </Grid>
      <Box sx={{ flexGrow: 1 }}></Box>
      <ProfileMenu />
    </Box>
  );
};

export default Header;
