export function getErrorMessage(e: any): string {
  if (e.data && e.data.message) {
    return e.data.message;
  }
  if (e.response && e.response.data && e.response.data.message) {
    return e.response.data.message;
  }
  if (e.message) {
    return e.message;
  }
  return 'Something went wrong.';
}
