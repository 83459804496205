export enum PriorityType {
  MemberType = 0,
  SlotType = 1,
}

export const PriorityTypes: PriorityType[] = [
  PriorityType.MemberType,
  PriorityType.SlotType,
];

export function GetPriorityTypeName(pt: PriorityType): string {
  switch (pt) {
    case PriorityType.SlotType:
      return 'Slot Type';
    case PriorityType.MemberType:
      return 'Member Type';
    default:
      return '';
  }
}
