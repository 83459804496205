import { Help } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';

type HelpTooltipProps = {
  text: string;
  children: React.ReactNode;
};

export const HelpTooltip = (props: HelpTooltipProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {props.children}
      <Tooltip disableFocusListener arrow placement="top" title={props.text}>
        <Help color="info" />
      </Tooltip>
    </Stack>
  );
};
