import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { ApiOp, OpStatus, toApiUrl } from '../../Util/Api';
import { getErrorMessage } from '../../Util/Error';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import * as yup from 'yup';

export const SendResetPassword = () => {
  const [result, setResult] = useState<ApiOp<void>>({
    status: OpStatus.Idle,
  });

  const schema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    console.debug('handleSendLink', data);

    try {
      await axios.post(toApiUrl('/auth/send-reset-password-token'), {
        email: data.email,
      });
      setResult({ status: OpStatus.Success });
    } catch (e) {
      const msg = getErrorMessage(e);
      setResult({ status: OpStatus.Failure, message: msg });
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container textAlign="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Reset Password</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <p>
              To reset your password enter your email below to receive a
              password reset link
            </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {result.status === OpStatus.Idle && (
            <FormControl
              fullWidth
              sx={{ marginTop: 1, marginBottom: 1 }}
              error={!!errors.email}
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    id="email"
                    label="Email"
                    type="email"
                    autoComplete="email"
                    autoFocus
                  />
                )}
              />
              <FormHelperText
                error
                id="standard-weight-helper-text-password-reset"
              >
                {errors.email?.message}
              </FormHelperText>
            </FormControl>
          )}
        </Grid>
        {result.status === OpStatus.Failure && (
          <Grid item xs={12}>
            <Typography color="error">{result.message}</Typography>
          </Grid>
        )}
        {result.status === OpStatus.Success && (
          <Grid item xs={12}>
            <Typography color="success">
              If you have an account with us you will receive an email
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box display={'flex'} sx={{ mt: 2 }} justifyContent={'center'}>
            {result.status === OpStatus.Idle && (
              <LoadingButton
                loading={isSubmitting}
                fullWidth
                size="large"
                variant="contained"
                type="submit"
              >
                Send Link
              </LoadingButton>
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
