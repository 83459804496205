import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import { toApiUrl } from '../../Util/Api';
import {
  Strength,
  strengthColor,
  strengthIndicator,
} from '../../Util/password-strength';

import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import * as yup from 'yup';
import { LoginPath } from '../../Routes';
import { getErrorMessage } from '../../Util/Error';

export const ResetPassword = () => {
  const [strength, setStrength] = useState(0);
  const [level, setLevel] = useState<Strength>();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const theme = useTheme();

  const changePassword = (value: string) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp, theme));
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .min(8, "Password can't be less than 8 characters")
      .matches(
        /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^*()& "]).*$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
      ),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!token) {
      setError('root', { message: 'Missing token.' });
    }
  }, [token, setError]);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      await axios.post(toApiUrl('/auth/reset-password'), {
        password: data.password,
        token: token,
      });
    } catch (e: any) {
      const msg = getErrorMessage(e);
      setError('root', { message: msg });
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} justifyContent={'center'} textAlign="center">
        <Grid item xs={12} mb={2}>
          <Typography variant="h6">Reset Password</Typography>
        </Grid>
        {isSubmitSuccessful && (
          <>
            <Grid item xs={12}>
              <Typography variant="body1">
                Password changed successfully.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                color={'primary'}
                component={Link}
                to={LoginPath}
                variant="subtitle1"
                sx={{ textDecoration: 'none' }}
              >
                Click here to login
              </Typography>
            </Grid>
          </>
        )}
        {!isSubmitSuccessful && (
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal" error={!!errors.password}>
              <InputLabel>Password</InputLabel>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                    disabled={isSubmitSuccessful}
                    onChange={(e) => {
                      changePassword(e.target.value);
                      field.onChange(e);
                    }}
                  />
                )}
              />
              <FormHelperText error>{errors.password?.message}</FormHelperText>
            </FormControl>
          </Grid>
        )}
        {!isSubmitSuccessful && strength !== 0 && (
          <Grid item>
            <FormControl fullWidth>
              <Box sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Box
                      style={{ backgroundColor: level?.color }}
                      sx={{
                        width: 85,
                        height: 8,
                        borderRadius: `${theme.border.radius}`,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" fontSize="0.75rem">
                      {level?.label}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="subtitle1" color={'error'}>
            {errors.root?.message}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {!isSubmitSuccessful && (
            <Button variant="contained" type="submit">
              Reset Password
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
