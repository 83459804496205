import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import * as Yup from 'yup';
import { SiteContext } from '../../Contexts/SiteContext';
import { MemberType } from '../../Model/MemberType';
import {
  useAddMemberTypeMutation,
  useUpdateMemberTypeMutation,
} from '../../Service/MemberTypes';
import { getErrorMessage } from '../../Util/Error';

type AddEditMemberTypeDialogProps = {
  open: boolean;
  title: string;
  memberType?: MemberType;
  onMemberTypeSaved?: (memberType: MemberType) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
};
export const AddEditMemberTypeDialog = (
  props: AddEditMemberTypeDialogProps
) => {
  const { memberType } = props;
  const schema = Yup.object().shape({
    ID: Yup.number(),
    Name: Yup.string().required('Name is required'),
    Description: Yup.string(),
    SiteID: Yup.number().required(),
  });

  const siteID = useContext(SiteContext).site.id;
  const values: MemberType = useMemo(() => {
    return {
      ID: memberType?.ID || 0,
      Name: memberType?.Name || '',
      Description: memberType?.Description || '',
      SiteID: memberType ? memberType.SiteID : siteID,
    };
  }, [memberType, siteID]);

  const {
    control,
    handleSubmit,
    setError,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: values,
    resolver: yupResolver(schema),
  });

  const handleCancel = (_event: any): void => {
    props.setOpen(false);
  };

  useEffect(() => {
    if (!props.open) {
      reset(values);
    }
  }, [props.open, values, reset]);

  const [addMemberType, { isLoading: isAddMemberTypeLoading }] =
    useAddMemberTypeMutation();
  const [updateMemberType, { isLoading: isUpdateMemberTypeLoading }] =
    useUpdateMemberTypeMutation();
  const isSaving = isAddMemberTypeLoading || isUpdateMemberTypeLoading;
  const handlePostMemberType = async (data: MemberType) => {
    try {
      await addMemberType(data).unwrap();
      props.setOpen(false);
    } catch (e: any) {
      const msg = getErrorMessage(e);
      setError('root', { message: msg });
    }
  };

  const handlePutMemberType = async (data: MemberType) => {
    try {
      await updateMemberType(data).unwrap();
      props.setOpen(false);
    } catch (e: any) {
      const msg = getErrorMessage(e);
      setError('root', { message: msg });
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (data.ID) {
      handlePutMemberType(data as MemberType);
    } else {
      handlePostMemberType(data as MemberType);
    }
  };

  return (
    <Dialog
      aria-label="add-edit-member-type-dialog"
      fullWidth
      onClose={() => props.setOpen(false)}
      open={props.open}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(onSubmit),
      }}
    >
      <DialogTitle textAlign={'center'}>{props.title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ py: 2 }} alignItems={'center'}>
          <input type="hidden" {...register('SiteID')} />
          <FormControl fullWidth error={Boolean(errors.Name)}>
            <InputLabel htmlFor="Name">Name</InputLabel>
            <Controller
              name="Name"
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  autoComplete="member-type-name"
                  label="Name"
                  id="Name"
                />
              )}
            />
            <FormHelperText error>{errors.Name?.message}</FormHelperText>
          </FormControl>
          <FormControl fullWidth error={Boolean(errors.Description)}>
            <InputLabel htmlFor="Description">Description</InputLabel>
            <Controller
              defaultValue=""
              name="Description"
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  label="Description"
                  autoComplete="member-type-description"
                  id="Description"
                />
              )}
            />
            <FormHelperText error>{errors.Description?.message}</FormHelperText>
          </FormControl>
          {errors.root && (
            <Box my={2} sx={{ width: '100%', textAlign: 'center' }}>
              <Typography color={'error'}>{errors.root?.message}</Typography>
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="primary">
          {isSaving && <CircularProgress size={20} sx={{ mr: 1 }} />}
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
        <Button color="inherit" onClick={handleCancel}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
