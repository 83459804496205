import { createApi } from '@reduxjs/toolkit/query/react';
import { SlotType } from '../Model/SlotType';
import { baseQuery } from './BaseQuery';
import { ErrorTags, getErrorTag } from './Error';

export const slotTypesApi = createApi({
  reducerPath: 'slotTypesApi',
  baseQuery,
  tagTypes: [...ErrorTags, 'SlotTypes'],
  endpoints: (build) => ({
    getSlotTypes: build.query<SlotType[], number>({
      query: (siteID: number) => `/sites/${siteID}/slot-types`,
      providesTags: (result, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return result
          ? [
              ...result.map(
                ({ ID }) => ({ type: 'SlotTypes', id: ID } as const)
              ),
              { type: 'SlotTypes', id: 'LIST' } as const,
            ]
          : [{ type: 'SlotTypes', id: 'LIST' } as const];
      },
    }),
    getSlotType: build.query<SlotType, { siteID: number; slotTypeID: number }>({
      query: ({ siteID, slotTypeID }) => ({
        url: `/sites/${siteID}/slot-types/${slotTypeID}`,
      }),
      providesTags: (result, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }

        return result ? [{ type: 'SlotTypes', id: result.ID }] : [];
      },
    }),
    addSlotType: build.mutation<SlotType, SlotType>({
      query: (slotType: SlotType) => ({
        url: `/sites/${slotType.SiteID}/slot-types`,
        method: 'POST',
        body: slotType,
      }),
      invalidatesTags: (result) => [
        { type: 'SlotTypes', id: result?.ID },
        { type: 'SlotTypes', id: 'LIST' },
      ],
    }),
    updateSlotType: build.mutation<SlotType, SlotType>({
      query: (slotType: SlotType) => ({
        url: `/sites/${slotType.SiteID}/slot-types/${slotType.ID}`,
        method: 'PUT',
        body: slotType,
      }),
      invalidatesTags: (result) => [
        { type: 'SlotTypes', id: result?.ID },
        { type: 'SlotTypes', id: 'LIST' },
      ],
    }),
    deleteSlotType: build.mutation<SlotType, SlotType>({
      query: (slotType: SlotType) => ({
        url: `/sites/${slotType.SiteID}/slot-types/${slotType.ID}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [
        { type: 'SlotTypes', id: result?.ID },
        { type: 'SlotTypes', id: 'LIST' },
        { type: 'Slots', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetSlotTypesQuery,
  useGetSlotTypeQuery,
  useAddSlotTypeMutation,
  useUpdateSlotTypeMutation,
  useDeleteSlotTypeMutation,
} = slotTypesApi;
