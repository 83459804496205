export function formatCurrency(value: number, symbol?: string): string {
  if (!symbol) {
    symbol = '€';
  }
  return symbol + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatQuantity(value: number): string {
  if (value === 0) {
    return 'None';
  }
  if (value === -1) {
    return 'Unlimited';
  }

  return value.toString();
}
