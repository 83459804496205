import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { SetStateAction } from 'react';

export interface Props {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  title: string;
  value?: any;
  confirmText?: string;
  danger?: boolean;
  message: string;
  onConfirm: (value?: any) => void;
}

export const ConfirmDialog = (props: Props) => {
  function handleConfirm(): void {
    props.onConfirm(props.value);
    props.setOpen(false);
  }

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={() => props.setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={() => props.setOpen(false)}>
          Close
        </Button>
        <Button
          color={props.danger ? 'error' : 'primary'}
          variant="contained"
          onClick={handleConfirm}
        >
          {props.confirmText || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
