import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import { Court } from '../../Model/Court';

import { yupResolver } from '@hookform/resolvers/yup';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useContext, useMemo } from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import * as Yup from 'yup';
import { SiteContext } from '../../Contexts/SiteContext';
import { TitleById } from '../../Model/GameType';
import { LabelFromIDs, LabelWithDay } from '../../Model/Slot';
import { BlockCourtRequest, useBlockCourtMutation } from '../../Service/Events';
import { useGetEventTypesQuery } from '../../Service/EventTypes';
import { useGetKBQuery } from '../../Service/KB';
import { useGetSlotsQuery } from '../../Service/Slots';
import { getErrorMessage } from '../../Util/Error';

type Props = {
  open: boolean;
  court: Court;
  setOpen: (open: boolean) => void;
};
export const BlockCourtDialog = (props: Props) => {
  const siteID = useContext(SiteContext).site.id;

  const { data: eventTypes } = useGetEventTypesQuery(siteID);

  const eventTypeIDs = useMemo(() => {
    return eventTypes?.map((eventType) => eventType.ID) || [];
  }, [eventTypes]);

  const { data: kb } = useGetKBQuery();
  const gameTypeIDs = useMemo(() => {
    return kb?.GameTypes.map((gameType) => gameType.ID) || [];
  }, [kb]);

  const minStartTime = DateTime.now();
  const schema = Yup.object().shape({
    GameTypeID: Yup.number()
      .required('Game Type is required')
      .oneOf(gameTypeIDs, 'Invalid Game Type'),
    SlotIDs: Yup.array().of(Yup.number().required()).min(1, 'Required'),
    RepeatUntil: Yup.date(),
    CancelBookings: Yup.boolean(),
    EventTypeID: Yup.number()
      .required('Event Type is required')
      .oneOf(eventTypeIDs, 'Invalid Event Type'),
  });

  const defaultValues = useMemo(() => {
    return {
      GameTypeID: gameTypeIDs.length > 0 ? gameTypeIDs[0] : 0,
      SlotIDs: [],
      RepeatUntil: minStartTime.plus({ days: 1 }).toJSDate(),
      CancelBookings: false,
      EventTypeID: eventTypeIDs.length > 0 ? eventTypeIDs[0] : 0,
    };
  }, [minStartTime, eventTypeIDs, gameTypeIDs]);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { data: slots } = useGetSlotsQuery({
    siteID: siteID,
    courtID: props.court.ID,
  });

  const [blockCourt] = useBlockCourtMutation();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    console.debug('Block Court Data', data);

    const req: BlockCourtRequest = {
      GameTypeID: data.GameTypeID,
      CourtID: props.court.ID,
      SlotIDs: data.SlotIDs,
      RepeatUntil: data.RepeatUntil,
      CancelExistingBookings: data.CancelBookings,
      EventTypeID: data.EventTypeID,
      SiteID: siteID,
    };

    try {
      await blockCourt(req).unwrap();
    } catch (e: any) {
      const msg = getErrorMessage(e);
      setError('root', { message: msg });
    }

    props.setOpen(false);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Dialog
      aria-label="Block Court Dialog"
      maxWidth="xs"
      fullWidth
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      <DialogTitle textAlign="center">Block {props.court.Name}</DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2} display="flex" justifyContent="center">
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.GameTypeID}>
                <InputLabel>Game Type</InputLabel>
                <Controller
                  name="GameTypeID"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={'Game Type'}
                      MenuProps={MenuProps}
                      input={<OutlinedInput label="Game Type" />}
                    >
                      {gameTypeIDs.map((gameTypeID) => (
                        <MenuItem key={gameTypeID} value={gameTypeID}>
                          {TitleById(gameTypeID, kb?.GameTypes || [])}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText>{errors.GameTypeID?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.SlotIDs}>
                <InputLabel>Slot Selection</InputLabel>
                <Controller
                  name="SlotIDs"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={'Slot Selection'}
                      multiple
                      renderValue={(s: number[]) => LabelFromIDs(s, slots!)}
                      MenuProps={MenuProps}
                      input={<OutlinedInput label="Slot Selection" />}
                    >
                      {slots?.map((slot) => (
                        <MenuItem key={slot.ID} value={slot.ID}>
                          <Checkbox checked={field.value?.includes(slot.ID)} />
                          <ListItemText primary={LabelWithDay(slot)} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText>{errors.SlotIDs?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.RepeatUntil}>
                <Controller
                  name="RepeatUntil"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <DateTimePicker
                      label="Repeat Until"
                      value={value ? DateTime.fromJSDate(value) : null}
                      onChange={(date) => onChange(date?.toJSDate() || null)}
                      onAccept={(date) => onChange(date?.toJSDate() || null)}
                      inputRef={ref}
                    />
                  )}
                />
                <FormHelperText>{errors.RepeatUntil?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.EventTypeID}>
                <InputLabel>Event Type</InputLabel>
                <Controller
                  name="EventTypeID"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={
                        eventTypeIDs.includes(field.value) ? field.value : ''
                      }
                      label="Event Type"
                      error={!!errors.EventTypeID}
                    >
                      {eventTypes?.map((eventType) => (
                        <MenuItem key={eventType.ID} value={eventType.ID}>
                          {eventType.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText>{errors.EventTypeID?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.CancelBookings}>
                <Controller
                  name="CancelBookings"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      checked={field.value}
                      control={<Switch />}
                      label="Cancel Existing Bookings"
                    />
                  )}
                />
                <FormHelperText>
                  {errors.CancelBookings?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography color="error" variant="subtitle1">
                {errors.root?.message}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
          <Button onClick={() => props.setOpen(false)}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
