import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {
  PickerChangeHandlerContext,
  TimePicker,
  TimeValidationError,
} from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { WeekdaySelector } from '../../../Components/UI/WeekdaySelector';
import { Court } from '../../../Model/Court';
import { Slot } from '../../../Model/Slot';
import { SlotBucket } from '../../../Model/SlotBucket';
import { SlotBuckets } from '../../../Model/SlotBucketList';
import { Weekday } from '../../../Model/Time/Weekday';
import { useAddSlotBucketsMutation } from '../../../Service/Slots';
import { useGetSlotTypesQuery } from '../../../Service/SlotTypes';
import { getErrorMessage } from '../../../Util/Error';
import { SlotBucketCreator } from './SlotBucketsCreator';

type AddSlotBucketsDialogProps = {
  open: boolean;
  court: Court;
  onSaved?: (slot: Slot[]) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const AddSlotBucketsDialog = (props: AddSlotBucketsDialogProps) => {
  const [error, setError] = useState<string>('');

  const [initialStart, setInitTime] = useState(DateTime.fromSQL('06:00:00'));
  const [weekdays, setWeekdays] = useState<Weekday[]>([]);
  const [buckets, setBuckets] = useState<SlotBucket[]>([
    {
      StartTimeMinutes: initialStart.minute + initialStart.hour * 60,
      NumSlots: 1,
      DurationMinutes: 90,
      SlotTypeID: 0,
    },
  ]);

  const { data: slotTypes } = useGetSlotTypesQuery(props.court.SiteID);
  function handleCancel(_event: any): void {
    props.setOpen(false);
  }
  function handleInitTimeChange(
    value: DateTime<true> | DateTime<false> | null,
    _: PickerChangeHandlerContext<TimeValidationError>
  ): void {
    if (value) {
      setInitTime(value);
    }
  }

  const [addSlotBuckets, { isLoading: isSaving }] = useAddSlotBucketsMutation();
  function handlePostBuckets(_event: MouseEvent<HTMLButtonElement>): void {
    if (buckets.length === 0) {
      setError('Please add at least one slot bucket');
      return;
    }

    if (weekdays.length === 0) {
      setError('Please select at least one weekday');
      return;
    }

    for (const bucket of buckets) {
      if (bucket.SlotTypeID === 0) {
        setError('Please select a slot type for all buckets');
        return;
      }
    }

    const list: SlotBuckets = {
      CourtID: props.court.ID,
      Weekdays: weekdays,
      Buckets: buckets,
    };

    const siteId = props.court.SiteID;

    addSlotBuckets({ siteID: siteId, buckets: list })
      .then(() => {
        setError('');
        props.setOpen(false);
      })
      .catch((error) => {
        const msg = getErrorMessage(error);
        setError(msg);
      });
  }

  return (
    <Dialog
      aria-labelledby="add-edit-slot-dialog"
      fullWidth
      onClose={() => props.setOpen(false)}
      open={props.open}
    >
      <DialogTitle textAlign={'center'}>
        <Stack direction={'column'} justifyContent={'center'}>
          <Typography variant={'h6'}>Add Slot Buckets</Typography>
          <Typography variant="subtitle1">{props.court.Name}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={12} sm={4}>
            <FormControl>
              <FormLabel htmlFor="InitialStart">Initial Start</FormLabel>
              <TimePicker
                timezone="system"
                defaultValue={initialStart}
                onChange={handleInitTimeChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl fullWidth>
              <FormLabel htmlFor="Weekdays">Repeats On</FormLabel>
              <WeekdaySelector value={weekdays} onSelect={setWeekdays} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
          <Grid item xs={12}>
            <SlotBucketCreator
              initialStart={initialStart}
              slotTypes={slotTypes}
              value={buckets}
              onUpdate={setBuckets}
            />
          </Grid>
          <Grid item xs={12} textAlign={'center'} mt={2}>
            <Typography color={'error'}>{error}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="submit"
          onClick={handlePostBuckets}
          loading={isSaving}
        >
          Save
        </LoadingButton>
        <Button color="inherit" onClick={handleCancel}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
