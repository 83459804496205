import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { OpenSlotsPath } from '../../../Routes';

export const PaymentFailure = () => {
  return (
    <Stack spacing={2} alignItems="center" textAlign={'center'}>
      <Typography variant="h6">Payment Failed</Typography>
      <Typography variant="body1">
        Please try again or contact site support
      </Typography>
      <Link to={OpenSlotsPath}>Return to Open Slots</Link>
    </Stack>
  );
};
