import { MemberType } from './MemberType';
import { UserRoles } from './UserRoles';

export interface UserInfo {
  ID: number;
  Email: string;
  FirstName: string;
  LastName: string;
  StreetAddress: string;
  City: string;
  Province: string;
  PostalCode: string;
  PhoneNumber: string;
  Avatar: string;
  CountryID: number;
  MemberTypes?: MemberType[];
  Roles?: UserRoles;
}

export const EmptyUserInfo: UserInfo = {
  ID: 0,
  Email: '',
  FirstName: '',
  LastName: '',
  StreetAddress: '',
  City: '',
  Province: '',
  PostalCode: '',
  PhoneNumber: '',
  Avatar: '',
  CountryID: 0,
  MemberTypes: [],
  Roles: {},
};
