import { createApi } from '@reduxjs/toolkit/query/react';
import { Court } from '../Model/Court';
import { baseQuery } from './BaseQuery';
import { ErrorTags, getErrorTag } from './Error';

export const courtsApi = createApi({
  reducerPath: 'courtsApi',
  baseQuery,
  tagTypes: [...ErrorTags, 'Courts'],
  endpoints: (build) => ({
    getCourts: build.query<Court[], number>({
      query: (siteID: number) => `/sites/${siteID}/courts`,
      providesTags: (result, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }

        return result
          ? [
              ...result.map(({ ID }) => ({ type: 'Courts', id: ID } as const)),
              { type: 'Courts', id: 'LIST' } as const,
            ]
          : [{ type: 'Courts', id: 'LIST' } as const];
      },
    }),
    addCourt: build.mutation<Court, Court>({
      query: (court: Court) => ({
        url: `/sites/${court.SiteID}/courts`,
        method: 'POST',
        body: court,
      }),
      invalidatesTags: (result) => [
        { type: 'Courts', id: result?.ID },
        {
          type: 'Courts',
          id: 'LIST',
        },
      ],
    }),
    editCourt: build.mutation<Court, Court>({
      query: (court: Court) => ({
        url: `/sites/${court.SiteID}/courts/${court.ID}`,
        method: 'PUT',
        body: court,
      }),
      invalidatesTags: (result) => [
        {
          type: 'Courts',
          id: result?.ID,
        },
      ],
    }),
    deleteCourt: build.mutation<Court, Court>({
      query: (court: Court) => ({
        url: `/sites/${court.SiteID}/courts/${court.ID}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [
        {
          type: 'Courts',
          id: result?.ID,
        },
      ],
    }),
  }),
});

export const {
  useGetCourtsQuery,
  useAddCourtMutation,
  useEditCourtMutation,
  useDeleteCourtMutation,
} = courtsApi;
