import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Dispatch, SetStateAction, useEffect } from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import * as Yup from 'yup';
import { DurationInput } from '../../Components/UI/DurationInput';
import { UserSearchInput } from '../../Components/UI/UserSearchInput';
import { MemberType } from '../../Model/MemberType';
import { PeriodType } from '../../Model/Time/PeriodType';
import { useAddMembershipMutation } from '../../Service/MemberTypes';
import { getErrorMessage } from '../../Util/Error';

type AddMemberDialogProps = {
  memberType: MemberType;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};
export const AddMemberDialog = (props: AddMemberDialogProps) => {
  const schema = Yup.object().shape({
    User: Yup.object().shape({
      ID: Yup.number().required(),
      Email: Yup.string().email('Invalid email').required('Email is required'),
      FirstName: Yup.string().required('First Name is required'),
      LastName: Yup.string().required('Last Name is required'),
      SiteIDs: Yup.array().of(Yup.number().required()).required().min(0),
    }),
    MemberTypeID: Yup.number().required(),
    ExpirationDate: Yup.date().required('Expiration Date is required'),
    GracePeriod: Yup.object().shape({
      Value: Yup.number().required('Value is required'),
      PeriodType: Yup.number().required('Unit is required'),
    }),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: {
      ExpirationDate: DateTime.now().plus({ years: 1 }).toJSDate(),
      MemberTypeID: props.memberType.ID,
      GracePeriod: {
        Value: 30,
        PeriodType: PeriodType.Day,
      },
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (props.open) {
      return;
    }

    reset({
      MemberTypeID: props.memberType.ID,
      ExpirationDate: DateTime.now().plus({ years: 1 }).toJSDate(),
      GracePeriod: {
        Value: 30,
        PeriodType: PeriodType.Day,
      },
    });
  }, [props.open, props.memberType.ID, reset]);

  const [addMembership, { isLoading: isAddMemberLoading }] =
    useAddMembershipMutation();
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    console.debug('Add Member Data', data);
    try {
      await addMembership({
        MemberTypeID: props.memberType.ID,
        SiteID: props.memberType.SiteID,
        UserID: data.User.ID,
        ExpirationDate: data.ExpirationDate.toISOString(),
        GracePeriodValue: data.GracePeriod.Value,
        GracePeriodType: data.GracePeriod.PeriodType,
      });

      props.setOpen(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      setError('root', { message });
    }
  };

  return (
    <Dialog
      aria-label="add-member"
      onClose={() => props.setOpen(false)}
      fullWidth
      open={props.open}
    >
      <DialogTitle>Add Member</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <input type="hidden" {...register('MemberTypeID')} />
        <DialogContent>
          <Grid container spacing={2} paddingY={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name="User"
                  control={control}
                  render={({ field }) => (
                    <UserSearchInput
                      siteId={props.memberType.SiteID}
                      includeSelf
                      value={field.value}
                      onChange={field.onChange}
                      label="User"
                    />
                  )}
                />
                <FormHelperText error>{errors.User?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl>
                <Controller
                  name="ExpirationDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      label="Expiration Date"
                      value={DateTime.fromJSDate(value)}
                      onChange={(date) =>
                        onChange(date?.isValid ? date.toJSDate() : null)
                      }
                      onAccept={(date) =>
                        onChange(date?.isValid ? date.toJSDate() : null)
                      }
                    />
                  )}
                />
                <FormHelperText error>
                  {errors.ExpirationDate?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormControl style={{ minWidth: 150 }}>
                <Controller
                  name="GracePeriod"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DurationInput value={value} onChange={onChange} />
                  )}
                />
                <FormHelperText error>
                  {errors.GracePeriod?.Value?.message}
                </FormHelperText>
                <FormHelperText error>
                  {errors.GracePeriod?.PeriodType?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography color={'error'}>{errors.root?.message}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="submit"
            color="primary"
            loading={isAddMemberLoading}
          >
            Save
          </LoadingButton>
          <Button color="inherit" onClick={() => props.setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
