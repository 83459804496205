import { Chip } from '@mui/material';
import { UserMembership } from '../../Model/UserMembership';

type Props = {
  membership: UserMembership;
  onDelete?: () => void;
  onClick?: () => void;
};

export const UserMembershipChip = (props: Props) => {
  return (
    <Chip
      label={`${props.membership.FirstName} ${props.membership.LastName} (${props.membership.Email})`}
      variant="outlined"
      size="small"
      sx={{ mr: 1, mb: 1 }}
      onDelete={props.onDelete}
      onClick={props.onClick}
    />
  );
};
