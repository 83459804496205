import { Box, Chip, Tooltip } from '@mui/material';
import { useContext } from 'react';
import { SiteContext } from '../../Contexts/SiteContext';
import { Slot } from '../../Model/Slot';
import { MinutesToLabel } from '../../Model/SlotBucket';
import { useGetSlotTypeQuery } from '../../Service/SlotTypes';

type SlotChipProps = {
  slot: Slot;
  onDeleteClicked?: (slot: Slot) => void;
};

export const SlotChip = (props: SlotChipProps) => {
  const startTimeLabel = MinutesToLabel(props.slot.StartTimeMinutes);
  const endTimeLabel = MinutesToLabel(props.slot.EndTimeMinutes);

  const handleDelete = (): void => {
    if (props.onDeleteClicked) {
      props.onDeleteClicked(props.slot);
    }
  };

  const siteCtx = useContext(SiteContext);

  const { data: slotType } = useGetSlotTypeQuery({
    siteID: siteCtx.site.id,
    slotTypeID: props.slot.SlotTypeID,
  });

  let title = '';
  if (slotType) {
    title = `Slot Type: ${slotType.Name}`;
  }

  return (
    <Tooltip title={title}>
      <Chip
        avatar={
          <Box
            sx={{
              borderRadius: '50%',
              width: 6,
              height: 6,
              bgcolor: `${slotType?.Color}`,
            }}
          />
        }
        label={`${startTimeLabel}-${endTimeLabel}`}
        onDelete={props.onDeleteClicked ? handleDelete : undefined}
        sx={{ mr: 1 }}
      />
    </Tooltip>
  );
};
