export const ErrorTagUnauthorized = 'UNAUTHORIZED';
export const ErrorTagForbidden = 'FORBIDDEN';
export const ErrorTagUnknown = 'UNKNOWN_ERROR';

export const ErrorTags = [
  ErrorTagForbidden,
  ErrorTagUnauthorized,
  ErrorTagUnknown,
];

export const getErrorTag = (
  status: number | string
): ['UNAUTHORIZED'] | ['FORBIDDEN'] | ['UNKNOWN_ERROR'] => {
  if (status === 401) {
    return [ErrorTagUnauthorized];
  } else if (status === 403) {
    return [ErrorTagForbidden];
  } else {
    return [ErrorTagUnknown];
  }
};
