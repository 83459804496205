import {
  AppBar,
  Box,
  LinearProgress,
  SimplePaletteColorOptions,
  Stack,
  styled,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { LoadingStatus } from '../../Contexts/LoadingStatus';
import { PermsContext } from '../../Contexts/PermissionsContext';
import { SiteContext } from '../../Contexts/SiteContext';
import { isExpanded, setExpanded } from '../../Contexts/UIState';
import { CalendarContextProvider } from '../../Pages/Calendar/CalendarContext';
import { ForbiddenPath, LoginPath } from '../../Routes';
import {
  getAssociatedSiteIDs,
  useGetRolesAndMembershipsQuery,
} from '../../Service/Me';
import { getCurrentSiteId } from '../../Util/Store';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';

declare module '@mui/material/styles' {
  interface Palette {
    accent: SimplePaletteColorOptions;
    understate: SimplePaletteColorOptions;
  }
  interface PaletteOptions {
    accent: SimplePaletteColorOptions;
    understate: SimplePaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    accent: true;
    understate: true;
  }
}

function createMargin(
  open: boolean | undefined,
  drawerwidth: number,
  smallscreen: 'true' | 'false'
): string {
  if (smallscreen === 'true' || open) {
    return '0';
  }
  return `-${drawerwidth}px`;
}

const MainLayout = () => {
  const theme = useTheme();

  const permsCtx = useContext(PermsContext);

  let currentSiteId = getCurrentSiteId();
  const siteCtx = useContext(SiteContext);

  const { data: rm } = useGetRolesAndMembershipsQuery();

  const loadContexts = async () => {
    await siteCtx.load(currentSiteId);
    if (currentSiteId === 0) {
      // TODO: Fix this once we have more than one site
      currentSiteId = getAssociatedSiteIDs(rm).find((s) => s > 0) || 1;
    }
    await permsCtx.load();
  };

  useEffect(() => {
    loadContexts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const expansionKey = 'sidebar';
  const expanded = isExpanded(expansionKey);
  const [sidebarOpen, setSidebarOpen] = useState(expanded);
  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    setExpanded(expansionKey, !sidebarOpen);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
    setExpanded(expansionKey, false);
  };

  const drawerWidth = 200;

  interface MainComponentProps {
    open?: boolean;
    smallscreen: 'true' | 'false';
  }

  const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
  })<MainComponentProps>(({ theme, open, smallscreen }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: createMargin(open, drawerWidth, smallscreen),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }));

  const location = useLocation();
  let headerExtraNav: JSX.Element | null = null;
  switch (location.pathname) {
    default:
      headerExtraNav = null;
  }

  switch (permsCtx.perms.loadingStatus) {
    case LoadingStatus.UNAUTHORIZED:
      window.location.pathname = LoginPath;
      return <></>;
    case LoadingStatus.FORBIDDEN:
      window.location.pathname = ForbiddenPath;
      return <></>;
    case LoadingStatus.ERROR:
      window.location.pathname = LoginPath;
      return <></>;
  }

  return (
    <>
      {permsCtx.perms.loadingStatus !== LoadingStatus.LOADED && (
        <LinearProgress />
      )}
      {permsCtx.perms.loadingStatus === LoadingStatus.LOADED && (
        <CalendarContextProvider>
          <AppBar
            variant="outlined"
            elevation={0}
            sx={{
              background: 'white',
              zIndex: (theme: any) => theme.zIndex.drawer + 1,
            }}
          >
            <Header
              children={headerExtraNav}
              onMenuButtonClick={handleToggleSidebar}
            />
          </AppBar>
          <Stack direction={'row'}>
            <Sidebar
              drawerWidth={drawerWidth}
              drawerOpen={sidebarOpen}
              onRequestClose={handleCloseSidebar}
            />
            <Main
              theme={theme}
              open={sidebarOpen}
              smallscreen={`${matchDownMd}`}
            >
              <Toolbar />
              <Box px={2}>
                <Outlet />
              </Box>
            </Main>
          </Stack>
        </CalendarContextProvider>
      )}
    </>
  );
};

export default MainLayout;
