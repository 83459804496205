import { Grid } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import Logo from '../../../Components/Layout/Header/Logo';
import AuthFooter from '../../../Components/UI/AuthFooter';
import PaymentCardWrapper from './PaymentCardWrapper';
import PaymentWrapper from './PaymentWrapper';

const PaymentLayout = () => {
  return (
    <PaymentWrapper>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: 'calc(100vh - 68px)' }}
          >
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <PaymentCardWrapper>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ mb: 3 }}>
                    <Link to="#">
                      <Logo />
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Outlet />
                  </Grid>
                </Grid>
              </PaymentCardWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
          <AuthFooter />
        </Grid>
      </Grid>
    </PaymentWrapper>
  );
};

export default PaymentLayout;
