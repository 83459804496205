/**
 * Password validator for login pages
 */
import { Theme } from '@mui/material';

// has number
const hasNumber = (number: string) => new RegExp(/[0-9]/).test(number);

// has mix of small and capitals
const hasMixed = (number: string) =>
  new RegExp(/[a-z]/).test(number) && new RegExp(/[A-Z]/).test(number);

// has special chars
const hasSpecial = (number: string) =>
  new RegExp(/[!#@$%^&*)(+=._-]/).test(number);

export type Strength = { label: string; color: string };
// set color based on password strength
export const strengthColor = (count: number, theme: Theme): Strength => {
  if (count < 2) return { label: 'Poor', color: theme.palette.error.main };
  if (count < 3) return { label: 'Weak', color: theme.palette.warning.dark };
  if (count < 4) return { label: 'Normal', color: theme.palette.warning.main };
  if (count < 5) return { label: 'Good', color: theme.palette.success.light };
  if (count < 6) return { label: 'Strong', color: theme.palette.success.main };
  return { label: 'Poor', color: theme.palette.error.main };
};

// password strength indicator
export const strengthIndicator = (number: string) => {
  let strengths = 0;
  if (number.length > 5) strengths += 1;
  if (number.length > 7) strengths += 1;
  if (hasNumber(number)) strengths += 1;
  if (hasSpecial(number)) strengths += 1;
  if (hasMixed(number)) strengths += 1;
  return strengths;
};
