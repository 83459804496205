import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import * as Yup from 'yup';
import { SiteContext } from '../../../Contexts/SiteContext';
import { PaymentProvider } from '../../../Model/Payments/PaymentProvider';
import { PaymentProviderType } from '../../../Model/Payments/PaymentProviderType';
import { VivaWallet } from '../../../Model/Payments/VivaWallet';
import { useAddPaymentProviderMutation } from '../../../Service/Payments';
import { getErrorMessage } from '../../../Util/Error';
import { VivaSettings } from './VivaSettings';

export type Props = {
  open: boolean;
  onClose: () => void;
};

export const AddProviderDialog = (props: Props) => {
  const schema = Yup.object().shape({
    Name: Yup.string().required('Name is required'),
    Description: Yup.string(),
    SiteID: Yup.number().required(),
    ProviderType: Yup.number().required().min(1).max(2),
    VivaSettings: Yup.object<VivaWallet>().shape({
      ClientID: Yup.string().required('Client ID is required'),
      ClientSecret: Yup.string().required('Client Secret is required'),
      MerchantID: Yup.string().required('Merchant ID is required'),
      APIKey: Yup.string().required('API Key is required'),
      BaseURL: Yup.string()
        .url('Invalid base url')
        .required('Base URL is required'),
      PaymentSource: Yup.number()
        .nullable()
        .required('Payment Source is required')
        .min(0),
    }),
  });

  const siteID = useContext(SiteContext).site.id;

  const {
    control,
    handleSubmit,
    setError,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Name: '',
      Description: '',
      SiteID: siteID,
      //Note: When we want to add more than one provider type we'll have
      //to make this into a form
      ProviderType: PaymentProviderType.VivaWallet,
      VivaSettings: {
        ClientID: '',
        ClientSecret: '',
        MerchantID: '',
        APIKey: '',
        BaseURL: '',
      } as VivaWallet,
    },
    resolver: yupResolver(schema),
  });

  const [addPaymentProvider] = useAddPaymentProviderMutation();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    console.debug('Add Provider Data', data);

    const provider: PaymentProvider = {
      ID: 0,
      Name: data.Name,
      Description: data.Description,
      ProviderType: data.ProviderType,
      IsActive: false,
      SiteID: data.SiteID,
      Data: data.VivaSettings,
    };

    try {
      await addPaymentProvider(provider).unwrap();

      props.onClose();
    } catch (e: any) {
      const msg = getErrorMessage(e);
      setError('root', { message: msg });
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('SiteID')} />
        <input type="hidden" {...register('ProviderType')} />
        <DialogTitle>Add Payment Provider</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} my={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel error={!!errors.Name}>Name</InputLabel>
                <Controller
                  name="Name"
                  control={control}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      label="Name"
                      error={!!errors.Name}
                    />
                  )}
                />
                <FormHelperText error>{errors.Name?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel error={!!errors.Description}>
                  Description
                </InputLabel>
                <Controller
                  name="Description"
                  control={control}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      label="Description"
                      error={!!errors.Description}
                    />
                  )}
                />
                <FormHelperText error>
                  {errors.Description?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            {
              <Grid item xs={12}>
                <Controller
                  name="VivaSettings"
                  control={control}
                  render={({ field, formState }) => (
                    <VivaSettings
                      errors={formState.errors.VivaSettings}
                      {...field}
                    />
                  )}
                />
              </Grid>
            }
            <Grid item xs={12}>
              <Typography color={'error'}>{errors.root?.message}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton type="submit">Add</LoadingButton>
          <Button color="inherit" onClick={props.onClose}>
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
