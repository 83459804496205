export const getCurrentSiteId = (): number => {
  const siteString = localStorage.getItem('currentSiteId');
  return parseInt(siteString || '0');
};

export const setCurrentSiteId = (siteId: number): void => {
  localStorage.setItem('currentSiteId', siteId.toString());
};

export const setToken = (token: string): void => {
  localStorage.setItem('token', token);
};

export const getToken = (): string | null => {
  return localStorage.getItem('token');
};

export const hasToken = (): boolean => {
  return !!getToken();
};

export const removeToken = () => {
  localStorage.removeItem('token');
};
