import { ThemeProvider, createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import MainLayout from './Components/Layout/MainLayout';
import {
  HasSiteAdminPerms,
  HasSiteCourtAdminPerm,
  HasSiteEventTypeAdminPerms,
  HasSiteMemberTypeMappingAdminPerms,
  HasSitePaymentProviderAdminPerms,
  HasSiteSlotTypeAdminPerms,
  HasSiteUserRolesAdminPerms,
} from './Contexts/PermissionsContext';
import { AddSitePage } from './Pages/AddSite/AddSitePage';
import AuthLayout from './Pages/Auth/AuthLayout';
import { ConfirmEmail } from './Pages/Auth/ConfirmEmail';
import AuthLogin from './Pages/Auth/Login';
import { Logout } from './Pages/Auth/Logout';
import { ResetPassword } from './Pages/Auth/ResetPassword';
import { SendEmailConfirmation } from './Pages/Auth/SendEmailConfirmation';
import { SendResetPassword } from './Pages/Auth/SendResetPassword';
import Signup from './Pages/Auth/Signup';
import { BookingsPage } from './Pages/Bookings/BookingsPage';
import CourtsCalendar from './Pages/Calendar/CourtsCalendar';
import { ManageCourtsPage } from './Pages/Courts/ManageCourtsPage';
import { Forbidden } from './Pages/Errors/Forbidden';
import { EventTypesPage } from './Pages/EventTypes/EventTypesPage';
import { GlobalAdminRoute } from './Pages/GlobalAdminRoute';
import { MemberRoute } from './Pages/MemberRoute';
import { ManageMembersPage } from './Pages/Members/ManageMembersPage';
import { Privacy } from './Pages/Notices/Privacy';
import { Terms } from './Pages/Notices/Terms';
import PaymentLayout from './Pages/Payments/Layout/PaymentLayout';
import { ManagePaymentsPage } from './Pages/Payments/ManagePaymentsPage';
import { PaymentFailure } from './Pages/Payments/Viva/PaymentFailure';
import { PaymentSuccess } from './Pages/Payments/Viva/PaymentSuccess';
import { PrivateRoute } from './Pages/PrivateRoute';
import { EditProfile } from './Pages/Profile/EditProfile';
import { UpdatePassword } from './Pages/Profile/UpdatePassword';
import { ManageSitePage } from './Pages/Site/ManageSitePage';
import { SitePermsRoute } from './Pages/SitePermsRoute';
import { ManageSlotTypeRulesPage } from './Pages/SlotTypes/ManageSlotTypeRulesPage';
import SlotsPager from './Pages/Slots/SlotsPager';
import { UserRolesPage } from './Pages/UserRoles/UserRolesPage';
import {
  AddSitePath,
  BookingsPath,
  CalendarPath,
  ConfirmEmailPath,
  ForbiddenPath,
  LoginPath,
  LogoutPath,
  ManageCourtsPath,
  ManageEventTypesPath,
  ManageMembersPath,
  ManagePaymentsPath,
  ManageSitePath,
  ManageSlotTypesPath,
  ManageUserRolesPath,
  OpenSlotsPath,
  PaymentsVivaFailurePath,
  PaymentsVivaSuccessPath,
  PrivacyPolicyPath,
  ProfilePath,
  ResetPasswordPath,
  SendEmailConfirmationLinkPath,
  SendResetPasswordLinkPath,
  SignupPath,
  TermsAndConditionsPath,
  UpdatePasswordPath,
} from './Routes';
import { useInterceptor } from './Service/Auth';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: OpenSlotsPath,
        element: (
          <PrivateRoute>
            <SlotsPager />
          </PrivateRoute>
        ),
      },
      {
        path: BookingsPath,
        element: (
          <PrivateRoute>
            <BookingsPage />
          </PrivateRoute>
        ),
      },
      {
        path: CalendarPath,
        element: (
          <PrivateRoute>
            <MemberRoute>
              <CourtsCalendar />
            </MemberRoute>
          </PrivateRoute>
        ),
      },
      {
        path: ProfilePath,
        element: (
          <PrivateRoute>
            <EditProfile />
          </PrivateRoute>
        ),
      },
      {
        path: UpdatePasswordPath,
        element: (
          <PrivateRoute>
            <UpdatePassword />
          </PrivateRoute>
        ),
      },
      {
        path: ManageCourtsPath,
        element: (
          <PrivateRoute>
            <SitePermsRoute perm={HasSiteCourtAdminPerm}>
              <ManageCourtsPage />
            </SitePermsRoute>
          </PrivateRoute>
        ),
      },
      {
        path: ManageEventTypesPath,
        element: (
          <PrivateRoute>
            <SitePermsRoute perm={HasSiteEventTypeAdminPerms}>
              <EventTypesPage />
            </SitePermsRoute>
          </PrivateRoute>
        ),
      },
      {
        path: ManageSitePath,
        element: (
          <PrivateRoute>
            <SitePermsRoute perm={HasSiteAdminPerms}>
              <ManageSitePage />
            </SitePermsRoute>
          </PrivateRoute>
        ),
      },
      {
        path: ManageSlotTypesPath,
        element: (
          <PrivateRoute>
            <SitePermsRoute perm={HasSiteSlotTypeAdminPerms}>
              <ManageSlotTypeRulesPage />
            </SitePermsRoute>
          </PrivateRoute>
        ),
      },
      {
        path: ManageMembersPath,
        element: (
          <PrivateRoute>
            <SitePermsRoute perm={HasSiteMemberTypeMappingAdminPerms}>
              <ManageMembersPage />
            </SitePermsRoute>
          </PrivateRoute>
        ),
      },
      {
        path: ManagePaymentsPath,
        element: (
          <PrivateRoute>
            <SitePermsRoute perm={HasSitePaymentProviderAdminPerms}>
              <ManagePaymentsPage />
            </SitePermsRoute>
          </PrivateRoute>
        ),
      },
      {
        path: ManageUserRolesPath,
        element: (
          <PrivateRoute>
            <SitePermsRoute perm={HasSiteUserRolesAdminPerms}>
              <UserRolesPage />
            </SitePermsRoute>
          </PrivateRoute>
        ),
      },
      {
        path: AddSitePath,
        element: (
          <PrivateRoute>
            <GlobalAdminRoute>
              <AddSitePage />
            </GlobalAdminRoute>
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/payments',
    element: <PaymentLayout />,
    children: [
      {
        path: PaymentsVivaSuccessPath,
        element: <PaymentSuccess />,
      },
      {
        path: PaymentsVivaFailurePath,
        element: <PaymentFailure />,
      },
    ],
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: LoginPath,
        element: <AuthLogin />,
      },
      {
        path: LogoutPath,
        element: <Logout />,
      },
      {
        path: SignupPath,
        element: <Signup />,
      },
      {
        path: SendResetPasswordLinkPath,
        element: <SendResetPassword />,
      },
      {
        path: ResetPasswordPath,
        element: <ResetPassword />,
      },
      {
        path: ConfirmEmailPath,
        element: <ConfirmEmail />,
      },
      {
        path: SendEmailConfirmationLinkPath,
        element: <SendEmailConfirmation />,
      },
      {
        path: '*',
        element: <AuthLogin />,
      },
    ],
  },
  {
    path: PrivacyPolicyPath,
    element: <Privacy />,
  },
  {
    path: TermsAndConditionsPath,
    element: <Terms />,
  },
  {
    path: ForbiddenPath,
    element: <Forbidden />,
  },
]);

declare module '@mui/material/styles' {
  interface Theme {
    border: {
      radius: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    border?: {
      radius?: string;
    };
  }
}

function App() {
  useInterceptor();

  const theme = createTheme({
    border: {
      radius: '7px',
    },
    palette: {
      primary: {
        light: '#b1f9d3',
        main: '#36c679',
        dark: '#519872',
      },
      secondary: {
        light: '#ffdf99',
        main: '#ffb81c',
        dark: '#986a05',
      },
      accent: {
        light: '#fad2d2',
        main: '#f08080',
        dark: '#a74a4a',
      },
      understate: {
        light: grey[100],
        main: grey[500],
        dark: grey[900],
      },
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
