import { Box, Popper, Stack, Typography } from '@mui/material';
import { ChangeEvent, SyntheticEvent, forwardRef, useState } from 'react';
import { ColorResult, GithubPicker } from 'react-color';

type ColorPickerProps = {
  value?: string;
  label?: string;
  onChange?: (value: string) => void;
};
export const ColorPicker = forwardRef((props: ColorPickerProps, ref: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [colorPopperOpen, setColorPopperOpen] = useState(false);
  function handleColorClicked(event: SyntheticEvent): void {
    setColorPopperOpen((prev) => !prev);
  }

  function handleColorChanged(
    color: ColorResult,
    _event: ChangeEvent<HTMLInputElement>
  ): void {
    setColorPopperOpen(false);
    if (props.onChange) {
      props.onChange(color.hex);
    }
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      display={'flex'}
      alignItems="center"
      ref={ref}
    >
      <Typography>{props.label}</Typography>
      <Box
        ref={setAnchorEl}
        sx={{
          width: '2rem',
          height: '2rem',
          cursor: 'pointer',
          borderRadius: '0.5rem',
          border: '1px solid gray',
          backgroundColor: `${props.value ? props.value : 'white'}`,
        }}
        onClick={handleColorClicked}
      />
      <Popper
        sx={{ zIndex: 1400 }}
        placement="bottom-start"
        open={colorPopperOpen}
        anchorEl={anchorEl}
      >
        <GithubPicker color={props.value} onChange={handleColorChanged} />
      </Popper>
    </Stack>
  );
});
