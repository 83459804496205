import { createApi } from '@reduxjs/toolkit/query/react';
import { BookingRule } from '../Model/BookingRule';
import { baseQuery } from './BaseQuery';
import { ErrorTags, getErrorTag } from './Error';

export const bookingRulesApi = createApi({
  reducerPath: 'bookingRulesApi',
  baseQuery,
  tagTypes: [...ErrorTags, 'BookingRules'],
  endpoints: (build) => ({
    getBookingRules: build.query<BookingRule[], number>({
      query: (siteID: number) => `/sites/${siteID}/booking-rules`,
      providesTags: (result, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return result
          ? [
              ...result.map(({ ID }) => ({ type: 'BookingRules', id: ID })),
              { type: 'BookingRules', id: 'LIST' },
            ]
          : [];
      },
    }),
    addBookingRule: build.mutation<
      BookingRule,
      { siteID: number; bookingRule: BookingRule }
    >({
      query: (arg: { siteID: number; bookingRule: BookingRule }) => ({
        url: `/sites/${arg.siteID}/booking-rules`,
        method: 'POST',
        body: arg.bookingRule,
      }),
      invalidatesTags: (result) => [
        { type: 'BookingRules', id: result?.ID },
        { type: 'BookingRules', id: 'LIST' },
      ],
    }),
    updateBookingRule: build.mutation<
      BookingRule,
      { siteID: number; bookingRule: BookingRule }
    >({
      query: (arg: { siteID: number; bookingRule: BookingRule }) => ({
        url: `/sites/${arg.siteID}/booking-rules/${arg.bookingRule.ID}`,
        method: 'PUT',
        body: arg.bookingRule,
      }),
      invalidatesTags: (result) => [
        { type: 'BookingRules', id: result?.ID },
        { type: 'BookingRules', id: 'LIST' },
      ],
    }),
    deleteBookingRule: build.mutation<void, { siteID: number; id: number }>({
      query: (arg: { siteID: number; id: number }) => ({
        url: `/sites/${arg.siteID}/booking-rules/${arg.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _arg, meta) => [
        { type: 'BookingRules', id: meta.id },
        { type: 'BookingRules', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetBookingRulesQuery,
  useAddBookingRuleMutation,
  useUpdateBookingRuleMutation,
  useDeleteBookingRuleMutation,
} = bookingRulesApi;
