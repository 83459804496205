import { DateTime } from 'luxon';

export const today = (): DateTime => {
  const now = DateTime.now();
  return DateTime.local(now.year, now.month, now.day);
};

export const daysFromToday = (days: number): DateTime => {
  return today().plus({ days: days });
};
