import { createApi } from '@reduxjs/toolkit/query/react';
import { MemberType } from '../Model/MemberType';
import { UserMembership, UserMemberships } from '../Model/UserMembership';
import { baseQuery } from './BaseQuery';
import { ErrorTags, getErrorTag } from './Error';

export type AddMembershipRequest = {
  SiteID: number;
  MemberTypeID: number;
  UserID: number;
  ExpirationDate: Date;
  GracePeriodValue: number;
  GracePeriodType: number;
};

export type DeleteMembershipRequest = {
  ID: number;
  UserID: number;
  MemberTypeID: number;
  SiteID: number;
};

export type UpdateMembershipRequest = {
  ID: number;
  UserID: number;
  MemberTypeID: number;
  SiteID: number;
  ExpirationDate: Date;
  GracePeriodValue: number;
  GracePeriodType: number;
};

export type GetMembershipsRequest = {
  SiteID: number;
  MemberTypeID: number;
};

export type DeleteMemberTypeRequest = GetMembershipsRequest;

export const memberTypesApi = createApi({
  reducerPath: 'memberTypesApi',
  baseQuery,
  tagTypes: [...ErrorTags, 'MemberTypes', 'Memberships'],
  endpoints: (build) => ({
    getMemberTypes: build.query<MemberType[], number>({
      query: (siteID: number) => `/sites/${siteID}/member-types`,
      providesTags: (result, error, ID) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return result
          ? [
              ...result.map(({ ID }) => ({ type: 'MemberTypes', id: ID })),
              { type: 'MemberTypes', id: 'LIST' },
            ]
          : [];
      },
    }),
    getMemberType: build.query<
      MemberType,
      { siteID: number; memberTypeID: number }
    >({
      query: ({ siteID, memberTypeID }) => ({
        url: `/sites/${siteID}/member-types/${memberTypeID}`,
      }),
      providesTags: (result, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }

        return result ? [{ type: 'MemberTypes', id: result.ID }] : [];
      },
    }),
    addMemberType: build.mutation<MemberType, MemberType>({
      query: (memberType: MemberType) => ({
        url: `/sites/${memberType.SiteID}/member-types`,
        method: 'POST',
        body: memberType,
      }),
      invalidatesTags: (result, _) => [
        { type: 'MemberTypes', id: result?.ID },
        { type: 'MemberTypes', id: 'LIST' },
      ],
    }),
    updateMemberType: build.mutation<MemberType, MemberType>({
      query: (memberType: MemberType) => ({
        url: `/sites/${memberType.SiteID}/member-types/${memberType.ID}`,
        method: 'PUT',
        body: memberType,
      }),
      invalidatesTags: (result, _) => [
        { type: 'MemberTypes', id: result?.ID },
        { type: 'MemberTypes', id: 'LIST' },
      ],
    }),
    deleteMemberType: build.mutation<MemberType, DeleteMemberTypeRequest>({
      query: ({ SiteID, MemberTypeID }: DeleteMemberTypeRequest) => ({
        url: `/sites/${SiteID}/member-types/${MemberTypeID}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _) => [
        { type: 'MemberTypes', id: result?.ID },
        { type: 'MemberTypes', id: 'LIST' },
      ],
    }),

    // Memberships
    getMemberships: build.query<UserMemberships, GetMembershipsRequest>({
      query: (r: GetMembershipsRequest) => ({
        url: `/sites/${r.SiteID}/member-types/${r.MemberTypeID}/members`,
      }),
      providesTags: (result, error, { MemberTypeID }) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return result
          ? [
              ...result?.items?.map(({ ID }) => ({
                type: 'Memberships',
                id: ID,
              })),
              { type: 'Memberships', id: 'LIST' },
              { type: 'MemberTypes', id: MemberTypeID },
            ]
          : [];
      },
    }),
    addMembership: build.mutation<UserMembership, AddMembershipRequest>({
      query: (r: AddMembershipRequest) => ({
        url: `/sites/${r.SiteID}/member-types/${r.MemberTypeID}/members`,
        method: 'POST',
        body: r,
      }),
      invalidatesTags: (result, _, { MemberTypeID }) => [
        { type: 'Memberships', id: result?.ID },
        { type: 'Memberships', id: 'LIST' },
        { type: 'MemberTypes', id: MemberTypeID },
      ],
    }),
    deleteMembership: build.mutation<void, DeleteMembershipRequest>({
      query: (r: DeleteMembershipRequest) => ({
        url: `/sites/${r.SiteID}/member-types/${r.MemberTypeID}/members/${r.UserID}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error, { ID, MemberTypeID }) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }

        return [
          { type: 'Memberships', id: ID },
          { type: 'Memberships', id: 'LIST' },
          { type: 'MemberTypes', id: MemberTypeID },
        ];
      },
    }),
    updateMembership: build.mutation<void, UpdateMembershipRequest>({
      query: (r: UpdateMembershipRequest) => ({
        url: `/sites/${r.SiteID}/member-types/${r.MemberTypeID}/members/${r.UserID}`,
        method: 'PUT',
        body: r,
      }),
      invalidatesTags: (_, error, { ID, MemberTypeID }) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }

        return [
          { type: 'Memberships', id: ID },
          { type: 'Memberships', id: 'LIST' },
          { type: 'MemberTypes', id: MemberTypeID },
        ];
      },
    }),
  }),
});

export const {
  useGetMemberTypesQuery,
  useGetMemberTypeQuery,
  useAddMemberTypeMutation,
  useUpdateMemberTypeMutation,
  useDeleteMemberTypeMutation,
  useAddMembershipMutation,
  useDeleteMembershipMutation,
  useGetMembershipsQuery,
  useUpdateMembershipMutation,
} = memberTypesApi;
