import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useContext, useState } from 'react';
import {
  HasSitePaymentProviderAdminPerms,
  PermsContext,
} from '../../Contexts/PermissionsContext';
import { SiteContext } from '../../Contexts/SiteContext';
import { Providers } from './Providers/Providers';

export const ManagePaymentsPage = () => {
  const [tab, setTab] = useState('1');

  const perms = useContext(PermsContext).perms;
  const siteID = useContext(SiteContext).site.id;
  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={(e, value) => setTab(value)}>
          {HasSitePaymentProviderAdminPerms(perms, siteID) && (
            <Tab label="Providers" value="1" />
          )}
          <Tab label="Payments" value="2" />
        </TabList>
      </Box>
      {HasSitePaymentProviderAdminPerms(perms, siteID) && (
        <TabPanel value="1">
          <Providers />
        </TabPanel>
      )}
      <TabPanel value="2">
        <h1>Payments</h1>
      </TabPanel>
    </TabContext>
  );
};
