import { Add } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { SlotBucket, SlotBucketEndMinutes } from '../../../Model/SlotBucket';
import { SlotType } from '../../../Model/SlotType';
import { SlotBucketComponent } from './SlotBucketComponent';

type SlotBucketCreatorProps = {
  initialStart: DateTime;
  value?: SlotBucket[];
  slotTypes?: SlotType[];
  onUpdate?: (value: SlotBucket[]) => void;
};

function updateStartTimes(initialStart: DateTime, updated: SlotBucket[]) {
  const newBuckets = [...updated];

  for (let i = 0; i < updated.length; i++) {
    const bucket = newBuckets[i];
    if (i === 0) {
      let initStartTimeMinutes = initialStart.minute + initialStart.hour * 60;
      bucket.StartTimeMinutes = initStartTimeMinutes;
    } else {
      const prev = newBuckets[i - 1];
      bucket.StartTimeMinutes = SlotBucketEndMinutes(prev);
    }
  }
}

export const SlotBucketCreator = (props: SlotBucketCreatorProps) => {
  const initValue = props.value;
  const [buckets, setBuckets] = useState<SlotBucket[]>(initValue ?? []);

  updateStartTimes(props.initialStart, buckets);

  function handleAddBucket(): void {
    const newBuckets = [...buckets];

    let startTime = props.initialStart.minute + props.initialStart.hour * 60;
    if (buckets.length > 0) {
      const last = buckets[buckets.length - 1];
      startTime = last.StartTimeMinutes + last.DurationMinutes;
    }
    newBuckets.push({
      StartTimeMinutes: startTime,
      NumSlots: 1,
      DurationMinutes: 90,
      SlotTypeID: 0,
    });
    updateStartTimes(props.initialStart, newBuckets);
    setBuckets(newBuckets);
    if (props.onUpdate) {
      props.onUpdate(newBuckets);
    }
  }

  function handleUpdateBucket(bucket: SlotBucket, index: number): void {
    const newBuckets = [...buckets];
    newBuckets[index] = bucket;
    updateStartTimes(props.initialStart, newBuckets);
    setBuckets(newBuckets);
    if (props.onUpdate) {
      props.onUpdate(newBuckets);
    }
  }

  function handleRemoveBucket(i: number) {
    const newBuckets = [...buckets];
    newBuckets.splice(i, 1);
    updateStartTimes(props.initialStart, newBuckets);
    setBuckets(newBuckets);
    if (props.onUpdate) {
      props.onUpdate(newBuckets);
    }
  }

  return (
    <Grid container spacing={2} alignItems={'center'}>
      <Grid item xs={12} textAlign="center">
        <Typography variant="h6">Buckets</Typography>
      </Grid>
      {buckets.map((bucket, i) => (
        <Grid item xs={12} key={i}>
          <SlotBucketComponent
            bucket={bucket}
            slotTypes={props.slotTypes}
            key={i}
            onDeleteClicked={() => handleRemoveBucket(i)}
            onUpdate={(bucket) => handleUpdateBucket(bucket, i)}
          />
        </Grid>
      ))}
      <Grid item container justifyContent="center" xs={12}>
        <Button
          color="primary"
          startIcon={<Add />}
          size="small"
          variant="outlined"
          onClick={handleAddBucket}
        >
          Add Bucket
        </Button>
      </Grid>
    </Grid>
  );
};
