import axios from 'axios';
import { getToken, removeToken } from '../Util/Store';

export const useInterceptor = () => {
  // Add token to request
  axios.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if (response.status === 401) {
        removeToken();
      }
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
