import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { ReactNode, useContext } from 'react';
import { NumericFormat } from 'react-number-format';
import { HelpTooltip } from '../../../Components/UI/HelpTooltip';
import { NumberInputExtended } from '../../../Components/UI/NumberInputExtended';
import { RelativeTimeRangeInput } from '../../../Components/UI/TimeRangeInput';
import { SiteContext } from '../../../Contexts/SiteContext';
import {
  BookingRule,
  NewBookingRule,
  ToBookingTimeRange,
  ToCancellationTimeRange,
  ValidateMemberTypeRule,
} from '../../../Model/BookingRule';
import {
  GetPriorityTypeName,
  PriorityType,
  PriorityTypes,
} from '../../../Model/Priority';
import { GetPeriodTypeName, PeriodTypes } from '../../../Model/Time/PeriodType';
import { TimeRange } from '../../../Model/Time/TimeRange';
import { useGetKBQuery } from '../../../Service/KB';
import { useGetMemberTypesQuery } from '../../../Service/MemberTypes';

type BookingRuleSettingsProps = {
  value?: BookingRule;
  onChange?: (value: BookingRule) => void;
};
export const BookingRuleSettings = (props: BookingRuleSettingsProps) => {
  //TODO: use site currency from context
  const currencySymbol = '€';

  const rule = props.value || NewBookingRule();

  const errors = ValidateMemberTypeRule(rule);
  const siteCtx = useContext(SiteContext);

  const { data: memberTypes } = useGetMemberTypesQuery(siteCtx.site.id);

  const handleMemberTypeSelected = (
    event: SelectChangeEvent<string | number>,
    _child: ReactNode
  ): void => {
    handleOnChange({
      ...rule,
      MemberTypeID: Number(event.target.value),
    });
  };

  const handlePeriodTypeSelected = (field: string, value: number): void => {
    switch (field) {
      case 'NumPaidPeriodType':
        handleOnChange({
          ...rule,
          NumPaidPeriodType: value,
        });
        break;
      case 'NumFreePeriodType':
        handleOnChange({
          ...rule,
          NumFreePeriodType: value,
        });
        break;
      case 'NumMaxPeriodType':
        handleOnChange({
          ...rule,
          NumMaxPeriodType: value,
        });
        break;
      case 'NumPaidPublicInvitesPeriodType':
        handleOnChange({
          ...rule,
          NumPaidPublicInvitesPeriodType: value,
        });
        break;
      case 'NumFreePublicInvitesPeriodType':
        handleOnChange({
          ...rule,
          NumFreePublicInvitesPeriodType: value,
        });
        break;
      case 'NumMaxPublicInvitesPeriodType':
        handleOnChange({
          ...rule,
          NumMaxPublicInvitesPeriodType: value,
        });
        break;
    }
  };

  const handleOnChange = (value: BookingRule): void => {
    if (value.NumPaid === 0) {
      value.PaidPrice = 0;
    }

    if (value.NumPaidPublicInvites === 0) {
      value.PaidInvitePrice = 0;
    }

    if (props.onChange) {
      props.onChange(value);
    }
  };

  const handleOnGameTypeChange = (value: number): void => {
    handleOnChange({
      ...rule,
      GameTypeID: value,
    });
  };

  function handleAmountsPriorityTypeSelected(
    event: SelectChangeEvent<PriorityType>,
    _child: ReactNode
  ): void {
    handleOnChange({
      ...rule,
      AmountsPriorityType: Number(event.target.value),
    });
  }

  function handleTimeRangePriorityTypeSelected(
    event: SelectChangeEvent<PriorityType>,
    _child: ReactNode
  ): void {
    handleOnChange({
      ...rule,
      TimeRangePriorityType: Number(event.target.value),
    });
  }

  const maxSlotsTooltipText =
    'The maximum number of total slots for this member type.' +
    ' Useful for when we want we want to restrict the total number of slots ' +
    'for a member type independently of the number of paid and free slots. ' +
    'e.g. If we want to restrict the total number of slots to 10 but want ' +
    'to allocate either 10 free slots or 10 paid ones, set free, paid and ' +
    'max slots to 10';

  const amountsPriorityTypeTooltipText =
    'This option determines which rule should be considered FOR THE AMOUNT ' +
    'OF ALLOWABLE SLOTS if a member type rule AND a slot type rule exist for ' +
    'a specific slot. Useful for when we want to override member type rules ' +
    'for specific slot types.';

  const timeRangePriorityTypeTooltipText =
    'This option determines which rule should be considered FOR THE TIME ' +
    'RANGE ALLOWANCES if a member type rule AND a slot type rule exist for ' +
    'a specific slot. Useful for when we want to override member type rules ' +
    'for specific slot types.';

  const gameTypeTooltipText =
    "When setting this option to 'All Game Types', the rule will only " +
    'check for free, paid and max allowances. It WILL NOT create any slots ' +
    'for the game type. It is useful for checking if a user has exceeded ' +
    'the number of slots allocated across all game types.';

  function handleBookingTimeRangeChange(
    value: TimeRange,
    _valid?: boolean | undefined
  ): void {
    handleOnChange({
      ...rule,
      BookingAnchorType: value.AnchorType,
      BookingStartPeriodType: value.StartPeriodType,
      BookingStartPeriodValue: value.StartPeriodValue,
      BookingEndPeriodType: value.EndPeriodType,
      BookingEndPeriodValue: value.EndPeriodValue,
    });
  }

  function handleCancellationTimeRangeChange(
    value: TimeRange,
    _valid?: boolean | undefined
  ): void {
    handleOnChange({
      ...rule,
      CancellationAnchorType: value.AnchorType,
      CancellationStartPeriodType: value.StartPeriodType,
      CancellationStartPeriodValue: value.StartPeriodValue,
      CancellationEndPeriodType: value.EndPeriodType,
      CancellationEndPeriodValue: value.EndPeriodValue,
    });
  }

  const { data: kb } = useGetKBQuery();

  return (
    <Grid container mt={1} spacing={2} textAlign={'center'} display={'flex'}>
      <Grid item xs={12}>
        <Typography variant="h6">General Settings</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="game-type-select-label">Game Type</InputLabel>
          <HelpTooltip text={gameTypeTooltipText}>
            <Select
              label="Game Type"
              variant="outlined"
              disabled={rule.ID !== 0}
              fullWidth
              value={rule.GameTypeID || 0}
              onChange={(event) =>
                handleOnGameTypeChange(Number(event.target.value))
              }
            >
              <MenuItem key={0} value={0}>
                <em>All Game Types</em>
              </MenuItem>
              {kb?.GameTypes.filter((gt) => !gt.AdminOnly).map((gt) => (
                <MenuItem key={gt.ID} value={gt.ID}>
                  {gt.Name}
                  {gt.Subtype ? ` - ${gt.Subtype}` : ''}
                </MenuItem>
              ))}
            </Select>
          </HelpTooltip>
          <FormHelperText error>{errors.GameTypeID}</FormHelperText>
        </FormControl>
      </Grid>
      {rule.SlotTypeID > 0 && (
        <>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="member-type-select-label">Member Type</InputLabel>
              <Select
                label="Member Type"
                variant="outlined"
                disabled={rule.ID !== 0}
                fullWidth
                value={rule.MemberTypeID}
                onChange={handleMemberTypeSelected}
              >
                <MenuItem key={0} value={0}>
                  <em>None</em>&nbsp;(Public Rule)
                </MenuItem>
                {memberTypes?.map((memberType) => (
                  <MenuItem key={memberType.ID} value={memberType.ID}>
                    {memberType.Name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{errors.MemberTypeID}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="allocation-type-select-label">
                Amounts Priority
              </InputLabel>
              <HelpTooltip text={amountsPriorityTypeTooltipText}>
                <Select
                  label="Amounts Priority"
                  variant="outlined"
                  fullWidth
                  value={rule.AmountsPriorityType || 0}
                  onChange={handleAmountsPriorityTypeSelected}
                >
                  {PriorityTypes.map((pt) => (
                    <MenuItem key={pt} value={pt}>
                      {GetPriorityTypeName(pt)}
                    </MenuItem>
                  ))}
                </Select>
              </HelpTooltip>
              <FormHelperText error>
                {errors.AmountsPriorityType}
              </FormHelperText>
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <NumberInputExtended
            label="Max Simultaneous Bookings"
            value={rule.MaxSimultaneousBookings}
            unlimitedValue={-1}
            min={-1}
            onUnlimitedClick={(value) => {
              handleOnChange({
                ...rule,
                MaxSimultaneousBookings: value ? -1 : 0,
              });
            }}
            onChange={(value) => {
              handleOnChange({
                ...rule,
                MaxSimultaneousBookings: value ? value : 0,
              });
            }}
          />
          <FormHelperText error>
            {errors.MaxSimultaneousBookings}
          </FormHelperText>
        </FormControl>
      </Grid>

      {(rule.SlotTypeID === 0 ||
        rule.AmountsPriorityType === PriorityType.SlotType) && (
        <>
          {/* FREE SLOTS */}
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Divider variant="middle" sx={{ width: '25%' }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ textDecoration: 'underline' }}>
              Amounts Category
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Free Slots</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="period-select-label">Reset Every</InputLabel>
              <Select
                label="Reset every"
                variant="outlined"
                fullWidth
                value={rule.NumFreePeriodType}
                onChange={(value) =>
                  handlePeriodTypeSelected(
                    'NumFreePeriodType',
                    Number(value.target.value)
                  )
                }
              >
                {PeriodTypes.map((periodType) => (
                  <MenuItem key={periodType} value={periodType}>
                    {GetPeriodTypeName(periodType)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{errors.NumFreePeriodType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <NumberInputExtended
                label="Amount"
                value={rule.NumFree}
                unlimitedValue={-1}
                min={-1}
                onUnlimitedClick={(value) => {
                  handleOnChange({
                    ...rule,
                    NumFree: value ? -1 : 0,
                  });
                }}
                onChange={(value) => {
                  handleOnChange({
                    ...rule,
                    NumFree: value ? value : 0,
                  });
                }}
              />
              <FormHelperText error>{errors.NumFree}</FormHelperText>
            </FormControl>
          </Grid>

          {/* PAID SLOTS */}
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Divider variant="middle" sx={{ width: '25%' }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Paid Slots</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="period-select-label">Reset Every</InputLabel>
              <Select
                label="Reset every"
                variant="outlined"
                fullWidth
                value={rule.NumPaidPeriodType}
                onChange={(value) =>
                  handlePeriodTypeSelected(
                    'NumPaidPeriodType',
                    Number(value.target.value)
                  )
                }
              >
                {PeriodTypes.map((periodType) => (
                  <MenuItem key={periodType} value={periodType}>
                    {GetPeriodTypeName(periodType)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{errors.NumPaidPeriodType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <NumberInputExtended
                label="Amount"
                value={rule.NumPaid}
                unlimitedValue={-1}
                min={-1}
                onUnlimitedClick={(value) => {
                  handleOnChange({
                    ...rule,
                    NumPaid: value ? -1 : 0,
                  });
                }}
                onChange={(value) => {
                  handleOnChange({
                    ...rule,
                    NumPaid: value ? value : 0,
                  });
                }}
              />
              <FormHelperText error>{errors.NumPaid}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item alignContent={'center'} xs={12} sm={4}>
            {rule.GameTypeID > 0 && (
              <FormControl fullWidth>
                <NumericFormat
                  label="Slot Price Per Slot"
                  customInput={TextField}
                  fixedDecimalScale
                  thousandSeparator
                  disabled={(rule.NumPaid ?? 0) === 0}
                  value={rule.PaidPrice}
                  onValueChange={({ floatValue }) => {
                    if ((floatValue ?? 0) < 0) {
                      return;
                    }
                    handleOnChange({
                      ...rule,
                      PaidPrice: floatValue ?? 0,
                    });
                  }}
                  decimalScale={2}
                  prefix={currencySymbol}
                />
                <FormHelperText error>{errors.PaidPrice}</FormHelperText>
              </FormControl>
            )}
          </Grid>

          {/* MAX SLOTS */}
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Divider variant="middle" sx={{ width: '25%' }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Max Slots</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="period-select-label">Reset Every</InputLabel>
              <Select
                label="Reset every"
                variant="outlined"
                fullWidth
                value={rule.NumMaxPeriodType}
                onChange={(value) =>
                  handlePeriodTypeSelected(
                    'NumMaxPeriodType',
                    Number(value.target.value)
                  )
                }
              >
                {PeriodTypes.map((periodType) => (
                  <MenuItem key={periodType} value={periodType}>
                    {GetPeriodTypeName(periodType)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{errors.NumMaxPeriodType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <HelpTooltip text={maxSlotsTooltipText}>
                <NumberInputExtended
                  label="Amount"
                  value={rule.NumMax}
                  unlimitedValue={-1}
                  min={-1}
                  onUnlimitedClick={(value) => {
                    handleOnChange({
                      ...rule,
                      NumMax: value ? -1 : 0,
                    });
                  }}
                  onChange={(value) => {
                    handleOnChange({
                      ...rule,
                      NumMax: value ? value : 0,
                    });
                  }}
                />
              </HelpTooltip>
              <FormHelperText error>{errors.NumMax}</FormHelperText>
            </FormControl>
          </Grid>

          {/* FREE PUBLIC INVITES */}
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Divider variant="middle" sx={{ width: '25%' }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Free Public Invites</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="period-select-label">Reset Every</InputLabel>
              <Select
                label="Reset every"
                variant="outlined"
                fullWidth
                value={rule.NumFreePublicInvitesPeriodType}
                onChange={(value) =>
                  handlePeriodTypeSelected(
                    'NumFreePublicInvitesPeriodType',
                    Number(value.target.value)
                  )
                }
              >
                {PeriodTypes.map((periodType) => (
                  <MenuItem key={periodType} value={periodType}>
                    {GetPeriodTypeName(periodType)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {errors.NumFreePublicInvites}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <NumberInputExtended
                label="Amount"
                value={rule.NumFreePublicInvites}
                unlimitedValue={-1}
                min={-1}
                onUnlimitedClick={(value) => {
                  handleOnChange({
                    ...rule,
                    NumFreePublicInvites: value ? -1 : 0,
                  });
                }}
                onChange={(value) => {
                  handleOnChange({
                    ...rule,
                    NumFreePublicInvites: value ? value : 0,
                  });
                }}
              />
              <FormHelperText error>{errors.NumFree}</FormHelperText>
            </FormControl>
          </Grid>

          {/* PAID PUBLIC INVITES */}
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Divider variant="middle" sx={{ width: '25%' }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Paid Public Invites</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="period-select-label">Reset Every</InputLabel>
              <Select
                label="Reset every"
                variant="outlined"
                fullWidth
                value={rule.NumPaidPublicInvitesPeriodType}
                onChange={(value) =>
                  handlePeriodTypeSelected(
                    'NumPaidPublicInvitesPeriodType',
                    Number(value.target.value)
                  )
                }
              >
                {PeriodTypes.map((periodType) => (
                  <MenuItem key={periodType} value={periodType}>
                    {GetPeriodTypeName(periodType)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{errors.NumPaidPeriodType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <NumberInputExtended
                label="Amount"
                value={rule.NumPaidPublicInvites}
                unlimitedValue={-1}
                min={-1}
                onUnlimitedClick={(value) => {
                  handleOnChange({
                    ...rule,
                    NumPaidPublicInvites: value ? -1 : 0,
                  });
                }}
                onChange={(value) => {
                  handleOnChange({
                    ...rule,
                    NumPaidPublicInvites: value ? value : 0,
                  });
                }}
              />
              <FormHelperText error>
                {errors.NumPaidPublicInvites}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item alignContent={'center'} xs={12} sm={4}>
            {rule.GameTypeID > 0 && (
              <FormControl fullWidth>
                <NumericFormat
                  label="Invite Price Per Slot"
                  customInput={TextField}
                  thousandSeparator
                  fixedDecimalScale
                  disabled={(rule.NumPaidPublicInvites ?? 0) === 0}
                  value={rule.PaidInvitePrice}
                  onValueChange={({ floatValue }) => {
                    if ((floatValue ?? 0) < 0) {
                      return;
                    }
                    handleOnChange({
                      ...rule,
                      PaidInvitePrice: floatValue ?? 0,
                    });
                  }}
                  decimalScale={2}
                  prefix={currencySymbol}
                />
                <FormHelperText error>{errors.PaidInvitePrice}</FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Divider variant="middle" sx={{ width: '25%' }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Max Public Invites</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="period-select-label">Reset Every</InputLabel>
              <Select
                label="Reset every"
                variant="outlined"
                fullWidth
                value={rule.NumMaxPublicInvitesPeriodType}
                onChange={(value) =>
                  handlePeriodTypeSelected(
                    'NumMaxPublicInvitesPeriodType',
                    Number(value.target.value)
                  )
                }
              >
                {PeriodTypes.map((periodType) => (
                  <MenuItem key={periodType} value={periodType}>
                    {GetPeriodTypeName(periodType)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {errors.MaxPublicInvitesPeriodType}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <NumberInputExtended
                label="Amount"
                value={rule.NumMaxPublicInvites}
                unlimitedValue={-1}
                min={-1}
                onUnlimitedClick={(value) => {
                  handleOnChange({
                    ...rule,
                    NumMaxPublicInvites: value ? -1 : 0,
                  });
                }}
                onChange={(value) => {
                  handleOnChange({
                    ...rule,
                    NumMaxPublicInvites: value ? value : 0,
                  });
                }}
              />
              <FormHelperText error>
                {errors.NumMaxPublicInvites}
              </FormHelperText>
            </FormControl>
          </Grid>
        </>
      )}
      {rule.GameTypeID > 0 && (
        <>
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Divider variant="middle" sx={{ width: '25%' }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ textDecoration: 'underline' }}>
              Time Range Category
            </Typography>
          </Grid>
          {rule.SlotTypeID > 0 && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="allocation-type-select-label">
                  Time Range Priority
                </InputLabel>
                <HelpTooltip text={timeRangePriorityTypeTooltipText}>
                  <Select
                    label="Time Range Priority"
                    variant="outlined"
                    fullWidth
                    value={rule.TimeRangePriorityType || 0}
                    onChange={handleTimeRangePriorityTypeSelected}
                  >
                    {PriorityTypes.map((pt) => (
                      <MenuItem key={pt} value={pt}>
                        {GetPriorityTypeName(pt)}
                      </MenuItem>
                    ))}
                  </Select>
                </HelpTooltip>
                <FormHelperText error>
                  {errors.TimeRangePriorityType}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          {(rule.SlotTypeID === 0 ||
            rule.TimeRangePriorityType === PriorityType.SlotType) && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">Booking Time Range</Typography>
              </Grid>
              <Grid item xs={12}>
                <RelativeTimeRangeInput
                  value={ToBookingTimeRange(rule)}
                  onChange={handleBookingTimeRangeChange}
                />
              </Grid>
              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                <Divider variant="middle" sx={{ width: '25%' }} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">Cancellation Time Range</Typography>
              </Grid>
              <Grid item xs={12}>
                <RelativeTimeRangeInput
                  value={ToCancellationTimeRange(rule)}
                  onChange={handleCancellationTimeRangeChange}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};
