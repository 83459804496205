import { Box, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { Event, GroupedEvents } from '../../Model/Event';
import DaySlots from './DaySlots';

export interface MultiDaySlotsProps {
  slots?: GroupedEvents;
  handleClick?: (slot: Event) => void;
}

const MultiDaySlots = (props: MultiDaySlotsProps) => {
  const { slots } = props;

  const keys = slots ? Object.keys(slots) : [];
  return (
    <Box key={keys[0]}>
      <Grid container spacing={2} justifyContent={'center'}>
        {keys.map((key) => (
          <Grid item key={key}>
            {slots && (
              <DaySlots
                handleClick={props.handleClick}
                events={slots[key]}
                date={DateTime.fromISO(key)}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MultiDaySlots;
