import { CalendarMonth, KeyboardArrowDown } from '@mui/icons-material';
import { Button, Dialog, Stack } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import { DateTime } from 'luxon';
import { useState } from 'react';

interface DateSelectorProps {
  date: DateTime;
  maxDate?: DateTime;
  minDate?: DateTime;
  onSelectDate: (date: DateTime) => void;
}
const DateSelector = (props: DateSelectorProps) => {
  const { date, maxDate, minDate } = props;

  const [open, setOpen] = useState(false);

  function handleDateChange(
    value: DateTime | null,
    selectionState?: PickerSelectionState | undefined
  ): void {
    if (!value || selectionState !== 'finish') {
      return;
    }

    if (props.onSelectDate) {
      props.onSelectDate(value);
    }
    setOpen(false);
  }

  return (
    <Stack direction="row">
      <Button
        variant="text"
        style={{ textTransform: 'none', color: 'rgb(95,99,104)' }}
        sx={{ fontSize: { xs: 'h6.fontSize' } }}
        onClick={() => setOpen((prev) => !prev)}
        endIcon={<KeyboardArrowDown />}
      >
        <CalendarMonth />
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DateCalendar
            value={date}
            disablePast
            timezone="system"
            maxDate={maxDate ? maxDate : undefined}
            minDate={minDate ? minDate : undefined}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
      </Dialog>
    </Stack>
  );
};

export default DateSelector;
