export interface EventType {
  ID: number;
  Name: string;
  Description: string;
  Color: string;
  SiteID: number;
}

export function GetEventTypeColor(
  id: number,
  eventTypes?: EventType[]
): string {
  if (!eventTypes) {
    return '#FFFFFF';
  }
  const eventType = eventTypes.find((eventType) => eventType.ID === id);
  if (eventType) {
    return eventType.Color;
  }
  return '#000000';
}
