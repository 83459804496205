import { useEffect, useState } from 'react';

export type ContainerSize = {
  width: number;
  height: number;
};

const useContainerSize = (id: string): ContainerSize => {
  const el = document.getElementById(id);
  let initialSize = !el
    ? { width: 0, height: 0 }
    : {
        width: Math.floor(el.offsetWidth),
        height: Math.floor(el.offsetHeight),
      };

  const [size, setSize] = useState(initialSize);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      const container = event[0].contentBoxSize[0];

      const width = Math.floor(container.inlineSize);
      const height = Math.floor(container.blockSize);

      setSize({ width, height });
    });

    if (!el) {
      return;
    }
    resizeObserver.observe(el);
    return () => {};
  }, [el]);
  return size;
};

export default useContainerSize;
