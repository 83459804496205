import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import * as yup from 'yup';
import { SnackBarInfo } from '../../Model/SnackBarInfo';
import { toApiUrl } from '../../Util/Api';
import { getErrorMessage } from '../../Util/Error';
import {
  Strength,
  strengthColor,
  strengthIndicator,
} from '../../Util/password-strength';

export const UpdatePassword = () => {
  const schema = yup.object().shape({
    oldPassword: yup.string().required('Old Password is required'),
    newPassword: yup
      .string()
      .required('Password is required')
      .min(8, "Password can't be less than 8 characters")
      .matches(
        /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^*()& "]).*$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
      ),
  });

  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarInfo>();

  const handleOnCloseSnackBar = (): void => {
    setSnackBarInfo({
      message: '',
      severity: 'success',
      open: false,
    });
  };

  const [strength, setStrength] = useState(0);
  const [level, setLevel] = useState<Strength>();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const [showOldPassword, setShowOldPassword] = useState(false);
  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const theme = useTheme();

  const changePassword = (value: string) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp, theme));
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (data.newPassword === data.oldPassword) {
      setError('oldPassword', {
        message: 'New and old password must be different',
      });
      setError('newPassword', {
        message: 'New and old password must be different',
      });
      return;
    }

    try {
      await axios.post(toApiUrl('/auth/update-password'), {
        old_password: data.oldPassword,
        new_password: data.newPassword,
      });

      setSnackBarInfo({
        message: 'Password updated successfully',
        severity: 'success',
        open: true,
      });
    } catch (e: any) {
      const msg = getErrorMessage(e);
      setError('root', { message: msg });
    }
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Card sx={{ marginX: 'auto', maxWidth: '500px' }}>
            <CardHeader title="Update Password" />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={!!errors.oldPassword}
                  >
                    <InputLabel htmlFor="oldPassword">Old Password</InputLabel>
                    <Controller
                      name="oldPassword"
                      control={control}
                      render={({ field }) => (
                        <OutlinedInput
                          {...field}
                          id="oldPassword"
                          name="oldPassword"
                          type={showOldPassword ? 'text' : 'password'}
                          label="Old Password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="large"
                              >
                                {showOldPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      )}
                    />
                    <FormHelperText>
                      {errors.oldPassword?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={!!errors.newPassword}
                  >
                    <InputLabel htmlFor="newPassword">New Password</InputLabel>
                    <Controller
                      name="newPassword"
                      control={control}
                      render={({ field }) => (
                        <OutlinedInput
                          label="New Password"
                          type={showNewPassword ? 'text' : 'password'}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="large"
                              >
                                {showNewPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          disabled={isSubmitting}
                          id="outlined-adornment-password-register"
                          {...field}
                          onChange={(e) => {
                            changePassword(e.target.value);
                            field.onChange(e);
                          }}
                        />
                      )}
                    />

                    <FormHelperText>
                      {errors.newPassword?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {strength !== 0 && (
                    <FormControl fullWidth>
                      <Box sx={{ mb: 2 }}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <Box
                              style={{ backgroundColor: level?.color }}
                              sx={{
                                width: 85,
                                height: 8,
                                borderRadius: `${theme.border.radius}`,
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle1" fontSize="0.75rem">
                              {level?.label}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography color={'error'}>
                    {errors.root?.message}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Container sx={{ textAlign: 'center' }}>
                <Button type="submit" variant="contained">
                  Update Password
                </Button>
              </Container>
            </CardActions>
          </Card>
        </Container>
      </form>
      <Snackbar
        open={snackBarInfo?.open}
        autoHideDuration={6000}
        onClose={handleOnCloseSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleOnCloseSnackBar}
          severity={snackBarInfo?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackBarInfo?.message}
        </Alert>
      </Snackbar>
    </>
  );
};
