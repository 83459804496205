import { Component } from 'react';
const privacy = `<style>
      [data-custom-class='body'],
      [data-custom-class='body'] * {
        background: transparent !important;
      }
      [data-custom-class='title'],
      [data-custom-class='title'] * {
        font-family: Arial !important;
        font-size: 26px !important;
        color: #000000 !important;
      }
      [data-custom-class='subtitle'],
      [data-custom-class='subtitle'] * {
        font-family: Arial !important;
        color: #595959 !important;
        font-size: 14px !important;
      }
      [data-custom-class='heading_1'],
      [data-custom-class='heading_1'] * {
        font-family: Arial !important;
        font-size: 19px !important;
        color: #000000 !important;
      }
      [data-custom-class='heading_2'],
      [data-custom-class='heading_2'] * {
        font-family: Arial !important;
        font-size: 17px !important;
        color: #000000 !important;
      }
      [data-custom-class='body_text'],
      [data-custom-class='body_text'] * {
        color: #595959 !important;
        font-size: 14px !important;
        font-family: Arial !important;
      }
      [data-custom-class='link'],
      [data-custom-class='link'] * {
        color: #3030f1 !important;
        font-size: 14px !important;
        font-family: Arial !important;
        word-break: break-word !important;
      }
    </style>
    <div data-custom-class="body">
      <div>
        <strong
          ><span style="font-size: 26px"
            ><span data-custom-class="title"
              ><bdt class="block-component"></bdt
              ><bdt class="question">PRIVACY POLICY</bdt
              ><bdt class="statement-end-if-in-editor"></bdt></span></span
        ></strong>
      </div>
      <div><br /></div>
      <div>
        <span style="color: rgb(127, 127, 127)"
          ><strong
            ><span style="font-size: 15px"
              ><span data-custom-class="subtitle"
                >Last updated
                <bdt class="question">September 18, 2024</bdt></span
              ></span
            ></strong
          ></span
        >
      </div>
      <div><br /></div>
      <div><br /></div>
      <div><br /></div>
      <div style="line-height: 1.5">
        <span style="color: rgb(127, 127, 127)"
          ><span style="color: rgb(89, 89, 89); font-size: 15px"
            ><span data-custom-class="body_text"
              >This Privacy Notice for
              <bdt class="question"
                >Argonaut Security LLC<bdt class="block-component"></bdt
              ></bdt>
              (<bdt class="block-component"></bdt>"<strong>we</strong>,"
              "<strong>us</strong>," or "<strong>our</strong>"<bdt
                class="statement-end-if-in-editor"
              ></bdt></span
            ><span data-custom-class="body_text"
              >), describes how and why we might access, collect, store, use,
              and/or share (<bdt class="block-component"></bdt
              >"<strong>process</strong>"<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >) your personal information when you use our services (<bdt
                class="block-component"
              ></bdt
              >"<strong>Services</strong>"<bdt
                class="statement-end-if-in-editor"
              ></bdt
              >), including when you:</span
            ></span
          ></span
        ><span style="font-size: 15px"
          ><span style="color: rgb(127, 127, 127)"
            ><span data-custom-class="body_text"
              ><span style="color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><bdt
                    class="block-component"
                  ></bdt></span></span></span></span
        ></span>
      </div>
      <ul>
        <li data-custom-class="body_text" style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                >Visit our website<bdt class="block-component"></bdt> at
                <span style="color: rgb(0, 58, 250)"
                  ><bdt class="question"
                    ><a
                      href="https://app.courtgo.com"
                      target="_blank"
                      data-custom-class="link"
                      >https://app.courtgo.com</a
                    ></bdt
                  ></span
                ><span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><span style="font-size: 15px"
                        ><span style="color: rgb(89, 89, 89)"
                          ><bdt class="statement-end-if-in-editor"
                            >, or any website of ours that links to this Privacy
                            Notice</bdt
                          ></span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            ></span
          >
        </li>
      </ul>
      <div>
        <bdt class="block-component"
          ><span style="font-size: 15px"
            ><span style="font-size: 15px"
              ><span style="color: rgb(127, 127, 127)"
                ><span data-custom-class="body_text"
                  ><span style="color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><bdt
                        class="block-component"
                      ></bdt></span></span></span></span></span></span
        ></bdt>
        <div style="line-height: 1.5">
          <bdt class="block-component"
            ><span style="font-size: 15px"></span
          ></bdt>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span style="color: rgb(127, 127, 127)"
                ><span data-custom-class="body_text"
                  ><span style="color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><bdt
                        class="block-component"
                      ></bdt></span></span></span></span
            ></span>
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    >Engage with us in other related ways, including any sales,
                    marketing, or events<span style="font-size: 15px"
                      ><span style="color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><span style="font-size: 15px"
                            ><span style="color: rgb(89, 89, 89)"
                              ><bdt
                                class="statement-end-if-in-editor"
                              ></bdt></span></span></span></span></span></span></span
              ></span>
            </li>
          </ul>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span style="color: rgb(127, 127, 127)"
                ><span data-custom-class="body_text"
                  ><strong>Questions or concerns?&nbsp;</strong>Reading this
                  Privacy Notice will help you understand your privacy rights
                  and choices. We are responsible for making decisions about how
                  your personal information is processed. If you do not agree
                  with our policies and practices, please do not use our
                  Services.<bdt class="block-component"></bdt> If you still have
                  any questions or concerns, please contact us at
                  <bdt class="question">privacy@courtgo.com</bdt>.</span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <strong
              ><span style="font-size: 15px"
                ><span data-custom-class="heading_1"
                  >SUMMARY OF KEY POINTS</span
                ></span
              ></strong
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong
                  ><em
                    >This summary provides key points from our Privacy Notice,
                    but you can find out more details about any of these topics
                    by clicking the link following each key point or by using
                    our&nbsp;</em
                  ></strong
                ></span
              ></span
            ><a data-custom-class="link" href="#toc"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><strong><em>table of contents</em></strong></span
                ></span
              ></a
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong
                  ><em
                    >&nbsp;below to find the section you are looking for.</em
                  ></strong
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong>What personal information do we process?</strong> When
                you visit, use, or navigate our Services, we may process
                personal information depending on how you interact with us and
                the Services, the choices you make, and the products and
                features you use. Learn more about&nbsp;</span
              ></span
            ><a data-custom-class="link" href="#personalinfo"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >personal information you disclose to us</span
                ></span
              ></a
            ><span data-custom-class="body_text">.</span>
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong
                  >Do we process any sensitive personal
                  information?&nbsp;</strong
                >Some of the information may be considered
                <bdt class="block-component"></bdt>"special" or "sensitive"<bdt
                  class="statement-end-if-in-editor"
                ></bdt>
                in certain jurisdictions, for example your racial or ethnic
                origins, sexual orientation, and religious beliefs.
                <bdt class="block-component"></bdt>We do not process sensitive
                personal information.<bdt class="else-block"></bdt></span
            ></span>
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong
                  >Do we collect any information from third parties?</strong
                >
                <bdt class="block-component"></bdt>We do not collect any
                information from third parties.<bdt
                  class="else-block"
                ></bdt></span
            ></span>
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong>How do we process your information?</strong> We process
                your information to provide, improve, and administer our
                Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do
                so. Learn more about&nbsp;</span
              ></span
            ><a data-custom-class="link" href="#infouse"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >how we process your information</span
                ></span
              ></a
            ><span data-custom-class="body_text">.</span>
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong
                  >In what situations and with which
                  <bdt class="block-component"></bdt>parties do we share
                  personal information?</strong
                >
                We may share information in specific situations and with
                specific <bdt class="block-component"></bdt>third parties. Learn
                more about&nbsp;</span
              ></span
            ><a data-custom-class="link" href="#whoshare"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >when and with whom we share your personal information</span
                ></span
              ></a
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text"
                >.<bdt class="block-component"></bdt></span
            ></span>
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong>How do we keep your information safe?</strong> We have
                adequate <bdt class="block-component"></bdt>organizational<bdt
                  class="statement-end-if-in-editor"
                ></bdt>
                and technical processes and procedures in place to protect your
                personal information. However, no electronic transmission over
                the internet or information storage technology can be guaranteed
                to be 100% secure, so we cannot promise or guarantee that
                hackers, cybercriminals, or other
                <bdt class="block-component"></bdt>unauthorized<bdt
                  class="statement-end-if-in-editor"
                ></bdt>
                third parties will not be able to defeat our security and
                improperly collect, access, steal, or modify your information.
                Learn more about&nbsp;</span
              ></span
            ><a data-custom-class="link" href="#infosafe"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >how we keep your information safe</span
                ></span
              ></a
            ><span data-custom-class="body_text">.</span
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><bdt class="statement-end-if-in-editor"></bdt></span
            ></span>
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong>What are your rights?</strong> Depending on where you
                are located geographically, the applicable privacy law may mean
                you have certain rights regarding your personal information.
                Learn more about&nbsp;</span
              ></span
            ><a data-custom-class="link" href="#privacyrights"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >your privacy rights</span
                ></span
              ></a
            ><span data-custom-class="body_text">.</span>
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong>How do you exercise your rights?</strong> The easiest
                way to exercise your rights is by
                <bdt class="block-component">submitting a&nbsp;</bdt></span
              ></span
            ><a
              data-custom-class="link"
              href="https://app.termly.io/notify/f1052077-6127-4964-b388-473a848eb791"
              rel="noopener noreferrer"
              target="_blank"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >data subject access request</span
                ></span
              ></a
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><bdt class="block-component"></bdt>, or by contacting us. We
                will consider and act upon any request in accordance with
                applicable data protection laws.</span
              ></span
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                >Want to learn more about what we do with any information we
                collect?&nbsp;</span
              ></span
            ><a data-custom-class="link" href="#toc"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                ><span data-custom-class="body_text"
                  >Review the Privacy Notice in full</span
                ></span
              ></a
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">.</span></span
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5"><br /></div>
          <div id="toc" style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span style="color: rgb(127, 127, 127)"
                ><span style="color: rgb(0, 0, 0)"
                  ><strong
                    ><span data-custom-class="heading_1"
                      >TABLE OF CONTENTS</span
                    ></strong
                  ></span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#infocollect"
                ><span style="color: rgb(0, 58, 250)"
                  >1. WHAT INFORMATION DO WE COLLECT?</span
                ></a
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#infouse"
                ><span style="color: rgb(0, 58, 250)"
                  >2. HOW DO WE PROCESS YOUR INFORMATION?<bdt
                    class="block-component"
                  ></bdt></span></a
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#legalbases"
                ><span style="color: rgb(0, 58, 250)"
                  >3.
                  <span style="font-size: 15px"
                    ><span style="color: rgb(0, 58, 250)"
                      >WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                      INFORMATION?</span
                    ></span
                  ><bdt class="statement-end-if-in-editor"></bdt></span></a
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span style="color: rgb(0, 58, 250)"
                ><a data-custom-class="link" href="#whoshare"
                  >4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                  INFORMATION?</a
                ></span
              ><span data-custom-class="body_text"
                ><bdt class="block-component"></bdt
                ><span style="color: rgb(127, 127, 127)"
                  ><span style="color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><span style="color: rgb(89, 89, 89)"
                        ><bdt
                          class="block-component"
                        ></bdt></span></span></span></span></span
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#cookies"
                ><span style="color: rgb(0, 58, 250)"
                  >5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span
                ></a
              ><span style="color: rgb(127, 127, 127)"
                ><span style="color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="color: rgb(89, 89, 89)"
                      ><bdt
                        class="statement-end-if-in-editor"
                      ></bdt></span></span></span></span
              ><bdt class="block-component"></bdt
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span style="color: rgb(127, 127, 127)"
                ><span style="color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="color: rgb(89, 89, 89)"
                      ><span style="color: rgb(89, 89, 89)"
                        ><span style="color: rgb(89, 89, 89)"
                          ><bdt
                            class="block-component"
                          ></bdt></span></span></span></span></span></span
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#sociallogins"
                ><span style="color: rgb(0, 58, 250)"
                  ><span style="color: rgb(0, 58, 250)"
                    ><span style="color: rgb(0, 58, 250)"
                      >6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span
                    ></span
                  ></span
                ></a
              ><span style="color: rgb(127, 127, 127)"
                ><span style="color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="color: rgb(89, 89, 89)"
                      ><span style="color: rgb(89, 89, 89)"
                        ><span style="color: rgb(89, 89, 89)"
                          ><bdt
                            class="statement-end-if-in-editor"
                          ></bdt></span></span
                      ><bdt
                        class="block-component"
                      ></bdt></span></span></span></span
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#inforetain"
                ><span style="color: rgb(0, 58, 250)"
                  >7. HOW LONG DO WE KEEP YOUR INFORMATION?</span
                ></a
              ><span style="color: rgb(127, 127, 127)"
                ><span style="color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="color: rgb(89, 89, 89)"
                      ><span style="color: rgb(89, 89, 89)"
                        ><bdt
                          class="block-component"
                        ></bdt></span></span></span></span></span
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#infosafe"
                ><span style="color: rgb(0, 58, 250)"
                  >8. HOW DO WE KEEP YOUR INFORMATION SAFE?</span
                ></a
              ><span style="color: rgb(127, 127, 127)"
                ><span style="color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="color: rgb(89, 89, 89)"
                      ><bdt class="statement-end-if-in-editor"></bdt
                      ><bdt
                        class="block-component"
                      ></bdt></span></span></span></span
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#infominors"
                ><span style="color: rgb(0, 58, 250)"
                  >9. DO WE COLLECT INFORMATION FROM MINORS?</span
                ></a
              ><span style="color: rgb(127, 127, 127)"
                ><span style="color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="color: rgb(89, 89, 89)"
                      ><bdt
                        class="statement-end-if-in-editor"
                      ></bdt></span></span></span></span
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span style="color: rgb(0, 58, 250)"
                ><a data-custom-class="link" href="#privacyrights"
                  >10. WHAT ARE YOUR PRIVACY RIGHTS?</a
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><a data-custom-class="link" href="#DNT"
                ><span style="color: rgb(0, 58, 250)"
                  >11. CONTROLS FOR DO-NOT-TRACK FEATURES<bdt
                    class="block-component"
                  ></bdt></span
                ><bdt class="block-component"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"></span></span></bdt></a
            ></span>
          </div>
          <div style="line-height: 1.5">
            <a data-custom-class="link" href="#DNT"
              ><bdt class="block-component"
                ><span style="font-size: 15px"></span></bdt
              ><bdt class="block-component"></bdt
              ><bdt class="block-component"></bdt
              ><bdt class="block-component"></bdt
              ><bdt class="block-component"></bdt
              ><bdt class="block-component"></bdt
              ><bdt class="block-component"></bdt
              ><bdt class="block-component"></bdt
              ><bdt class="block-component"></bdt
              ><bdt class="block-component"></bdt
            ></a>
          </div>
          <div style="line-height: 1.5">
            <a data-custom-class="link" href="#DNT"
              ><span style="font-size: 15px"></span></a
            ><a data-custom-class="link" href="#policyupdates"
              ><span style="color: rgb(0, 58, 250)"
                >12. DO WE MAKE UPDATES TO THIS NOTICE?</span
              ></a
            >
          </div>
          <div style="line-height: 1.5">
            <a data-custom-class="link" href="#contact"
              ><span style="color: rgb(0, 58, 250); font-size: 15px"
                >13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span
              ></a
            >
          </div>
          <div style="line-height: 1.5">
            <a data-custom-class="link" href="#request"
              ><span style="color: rgb(0, 58, 250)"
                >14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?</span
              ></a
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5"><br /></div>
          <div id="infocollect" style="line-height: 1.5">
            <span style="color: rgb(0, 0, 0)"
              ><span style="color: rgb(0, 0, 0); font-size: 15px"
                ><span style="font-size: 15px; color: rgb(0, 0, 0)"
                  ><span style="font-size: 15px; color: rgb(0, 0, 0)"
                    ><span id="control" style="color: rgb(0, 0, 0)"
                      ><strong
                        ><span data-custom-class="heading_1"
                          >1. WHAT INFORMATION DO WE COLLECT?</span
                        ></strong
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div id="personalinfo" style="line-height: 1.5">
            <span data-custom-class="heading_2" style="color: rgb(0, 0, 0)"
              ><span style="font-size: 15px"
                ><strong>Personal information you disclose to us</strong></span
              ></span
            >
          </div>
          <div>
            <div><br /></div>
            <div style="line-height: 1.5">
              <span style="color: rgb(127, 127, 127)"
                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><strong><em>In Short:</em></strong></span
                        ></span
                      ></span
                    ></span
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><strong><em>&nbsp;</em></strong
                          ><em
                            >We collect personal information that you provide to
                            us.</em
                          ></span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </div>
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  >We collect personal information that you voluntarily provide
                  to us when you
                  <span style="font-size: 15px"
                    ><bdt class="block-component"></bdt></span
                  >register on the Services,&nbsp;</span
                ><span style="font-size: 15px"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><bdt
                        class="statement-end-if-in-editor"
                      ></bdt></span></span
                  ><span data-custom-class="body_text"
                    >express an interest in obtaining information about us or
                    our products and Services, when you participate in
                    activities on the Services, or otherwise when you contact
                    us.</span
                  ></span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><bdt class="block-component"></bdt></span></span></span
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><strong>Personal Information Provided by You.</strong> The
                  personal information that we collect depends on the context of
                  your interactions with us and the Services, the choices you
                  make, and the products and features you use. The personal
                  information we collect may include the following:<span
                    style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      ><bdt
                        class="forloop-component"
                      ></bdt></span></span></span></span
            ></span>
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        ><bdt class="question">names</bdt></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      ><bdt
                        class="forloop-component"
                      ></bdt></span></span></span></span
            ></span>
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        ><bdt class="question">phone numbers</bdt></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      ><bdt
                        class="forloop-component"
                      ></bdt></span></span></span></span
            ></span>
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        ><bdt class="question">email addresses</bdt></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      ><bdt
                        class="forloop-component"
                      ></bdt></span></span></span></span
            ></span>
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        ><bdt class="question">mailing addresses</bdt></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      ><bdt
                        class="forloop-component"
                      ></bdt></span></span></span></span
            ></span>
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        ><bdt class="question">usernames</bdt></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      ><bdt
                        class="forloop-component"
                      ></bdt></span></span></span></span
            ></span>
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span data-custom-class="body_text"
                        ><bdt class="question">passwords</bdt></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      ><bdt class="forloop-component"></bdt></span
                    ><span data-custom-class="body_text"
                      ><bdt
                        class="statement-end-if-in-editor"
                      ></bdt></span></span></span></span
            ></span>
          </div>
          <div id="sensitiveinfo" style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong>Sensitive Information.</strong>
                <bdt class="block-component"></bdt>We do not process sensitive
                information.</span
              ></span
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><bdt class="else-block"></bdt></span></span
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      ><bdt
                        class="block-component"
                      ></bdt></span></span></span></span
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><strong>Payment Data.</strong> We may collect data necessary
                  to process your payment if you choose to make purchases, such
                  as your payment instrument number, and the security code
                  associated with your payment instrument. All payment data is
                  handled and stored by<bdt class="forloop-component"></bdt
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><span style="font-size: 15px"
                          ><span data-custom-class="body_text"
                            ><bdt
                              class="block-component"
                            ></bdt></span></span></span></span
                  ></span>
                  <bdt class="question">Viva Wallet</bdt
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                            ><span data-custom-class="body_text"
                              ><span style="font-size: 15px"
                                ><span data-custom-class="body_text"
                                  ><bdt
                                    class="block-component"
                                  ></bdt></span></span></span
                            ><span data-custom-class="body_text"
                              ><span style="font-size: 15px"
                                ><span style="color: rgb(89, 89, 89)"
                                  ><span data-custom-class="body_text"
                                    ><span style="font-size: 15px"
                                      ><span style="color: rgb(89, 89, 89)"
                                        ><span data-custom-class="body_text"
                                          ><span style="color: rgb(89, 89, 89)"
                                            ><span style="font-size: 15px"
                                              ><span
                                                data-custom-class="body_text"
                                                ><bdt
                                                  class="forloop-component"
                                                ></bdt></span></span></span></span></span></span></span></span></span></span></span></span
                        >. You may find their privacy notice link(s) here:<span
                          style="font-size: 15px; color: rgb(89, 89, 89)"
                          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                            ><span data-custom-class="body_text"
                              ><bdt class="forloop-component"></bdt
                              ><span
                                style="font-size: 15px; color: rgb(89, 89, 89)"
                                ><span
                                  style="
                                    font-size: 15px;
                                    color: rgb(89, 89, 89);
                                  "
                                  ><span data-custom-class="body_text"
                                    ><span style="font-size: 15px"
                                      ><span data-custom-class="body_text"
                                        ><bdt
                                          class="block-component"
                                        ></bdt></span></span></span></span></span></span></span
                        ></span>
                        <span style="color: rgb(0, 58, 250)"
                          ><bdt class="question"
                            ><a
                              href="https://www.viva.com/en-eu/privacy-notice"
                              target="_blank"
                              data-custom-class="link"
                              >https://www.viva.com/en-eu/privacy-notice</a
                            ></bdt
                          ></span
                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                            ><span data-custom-class="body_text"
                              ><span
                                style="font-size: 15px; color: rgb(89, 89, 89)"
                                ><span
                                  style="
                                    font-size: 15px;
                                    color: rgb(89, 89, 89);
                                  "
                                  ><span data-custom-class="body_text"
                                    ><span
                                      style="
                                        font-size: 15px;
                                        color: rgb(89, 89, 89);
                                      "
                                      ><span
                                        style="
                                          font-size: 15px;
                                          color: rgb(89, 89, 89);
                                        "
                                        ><span data-custom-class="body_text"
                                          ><span style="font-size: 15px"
                                            ><span data-custom-class="body_text"
                                              ><bdt
                                                class="block-component"
                                              ></bdt></span></span></span></span></span></span></span></span
                              ><bdt class="forloop-component"></bdt
                              ><span style="font-size: 15px"
                                ><span data-custom-class="body_text"
                                  >.<bdt
                                    class="block-component"
                                  ></bdt></span></span></span></span></span></span></span></span></span></span
            ></span>
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      ><bdt class="statement-end-if-in-editor"
                        ><bdt
                          class="block-component"
                        ></bdt></bdt></span></span></span></span
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><strong>Social Media Login Data.&nbsp;</strong>We may provide
                  you with the option to register with us using your existing
                  social media account details, like your Facebook, X, or other
                  social media account. If you choose to register in this way,
                  we will collect certain profile information about you from the
                  social media provider, as described in the section called
                  <bdt class="block-component"></bdt>"<bdt
                    class="statement-end-if-in-editor"
                  ></bdt
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      ><span style="font-size: 15px"
                        ><span style="color: rgb(0, 58, 250)"
                          ><a data-custom-class="link" href="#sociallogins"
                            >HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a
                          ></span
                        ></span
                      ></span
                    ></span
                  ><bdt class="block-component"></bdt>"<bdt
                    class="statement-end-if-in-editor"
                  ></bdt>
                  below.</span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><bdt class="statement-end-if-in-editor"
                      ><bdt
                        class="statement-end-if-in-editor"
                      ></bdt></bdt></span></span></span></span
            ><bdt class="block-component"></bdt>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  >All personal information that you provide to us must be true,
                  complete, and accurate, and you must notify us of any changes
                  to such personal information.</span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt></span></span
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span data-custom-class="heading_2" style="color: rgb(0, 0, 0)"
              ><span style="font-size: 15px"
                ><strong>Information automatically collected</strong></span
              ></span
            >
          </div>
          <div>
            <div><br /></div>
            <div style="line-height: 1.5">
              <span style="color: rgb(127, 127, 127)"
                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><strong><em>In Short:</em></strong></span
                        ></span
                      ></span
                    ></span
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><strong><em>&nbsp;</em></strong
                          ><em
                            >Some information — such as your Internet Protocol
                            (IP) address and/or browser and device
                            characteristics — is collected automatically when
                            you visit our Services.</em
                          ></span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </div>
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  >We automatically collect certain information when you visit,
                  use, or navigate the Services. This information does not
                  reveal your specific identity (like your name or contact
                  information) but may include device and usage information,
                  such as your IP address, browser and device characteristics,
                  operating system, language preferences, referring URLs, device
                  name, country, location, information about how and when you
                  use our Services, and other technical information. This
                  information is primarily needed to maintain the security and
                  operation of our Services, and for our internal analytics and
                  reporting purposes.</span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt></span></span
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  >Like many businesses, we also collect information through
                  cookies and similar technologies.
                  <bdt class="block-component"></bdt
                  ><bdt class="block-component"></bdt></span></span
            ></span>
          </div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><bdt class="statement-end-if-in-editor"
                    ><span data-custom-class="body_text"></span></bdt></span
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt></span></span
            ></span>
          </div>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  >The information we collect includes:<bdt
                    class="block-component"
                  ></bdt></span></span
            ></span>
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><em>Log and Usage Data.</em> Log and usage data is
                    service-related, diagnostic, usage, and performance
                    information our servers automatically collect when you
                    access or use our Services and which we record in log files.
                    Depending on how you interact with us, this log data may
                    include your IP address, device information, browser type,
                    and settings and information about your activity in the
                    Services<span style="font-size: 15px">&nbsp;</span>(such as
                    the date/time stamps associated with your usage, pages and
                    files viewed, searches, and other actions you take such as
                    which features you use), device event information (such as
                    system activity, error reports (sometimes called
                    <bdt class="block-component"></bdt>"crash dumps"<bdt
                      class="statement-end-if-in-editor"
                    ></bdt
                    >), and hardware settings).<span style="font-size: 15px"
                      ><span style="color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><span style="font-size: 15px"
                            ><span style="color: rgb(89, 89, 89)"
                              ><bdt
                                class="statement-end-if-in-editor"
                              ></bdt></span></span></span></span></span></span></span
              ></span>
            </li>
          </ul>
          <div style="line-height: 1.5">
            <bdt class="block-component"
              ><span style="font-size: 15px"
                ><span data-custom-class="body_text"></span></span
            ></bdt>
            <div style="line-height: 1.5">
              <bdt class="block-component"
                ><span style="font-size: 15px"
                  ><span data-custom-class="body_text"></span></span
              ></bdt>
            </div>
            <ul>
              <li data-custom-class="body_text" style="line-height: 1.5">
                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><em>Location Data.</em> We collect location data such as
                      information about your device's location, which can be
                      either precise or imprecise. How much information we
                      collect depends on the type and settings of the device you
                      use to access the Services. For example, we may use GPS
                      and other technologies to collect geolocation data that
                      tells us your current location (based on your IP address).
                      You can opt out of allowing us to collect this information
                      either by refusing access to the information or by
                      disabling your Location setting on your device. However,
                      if you choose to opt out, you may not be able to use
                      certain aspects of the Services.<span
                        style="font-size: 15px"
                        ><span style="color: rgb(89, 89, 89)"
                          ><span data-custom-class="body_text"
                            ><span style="font-size: 15px"
                              ><span style="color: rgb(89, 89, 89)"
                                ><bdt
                                  class="statement-end-if-in-editor"
                                ></bdt></span></span></span></span></span></span></span
                ></span>
              </li>
            </ul>
            <div>
              <bdt class="block-component"
                ><span style="font-size: 15px"></span></bdt
              ><bdt class="statement-end-if-in-editor"></bdt
              ><bdt class="block-component"
                ><span style="font-size: 15px"></span
              ></bdt>
            </div>
            <div style="line-height: 1.5">
              <span style="font-size: 15px"
                ><strong
                  ><span data-custom-class="heading_2">Google API</span></strong
                ></span
              >
            </div>
            <div style="line-height: 1.5">
              <span style="font-size: 15px"><br /></span>
            </div>
            <div style="line-height: 1.5">
              <span style="font-size: 15px"
                ><span data-custom-class="body_text"
                  >Our use of information received from Google APIs will adhere
                  to&nbsp;</span
                ></span
              ><a
                data-custom-class="link"
                href="https://developers.google.com/terms/api-services-user-data-policy"
                rel="noopener noreferrer"
                target="_blank"
                ><span style="color: rgb(0, 58, 250); font-size: 15px"
                  ><span data-custom-class="body_text"
                    >Google API Services User Data Policy</span
                  ></span
                ></a
              ><span style="font-size: 15px"
                ><span data-custom-class="body_text"
                  >, including the&nbsp;</span
                ></span
              ><a
                data-custom-class="link"
                href="https://developers.google.com/terms/api-services-user-data-policy#limited-use"
                rel="noopener noreferrer"
                target="_blank"
                ><span style="color: rgb(0, 58, 250); font-size: 15px"
                  ><span data-custom-class="body_text"
                    >Limited Use requirements</span
                  ></span
                ></a
              ><span style="font-size: 15px"
                ><span data-custom-class="body_text">.</span><br
              /></span>
            </div>
            <div>
              <span style="font-size: 15px"><br /></span>
            </div>
            <div style="line-height: 1.5">
              <bdt class="statement-end-if-in-editor"
                ><span style="font-size: 15px"></span></bdt
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><span style="color: rgb(89, 89, 89); font-size: 15px"
                      ><span data-custom-class="body_text"
                        ><span style="color: rgb(89, 89, 89); font-size: 15px"
                          ><span data-custom-class="body_text"
                            ><bdt class="statement-end-if-in-editor"
                              ><bdt
                                class="block-component"
                              ></bdt></bdt></span></span></span></span></span></span></span
              ><span style="font-size: 15px"
                ><span data-custom-class="body_text"
                  ><bdt class="block-component"></bdt></span
              ></span>
            </div>
            <div style="line-height: 1.5"><br /></div>
            <div id="infouse" style="line-height: 1.5">
              <span style="color: rgb(127, 127, 127)"
                ><span style="color: rgb(89, 89, 89); font-size: 15px"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span id="control" style="color: rgb(0, 0, 0)"
                        ><strong
                          ><span data-custom-class="heading_1"
                            >2. HOW DO WE PROCESS YOUR INFORMATION?</span
                          ></strong
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </div>
            <div>
              <div style="line-height: 1.5"><br /></div>
              <div style="line-height: 1.5">
                <span style="color: rgb(127, 127, 127)"
                  ><span style="color: rgb(89, 89, 89); font-size: 15px"
                    ><span data-custom-class="body_text"
                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                          ><span data-custom-class="body_text"
                            ><strong><em>In Short:&nbsp;</em></strong
                            ><em
                              >We process your information to provide, improve,
                              and administer our Services, communicate with you,
                              for security and fraud prevention, and to comply
                              with law. We may also process your information for
                              other purposes with your consent.</em
                            ></span
                          ></span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
            </div>
            <div style="line-height: 1.5"><br /></div>
            <div style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><strong
                      >We process your personal information for a variety of
                      reasons, depending on how you interact with our Services,
                      including:</strong
                    ><bdt class="block-component"></bdt></span></span
              ></span>
            </div>
            <ul>
              <li data-custom-class="body_text" style="line-height: 1.5">
                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><strong
                        >To facilitate account creation and authentication and
                        otherwise manage user accounts.&nbsp;</strong
                      >We may process your information so you can create and log
                      in to your account, as well as keep your account in
                      working order.<span
                        style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                          ><span data-custom-class="body_text"
                            ><span style="font-size: 15px"
                              ><span style="color: rgb(89, 89, 89)"
                                ><span data-custom-class="body_text"
                                  ><span style="font-size: 15px"
                                    ><span style="color: rgb(89, 89, 89)"
                                      ><span data-custom-class="body_text"
                                        ><bdt
                                          class="statement-end-if-in-editor"
                                        ></bdt></span></span></span></span></span></span></span></span></span></span></span
                ></span>
              </li>
            </ul>
            <div style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><bdt class="block-component"></bdt></span></span
              ></span>
              <div style="line-height: 1.5">
                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><bdt class="block-component"></bdt></span></span
                ></span>
              </div>
              <ul>
                <li data-custom-class="body_text" style="line-height: 1.5">
                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><strong
                          >To deliver and facilitate delivery of services to the
                          user.&nbsp;</strong
                        >We may process your information to provide you with the
                        requested service.<span style="font-size: 15px"
                          ><span style="color: rgb(89, 89, 89)"
                            ><span data-custom-class="body_text"
                              ><span
                                style="font-size: 15px; color: rgb(89, 89, 89)"
                                ><span
                                  style="
                                    font-size: 15px;
                                    color: rgb(89, 89, 89);
                                  "
                                  ><span data-custom-class="body_text"
                                    ><span
                                      style="
                                        font-size: 15px;
                                        color: rgb(89, 89, 89);
                                      "
                                      ><span
                                        style="
                                          font-size: 15px;
                                          color: rgb(89, 89, 89);
                                        "
                                        ><span data-custom-class="body_text"
                                          ><span
                                            style="
                                              font-size: 15px;
                                              color: rgb(89, 89, 89);
                                            "
                                            ><span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  ><span
                                                    style="
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      ><span
                                                        style="font-size: 15px"
                                                        ><span
                                                          style="
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            data-custom-class="body_text"
                                                            ><bdt
                                                              class="statement-end-if-in-editor"
                                                            ></bdt></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span
                  ></span>
                </li>
              </ul>
              <div style="line-height: 1.5">
                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><bdt class="block-component"></bdt></span></span
                ></span>
                <div style="line-height: 1.5">
                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><bdt class="block-component"></bdt></span></span
                  ></span>
                </div>
                <ul>
                  <li data-custom-class="body_text" style="line-height: 1.5">
                    <span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span data-custom-class="body_text"
                          ><strong
                            >To respond to user inquiries/offer support to
                            users.&nbsp;</strong
                          >We may process your information to respond to your
                          inquiries and solve any potential issues you might
                          have with the requested service.<bdt
                            class="statement-end-if-in-editor"
                          ></bdt></span></span
                    ></span>
                  </li>
                </ul>
                <div style="line-height: 1.5">
                  <bdt class="block-component"
                    ><span style="font-size: 15px"></span
                  ></bdt>
                  <div style="line-height: 1.5">
                    <bdt class="block-component"
                      ><span style="font-size: 15px"></span
                    ></bdt>
                  </div>
                  <ul>
                    <li data-custom-class="body_text" style="line-height: 1.5">
                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                          ><span data-custom-class="body_text"
                            ><strong
                              >To send administrative information to
                              you.&nbsp;</strong
                            >We may process your information to send you details
                            about our products and services, changes to our
                            terms and policies, and other similar
                            information.<span
                              style="font-size: 15px; color: rgb(89, 89, 89)"
                              ><span
                                style="font-size: 15px; color: rgb(89, 89, 89)"
                                ><span data-custom-class="body_text"
                                  ><span
                                    style="
                                      font-size: 15px;
                                      color: rgb(89, 89, 89);
                                    "
                                    ><span
                                      style="
                                        font-size: 15px;
                                        color: rgb(89, 89, 89);
                                      "
                                      ><span data-custom-class="body_text"
                                        ><bdt
                                          class="statement-end-if-in-editor"
                                        ></bdt></span></span></span></span></span></span></span></span
                      ></span>
                    </li>
                  </ul>
                  <div style="line-height: 1.5">
                    <bdt class="block-component"
                      ><span style="font-size: 15px"></span
                    ></bdt>
                    <div style="line-height: 1.5">
                      <bdt class="block-component"
                        ><span style="font-size: 15px"
                          ><span data-custom-class="body_text"></span></span
                      ></bdt>
                    </div>
                    <ul>
                      <li
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span data-custom-class="body_text"
                          ><span style="font-size: 15px"
                            ><strong
                              >To <bdt class="block-component"></bdt>fulfill<bdt
                                class="statement-end-if-in-editor"
                              ></bdt>
                              and manage your orders.</strong
                            >
                            We may process your information to
                            <bdt class="block-component"></bdt>fulfill<bdt
                              class="statement-end-if-in-editor"
                            ></bdt>
                            and manage your orders, payments, returns, and
                            exchanges made through the Services.</span
                          ></span
                        ><bdt class="statement-end-if-in-editor"
                          ><span style="font-size: 15px"
                            ><span data-custom-class="body_text"></span></span
                        ></bdt>
                      </li>
                    </ul>
                    <p style="font-size: 15px; line-height: 1.5">
                      <bdt class="block-component"
                        ><span style="font-size: 15px"></span
                      ></bdt>
                    </p>
                    <p style="font-size: 15px; line-height: 1.5">
                      <bdt class="block-component"
                        ><span style="font-size: 15px"></span
                      ></bdt>
                    </p>
                    <ul>
                      <li
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span style="font-size: 15px"
                          ><span style="color: rgb(89, 89, 89)"
                            ><span style="color: rgb(89, 89, 89)"
                              ><span data-custom-class="body_text"
                                ><strong
                                  >To enable user-to-user
                                  communications.&nbsp;</strong
                                >We may process your information if you choose
                                to use any of our offerings that allow for
                                communication with another user.<span
                                  style="color: rgb(89, 89, 89)"
                                  ><span style="color: rgb(89, 89, 89)"
                                    ><span data-custom-class="body_text"
                                      ><span style="color: rgb(89, 89, 89)"
                                        ><span data-custom-class="body_text"
                                          ><span style="color: rgb(89, 89, 89)"
                                            ><span data-custom-class="body_text"
                                              ><bdt
                                                class="statement-end-if-in-editor"
                                              ></bdt></span></span></span></span></span></span></span></span></span></span
                        ></span>
                      </li>
                    </ul>
                    <p style="font-size: 15px; line-height: 1.5">
                      <bdt class="block-component"></bdt>
                    </p>
                    <p style="font-size: 15px; line-height: 1.5">
                      <bdt class="block-component"></bdt>
                    </p>
                    <ul>
                      <li
                        data-custom-class="body_text"
                        style="line-height: 1.5"
                      >
                        <span style="font-size: 15px"
                          ><span style="color: rgb(89, 89, 89)"
                            ><span style="color: rgb(89, 89, 89)"
                              ><span data-custom-class="body_text"
                                ><strong>To request feedback.&nbsp;</strong>We
                                may process your information when necessary to
                                request feedback and to contact you about your
                                use of our Services.<span
                                  style="color: rgb(89, 89, 89)"
                                  ><span style="color: rgb(89, 89, 89)"
                                    ><span data-custom-class="body_text"
                                      ><span style="color: rgb(89, 89, 89)"
                                        ><span data-custom-class="body_text"
                                          ><span style="color: rgb(89, 89, 89)"
                                            ><span data-custom-class="body_text"
                                              ><bdt
                                                class="statement-end-if-in-editor"
                                              ></bdt></span></span></span></span></span></span></span></span></span></span
                        ></span>
                      </li>
                    </ul>
                    <div style="line-height: 1.5">
                      <bdt class="block-component"
                        ><span style="font-size: 15px"
                          ><span data-custom-class="body_text"></span></span
                      ></bdt>
                      <div style="line-height: 1.5">
                        <bdt class="block-component"
                          ><span style="font-size: 15px"></span
                        ></bdt>
                        <div style="line-height: 1.5">
                          <bdt class="block-component"
                            ><span style="font-size: 15px"></span
                          ></bdt>
                          <div style="line-height: 1.5">
                            <span style="font-size: 15px"
                              ><bdt class="block-component"
                                ><span
                                  data-custom-class="body_text"
                                ></span></bdt
                            ></span>
                            <div style="line-height: 1.5">
                              <bdt class="block-component"
                                ><span style="font-size: 15px"
                                  ><span
                                    data-custom-class="body_text"
                                  ></span></span
                              ></bdt>
                              <div style="line-height: 1.5">
                                <bdt class="block-component"
                                  ><span style="font-size: 15px"
                                    ><span
                                      data-custom-class="body_text"
                                    ></span></span
                                ></bdt>
                                <div style="line-height: 1.5">
                                  <bdt class="block-component"
                                    ><span style="font-size: 15px"
                                      ><span
                                        data-custom-class="body_text"
                                      ></span></span
                                  ></bdt>
                                </div>
                                <ul>
                                  <li
                                    data-custom-class="body_text"
                                    style="line-height: 1.5"
                                  >
                                    <span data-custom-class="body_text"
                                      ><span style="font-size: 15px"
                                        ><strong
                                          >To protect our Services.</strong
                                        >
                                        We may process your information as part
                                        of our efforts to keep our Services safe
                                        and secure, including fraud monitoring
                                        and prevention.</span
                                      ></span
                                    ><bdt class="statement-end-if-in-editor"
                                      ><span style="font-size: 15px"
                                        ><span
                                          data-custom-class="body_text"
                                        ></span></span
                                    ></bdt>
                                  </li>
                                </ul>
                                <div style="line-height: 1.5">
                                  <bdt class="block-component"
                                    ><span style="font-size: 15px"
                                      ><span
                                        data-custom-class="body_text"
                                      ></span></span
                                  ></bdt>
                                  <div style="line-height: 1.5">
                                    <bdt class="block-component"
                                      ><span style="font-size: 15px"
                                        ><span
                                          data-custom-class="body_text"
                                        ></span></span
                                    ></bdt>
                                    <div style="line-height: 1.5">
                                      <bdt class="block-component"
                                        ><span style="font-size: 15px"
                                          ><span
                                            data-custom-class="body_text"
                                          ></span></span
                                      ></bdt>
                                      <div style="line-height: 1.5">
                                        <bdt class="block-component"
                                          ><span style="font-size: 15px"
                                            ><span
                                              data-custom-class="body_text"
                                            ></span></span
                                        ></bdt>
                                      </div>
                                      <ul>
                                        <li
                                          data-custom-class="body_text"
                                          style="line-height: 1.5"
                                        >
                                          <span data-custom-class="body_text"
                                            ><span style="font-size: 15px"
                                              ><strong
                                                >To identify usage
                                                trends.</strong
                                              >
                                              We may process information about
                                              how you use our Services to better
                                              understand how they are being used
                                              so we can improve them.</span
                                            ></span
                                          ><bdt
                                            class="statement-end-if-in-editor"
                                            ><span style="font-size: 15px"
                                              ><span
                                                data-custom-class="body_text"
                                              ></span></span
                                          ></bdt>
                                        </li>
                                      </ul>
                                      <div style="line-height: 1.5">
                                        <bdt class="block-component"
                                          ><span style="font-size: 15px"
                                            ><span
                                              data-custom-class="body_text"
                                            ></span></span
                                        ></bdt>
                                        <div style="line-height: 1.5">
                                          <bdt class="block-component"
                                            ><span style="font-size: 15px"
                                              ><span
                                                data-custom-class="body_text"
                                              ></span></span
                                          ></bdt>
                                          <div style="line-height: 1.5">
                                            <bdt class="block-component"
                                              ><span style="font-size: 15px"
                                                ><span
                                                  data-custom-class="body_text"
                                                ></span></span
                                            ></bdt>
                                            <div style="line-height: 1.5">
                                              <bdt class="block-component"
                                                ><span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                  ></span></span
                                              ></bdt>
                                            </div>
                                            <ul>
                                              <li
                                                data-custom-class="body_text"
                                                style="line-height: 1.5"
                                              >
                                                <span
                                                  data-custom-class="body_text"
                                                  ><span style="font-size: 15px"
                                                    ><strong
                                                      >To save or protect an
                                                      individual's vital
                                                      interest.</strong
                                                    >
                                                    We may process your
                                                    information when necessary
                                                    to save or protect an
                                                    individual’s vital interest,
                                                    such as to prevent
                                                    harm.</span
                                                  ></span
                                                ><bdt
                                                  class="statement-end-if-in-editor"
                                                  ><span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                    ></span></span
                                                ></bdt>
                                              </li>
                                            </ul>
                                            <div style="line-height: 1.5">
                                              <bdt class="block-component"
                                                ><span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                  ></span></span></bdt
                                              ><bdt class="block-component"
                                                ><span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                  ></span></span></bdt
                                              ><bdt class="block-component"
                                                ><span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                  ></span></span></bdt
                                              ><bdt class="block-component"
                                                ><span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                  ></span></span
                                              ></bdt>
                                            </div>
                                            <div style="line-height: 1.5">
                                              <br />
                                            </div>
                                            <div
                                              id="legalbases"
                                              style="line-height: 1.5"
                                            >
                                              <strong
                                                ><span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="heading_1"
                                                    >3. WHAT LEGAL BASES DO WE
                                                    RELY ON TO PROCESS YOUR
                                                    INFORMATION?</span
                                                  ></span
                                                ></strong
                                              >
                                            </div>
                                            <div style="line-height: 1.5">
                                              <br />
                                            </div>
                                            <div style="line-height: 1.5">
                                              <em
                                                ><span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    ><strong
                                                      >In Short:&nbsp;</strong
                                                    >We only process your
                                                    personal information when we
                                                    believe it is necessary and
                                                    we have a valid legal reason
                                                    (i.e.<bdt
                                                      class="block-component"
                                                    ></bdt
                                                    >,<bdt
                                                      class="statement-end-if-in-editor"
                                                    ></bdt>
                                                    legal basis) to do so under
                                                    applicable law, like with
                                                    your consent, to comply with
                                                    laws, to provide you with
                                                    services to enter into or
                                                    <bdt
                                                      class="block-component"
                                                    ></bdt
                                                    >fulfill<bdt
                                                      class="statement-end-if-in-editor"
                                                    ></bdt>
                                                    our contractual obligations,
                                                    to protect your rights, or
                                                    to
                                                    <bdt
                                                      class="block-component"
                                                    ></bdt
                                                    >fulfill<bdt
                                                      class="statement-end-if-in-editor"
                                                    ></bdt>
                                                    our legitimate business
                                                    interests.</span
                                                  ></span
                                                ></em
                                              >
                                            </div>
                                            <div style="line-height: 1.5">
                                              <br />
                                            </div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px"
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><bdt
                                                    class="block-component"
                                                  ></bdt></span
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><bdt
                                                    class="block-component"
                                                  ></bdt></span
                                              ></span>
                                            </div>
                                            <div style="line-height: 1.5">
                                              <span style="font-size: 15px"
                                                ><span
                                                  data-custom-class="body_text"
                                                  >The General Data Protection
                                                  Regulation (GDPR) and UK GDPR
                                                  require us to explain the
                                                  valid legal bases we rely on
                                                  in order to process your
                                                  personal information. As such,
                                                  we may rely on the following
                                                  legal bases to process your
                                                  personal information:</span
                                                ></span
                                              >
                                            </div>
                                            <ul>
                                              <li
                                                data-custom-class="body_text"
                                                style="line-height: 1.5"
                                              >
                                                <span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    ><strong
                                                      >Consent.&nbsp;</strong
                                                    >We may process your
                                                    information if you have
                                                    given us permission
                                                    (i.e.<bdt
                                                      class="block-component"
                                                    ></bdt
                                                    >,<bdt
                                                      class="statement-end-if-in-editor"
                                                    ></bdt>
                                                    consent) to use your
                                                    personal information for a
                                                    specific purpose. You can
                                                    withdraw your consent at any
                                                    time. Learn more
                                                    about&nbsp;</span
                                                  ></span
                                                ><a
                                                  data-custom-class="link"
                                                  href="#withdrawconsent"
                                                  ><span
                                                    style="
                                                      color: rgb(0, 58, 250);
                                                      font-size: 15px;
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >withdrawing your
                                                      consent</span
                                                    ></span
                                                  ></a
                                                ><span
                                                  data-custom-class="body_text"
                                                  >.</span
                                                >
                                              </li>
                                            </ul>
                                            <div style="line-height: 1.5">
                                              <bdt class="block-component"
                                                ><span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                  ></span></span
                                              ></bdt>
                                            </div>
                                            <ul>
                                              <li
                                                data-custom-class="body_text"
                                                style="line-height: 1.5"
                                              >
                                                <span
                                                  data-custom-class="body_text"
                                                  ><span style="font-size: 15px"
                                                    ><strong
                                                      >Performance of a
                                                      Contract.</strong
                                                    >
                                                    We may process your personal
                                                    information when we believe
                                                    it is necessary to
                                                    <bdt
                                                      class="block-component"
                                                    ></bdt
                                                    >fulfill<bdt
                                                      class="statement-end-if-in-editor"
                                                    ></bdt>
                                                    our contractual obligations
                                                    to you, including providing
                                                    our Services or at your
                                                    request prior to entering
                                                    into a contract with
                                                    you.</span
                                                  ></span
                                                ><bdt
                                                  class="statement-end-if-in-editor"
                                                  ><span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                    ></span></span
                                                ></bdt>
                                              </li>
                                            </ul>
                                            <div style="line-height: 1.5">
                                              <bdt class="block-component"
                                                ><span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                  ></span></span
                                              ></bdt>
                                            </div>
                                            <ul>
                                              <li
                                                data-custom-class="body_text"
                                                style="line-height: 1.5"
                                              >
                                                <span
                                                  data-custom-class="body_text"
                                                  ><span style="font-size: 15px"
                                                    ><strong
                                                      >Legitimate
                                                      Interests.</strong
                                                    >
                                                    We may process your
                                                    information when we believe
                                                    it is reasonably necessary
                                                    to achieve our legitimate
                                                    business interests and those
                                                    interests do not outweigh
                                                    your interests and
                                                    fundamental rights and
                                                    freedoms. For example, we
                                                    may process your personal
                                                    information for some of the
                                                    purposes described in order
                                                    to:</span
                                                  ></span
                                                >
                                              </li>
                                            </ul>
                                            <div style="line-height: 1.5">
                                              <span
                                                data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  ><bdt
                                                    class="block-component"
                                                  ></bdt></span
                                              ></span>
                                              <div style="line-height: 1.5">
                                                <span
                                                  data-custom-class="body_text"
                                                  ><span style="font-size: 15px"
                                                    ><bdt
                                                      class="block-component"
                                                    ></bdt></span
                                                ></span>
                                                <div style="line-height: 1.5">
                                                  <span
                                                    data-custom-class="body_text"
                                                    ><span
                                                      style="font-size: 15px"
                                                      ><bdt
                                                        class="block-component"
                                                      ></bdt></span
                                                  ></span>
                                                </div>
                                                <ul style="margin-left: 40px">
                                                  <li
                                                    data-custom-class="body_text"
                                                    style="line-height: 1.5"
                                                  >
                                                    <span
                                                      data-custom-class="body_text"
                                                      ><span
                                                        style="font-size: 15px"
                                                        ><bdt
                                                          class="block-component"
                                                        ></bdt
                                                        >Analyze<bdt
                                                          class="statement-end-if-in-editor"
                                                        ></bdt>
                                                        how our Services are
                                                        used so we can improve
                                                        them to engage and
                                                        retain users<bdt
                                                          class="statement-end-if-in-editor"
                                                        ></bdt></span
                                                    ></span>
                                                  </li>
                                                </ul>
                                                <div style="line-height: 1.5">
                                                  <span
                                                    data-custom-class="body_text"
                                                    ><span
                                                      style="font-size: 15px"
                                                      ><bdt
                                                        class="block-component"
                                                      ></bdt></span
                                                  ></span>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      data-custom-class="body_text"
                                                      ><span
                                                        style="font-size: 15px"
                                                        ><bdt
                                                          class="block-component"
                                                        ></bdt></span
                                                    ></span>
                                                  </div>
                                                  <ul style="margin-left: 40px">
                                                    <li
                                                      data-custom-class="body_text"
                                                      style="line-height: 1.5"
                                                    >
                                                      <span
                                                        data-custom-class="body_text"
                                                        ><span
                                                          style="
                                                            font-size: 15px;
                                                          "
                                                          >Diagnose problems
                                                          and/or prevent
                                                          fraudulent
                                                          activities<bdt
                                                            class="statement-end-if-in-editor"
                                                          ></bdt></span
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      data-custom-class="body_text"
                                                      ><span
                                                        style="font-size: 15px"
                                                        ><bdt
                                                          class="block-component"
                                                        ></bdt></span
                                                    ></span>
                                                  </div>
                                                  <ul style="margin-left: 40px">
                                                    <li
                                                      data-custom-class="body_text"
                                                      style="line-height: 1.5"
                                                    >
                                                      <span
                                                        data-custom-class="body_text"
                                                        ><span
                                                          style="
                                                            font-size: 15px;
                                                          "
                                                          >Understand how our
                                                          users use our products
                                                          and services so we can
                                                          improve user
                                                          experience<bdt
                                                            class="statement-end-if-in-editor"
                                                          ></bdt></span
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      data-custom-class="body_text"
                                                      ><span
                                                        style="font-size: 15px"
                                                        ><bdt
                                                          class="block-component"
                                                        ></bdt></span></span
                                                    ><bdt
                                                      class="statement-end-if-in-editor"
                                                      ><span
                                                        style="font-size: 15px"
                                                        ><span
                                                          data-custom-class="body_text"
                                                        ></span></span></bdt
                                                    ><bdt
                                                      class="block-component"
                                                      ><span
                                                        style="font-size: 15px"
                                                        ><span
                                                          data-custom-class="body_text"
                                                        ></span></span
                                                    ></bdt>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      data-custom-class="body_text"
                                                      style="line-height: 1.5"
                                                    >
                                                      <span
                                                        data-custom-class="body_text"
                                                        ><span
                                                          style="
                                                            font-size: 15px;
                                                          "
                                                          ><strong
                                                            >Legal
                                                            Obligations.</strong
                                                          >
                                                          We may process your
                                                          information where we
                                                          believe it is
                                                          necessary for
                                                          compliance with our
                                                          legal obligations,
                                                          such as to cooperate
                                                          with a law enforcement
                                                          body or regulatory
                                                          agency, exercise or
                                                          defend our legal
                                                          rights, or disclose
                                                          your information as
                                                          evidence in litigation
                                                          in which we are
                                                          involved.<bdt
                                                            class="statement-end-if-in-editor"
                                                          ></bdt
                                                          ><br /></span
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                  <div style="line-height: 1.5">
                                                    <bdt class="block-component"
                                                      ><span
                                                        style="font-size: 15px"
                                                        ><span
                                                          data-custom-class="body_text"
                                                        ></span></span
                                                    ></bdt>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      data-custom-class="body_text"
                                                      style="line-height: 1.5"
                                                    >
                                                      <span
                                                        data-custom-class="body_text"
                                                        ><span
                                                          style="
                                                            font-size: 15px;
                                                          "
                                                          ><strong
                                                            >Vital
                                                            Interests.</strong
                                                          >
                                                          We may process your
                                                          information where we
                                                          believe it is
                                                          necessary to protect
                                                          your vital interests
                                                          or the vital interests
                                                          of a third party, such
                                                          as situations
                                                          involving potential
                                                          threats to the safety
                                                          of any person.</span
                                                        ></span
                                                      ><bdt
                                                        class="statement-end-if-in-editor"
                                                        ><span
                                                          style="
                                                            font-size: 15px;
                                                          "
                                                          ><span
                                                            data-custom-class="body_text"
                                                          ></span></span
                                                      ></bdt>
                                                    </li>
                                                  </ul>
                                                  <div style="line-height: 1.5">
                                                    <bdt class="block-component"
                                                      ><span
                                                        style="font-size: 15px"
                                                        ><span
                                                          data-custom-class="body_text"
                                                        ></span></span></bdt
                                                    ><span
                                                      data-custom-class="body_text"
                                                      ><span
                                                        style="font-size: 15px"
                                                        ><bdt
                                                          class="block-component"
                                                          ><bdt
                                                            class="block-component"
                                                          ></bdt></bdt></span></span
                                                    ><bdt
                                                      class="statement-end-if-in-editor"
                                                      ><span
                                                        style="font-size: 15px"
                                                        ><span
                                                          data-custom-class="body_text"
                                                        ></span></span
                                                    ></bdt>
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="whoshare"
                                                    style="line-height: 1.5"
                                                  >
                                                    <span
                                                      style="
                                                        color: rgb(
                                                          127,
                                                          127,
                                                          127
                                                        );
                                                      "
                                                      ><span
                                                        style="
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                          font-size: 15px;
                                                        "
                                                        ><span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              id="control"
                                                              style="
                                                                color: rgb(
                                                                  0,
                                                                  0,
                                                                  0
                                                                );
                                                              "
                                                              ><strong
                                                                ><span
                                                                  data-custom-class="heading_1"
                                                                  >4. WHEN AND
                                                                  WITH WHOM DO
                                                                  WE SHARE YOUR
                                                                  PERSONAL
                                                                  INFORMATION?</span
                                                                ></strong
                                                              ></span
                                                            ></span
                                                          ></span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          ><strong
                                                            ><em
                                                              >In Short:</em
                                                            ></strong
                                                          ><em
                                                            >&nbsp;We may share
                                                            information in
                                                            specific situations
                                                            described in this
                                                            section and/or with
                                                            the following
                                                            <bdt
                                                              class="block-component"
                                                            ></bdt
                                                            >third parties.</em
                                                          ></span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          ><bdt
                                                            class="block-component"
                                                          ></bdt></span></span
                                                    ></span>
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="font-size: 15px"
                                                      ><span
                                                        data-custom-class="body_text"
                                                        >We
                                                        <bdt
                                                          class="block-component"
                                                        ></bdt
                                                        >may need to share your
                                                        personal information in
                                                        the following
                                                        situations:</span
                                                      ></span
                                                    >
                                                  </div>
                                                  <ul>
                                                    <li
                                                      data-custom-class="body_text"
                                                      style="line-height: 1.5"
                                                    >
                                                      <span
                                                        style="font-size: 15px"
                                                        ><span
                                                          data-custom-class="body_text"
                                                          ><strong
                                                            >Business
                                                            Transfers.</strong
                                                          >
                                                          We may share or
                                                          transfer your
                                                          information in
                                                          connection with, or
                                                          during negotiations
                                                          of, any merger, sale
                                                          of company assets,
                                                          financing, or
                                                          acquisition of all or
                                                          a portion of our
                                                          business to another
                                                          company.</span
                                                        ></span
                                                      >
                                                    </li>
                                                  </ul>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="font-size: 15px"
                                                      ><span
                                                        data-custom-class="body_text"
                                                        ><bdt
                                                          class="block-component"
                                                        ></bdt></span
                                                    ></span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      data-custom-class="body_text"
                                                      style="line-height: 1.5"
                                                    >
                                                      <span
                                                        style="font-size: 15px"
                                                        ><span
                                                          data-custom-class="body_text"
                                                          ><strong
                                                            >When we use Google
                                                            Maps Platform
                                                            APIs.</strong
                                                          >
                                                          We may share your
                                                          information with
                                                          certain Google Maps
                                                          Platform APIs
                                                          (e.g.<bdt
                                                            class="block-component"
                                                          ></bdt
                                                          >,<bdt
                                                            class="statement-end-if-in-editor"
                                                          ></bdt>
                                                          Google Maps API,
                                                          Places API).</span
                                                        ><span
                                                          data-custom-class="body_text"
                                                          ><bdt
                                                            class="block-component"
                                                          >
                                                            Google Maps uses
                                                            GPS, Wi-Fi, and cell
                                                            towers to estimate
                                                            your location. GPS
                                                            is accurate to about
                                                            20 meters, while
                                                            Wi-Fi and cell
                                                            towers help improve
                                                            accuracy when GPS
                                                            signals are weak,
                                                            like indoors. This
                                                            data helps Google
                                                            Maps provide
                                                            directions, but it
                                                            is not always
                                                            perfectly
                                                            precise.</bdt
                                                          ><bdt
                                                            class="block-component"
                                                          ></bdt>
                                                          We obtain and store on
                                                          your device (<bdt
                                                            class="block-component"
                                                          ></bdt
                                                          >"cache"<bdt
                                                            class="statement-end-if-in-editor"
                                                          ></bdt
                                                          >) your location<bdt
                                                            class="block-component"
                                                          ></bdt
                                                          >. You may revoke your
                                                          consent anytime by
                                                          contacting us at the
                                                          contact details
                                                          provided at the end of
                                                          this document.<bdt
                                                            class="statement-end-if-in-editor"
                                                          ></bdt
                                                          ><bdt
                                                            class="block-component"
                                                          ></bdt></span
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="font-size: 15px"
                                                      ><bdt
                                                        class="block-component"
                                                        ><span
                                                          data-custom-class="body_text"
                                                        ></span></bdt
                                                    ></span>
                                                    <div
                                                      style="line-height: 1.5"
                                                    >
                                                      <bdt
                                                        class="block-component"
                                                        ><span
                                                          style="
                                                            font-size: 15px;
                                                          "
                                                          ><span
                                                            data-custom-class="body_text"
                                                          ></span></span
                                                      ></bdt>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <bdt
                                                          class="block-component"
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                            ></span></span
                                                        ></bdt>
                                                      </div>
                                                      <ul>
                                                        <li
                                                          data-custom-class="body_text"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              ><strong
                                                                >Other
                                                                Users.</strong
                                                              >
                                                              When you share
                                                              personal
                                                              information<bdt
                                                                class="block-component"
                                                              ></bdt>
                                                              (for example, by
                                                              posting comments,
                                                              contributions, or
                                                              other content to
                                                              the Services)<bdt
                                                                class="statement-end-if-in-editor"
                                                              ></bdt>
                                                              or otherwise
                                                              interact with
                                                              public areas of
                                                              the Services, such
                                                              personal
                                                              information may be
                                                              viewed by all
                                                              users and may be
                                                              publicly made
                                                              available outside
                                                              the Services in
                                                              perpetuity.<bdt
                                                                class="block-component"
                                                              ></bdt>
                                                              If you interact
                                                              with other users
                                                              of our Services
                                                              and register for
                                                              our Services
                                                              through a social
                                                              network (such as
                                                              Facebook), your
                                                              contacts on the
                                                              social network
                                                              will see your
                                                              name, profile
                                                              photo, and
                                                              descriptions of
                                                              your activity.<bdt
                                                                class="statement-end-if-in-editor"
                                                              ></bdt>
                                                              Similarly, other
                                                              users will be able
                                                              to view
                                                              descriptions of
                                                              your activity,
                                                              communicate with
                                                              you within our
                                                              Services, and view
                                                              your profile.<bdt
                                                                class="statement-end-if-in-editor"
                                                              ></bdt></span
                                                          ></span>
                                                        </li>
                                                      </ul>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <bdt
                                                          class="block-component"
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                            ></span></span
                                                        ></bdt>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <bdt
                                                            class="block-component"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                              ></span></span></bdt
                                                          ><span
                                                            style="
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                    "
                                                                    ><bdt
                                                                      class="block-component"
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        ><bdt
                                                                          class="block-component"
                                                                        ></bdt></span></bdt></span></span></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="cookies"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              color: rgb(
                                                                127,
                                                                127,
                                                                127
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    id="control"
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        0,
                                                                        0
                                                                      );
                                                                    "
                                                                    ><strong
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        >5. DO
                                                                        WE USE
                                                                        COOKIES
                                                                        AND
                                                                        OTHER
                                                                        TRACKING
                                                                        TECHNOLOGIES?</span
                                                                      ></strong
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><strong
                                                                  ><em
                                                                    >In
                                                                    Short:</em
                                                                  ></strong
                                                                ><em
                                                                  >&nbsp;We may
                                                                  use cookies
                                                                  and other
                                                                  tracking
                                                                  technologies
                                                                  to collect and
                                                                  store your
                                                                  information.</em
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >We may use
                                                                cookies and
                                                                similar tracking
                                                                technologies
                                                                (like web
                                                                beacons and
                                                                pixels) to
                                                                gather
                                                                information when
                                                                you interact
                                                                with our
                                                                Services. Some
                                                                online tracking
                                                                technologies
                                                                help us maintain
                                                                the security of
                                                                our Services<bdt
                                                                  class="block-component"
                                                                ></bdt>
                                                                and your
                                                                account<bdt
                                                                  class="statement-end-if-in-editor"
                                                                ></bdt
                                                                >, prevent
                                                                crashes, fix
                                                                bugs, save your
                                                                preferences, and
                                                                assist with
                                                                basic site
                                                                functions.</span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >We also permit
                                                                third parties
                                                                and service
                                                                providers to use
                                                                online tracking
                                                                technologies on
                                                                our Services for
                                                                analytics and
                                                                advertising,
                                                                including to
                                                                help manage and
                                                                display
                                                                advertisements,
                                                                to tailor
                                                                advertisements
                                                                to your
                                                                interests, or to
                                                                send abandoned
                                                                shopping cart
                                                                reminders
                                                                (depending on
                                                                your
                                                                communication
                                                                preferences).
                                                                The third
                                                                parties and
                                                                service
                                                                providers use
                                                                their technology
                                                                to provide
                                                                advertising
                                                                about products
                                                                and services
                                                                tailored to your
                                                                interests which
                                                                may appear
                                                                either on our
                                                                Services or on
                                                                other
                                                                websites.</span
                                                              ></span
                                                            ></span
                                                          ><bdt
                                                            class="block-component"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                            ></span
                                                          ></bdt>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >Specific
                                                                information
                                                                about how we use
                                                                such
                                                                technologies and
                                                                how you can
                                                                refuse certain
                                                                cookies is set
                                                                out in our
                                                                Cookie
                                                                Notice<span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    data-custom-class="body_text"
                                                                    ><bdt
                                                                      class="block-component"
                                                                    ></bdt
                                                                    >.</span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          ><bdt
                                                            class="block-component"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                            ></span></bdt
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                      font-size: 15px;
                                                                    "
                                                                    ><span
                                                                      style="
                                                                        font-size: 15px;
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                            ><span
                                                                              data-custom-class="body_text"
                                                                              ><bdt
                                                                                class="statement-end-if-in-editor"
                                                                              ></bdt></span></span></span></span></span></span></span></span></span></span
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><bdt
                                                              class="block-component"
                                                            ></bdt></span
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                      font-size: 15px;
                                                                    "
                                                                    ><span
                                                                      style="
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                        font-size: 15px;
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              font-size: 15px;
                                                                            "
                                                                            ><span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                              ><span
                                                                                data-custom-class="body_text"
                                                                                ><bdt
                                                                                  class="block-component"
                                                                                ></bdt></span></span></span></span></span></span></span></span></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="sociallogins"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              color: rgb(
                                                                127,
                                                                127,
                                                                127
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    id="control"
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        0,
                                                                        0
                                                                      );
                                                                    "
                                                                    ><strong
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        >6. HOW
                                                                        DO WE
                                                                        HANDLE
                                                                        YOUR
                                                                        SOCIAL
                                                                        LOGINS?</span
                                                                      ></strong
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><strong
                                                                  ><em
                                                                    >In
                                                                    Short:&nbsp;</em
                                                                  ></strong
                                                                ><em
                                                                  >If you choose
                                                                  to register or
                                                                  log in to our
                                                                  Services using
                                                                  a social media
                                                                  account, we
                                                                  may have
                                                                  access to
                                                                  certain
                                                                  information
                                                                  about you.</em
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >Our Services
                                                                offer you the
                                                                ability to
                                                                register and log
                                                                in using your
                                                                third-party
                                                                social media
                                                                account details
                                                                (like your
                                                                Facebook or X
                                                                logins). Where
                                                                you choose to do
                                                                this, we will
                                                                receive certain
                                                                profile
                                                                information
                                                                about you from
                                                                your social
                                                                media provider.
                                                                The profile
                                                                information we
                                                                receive may vary
                                                                depending on the
                                                                social media
                                                                provider
                                                                concerned, but
                                                                will often
                                                                include your
                                                                name, email
                                                                address, friends
                                                                list, and
                                                                profile picture,
                                                                as well as other
                                                                information you
                                                                choose to make
                                                                public on such a
                                                                social media
                                                                platform.<span
                                                                  style="
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                    "
                                                                    ><span
                                                                      data-custom-class="body_text"
                                                                      ><bdt
                                                                        class="block-component"
                                                                      ></bdt></span></span></span></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >We will use the
                                                                information we
                                                                receive only for
                                                                the purposes
                                                                that are
                                                                described in
                                                                this Privacy
                                                                Notice or that
                                                                are otherwise
                                                                made clear to
                                                                you on the
                                                                relevant
                                                                Services. Please
                                                                note that we do
                                                                not control, and
                                                                are not
                                                                responsible for,
                                                                other uses of
                                                                your personal
                                                                information by
                                                                your third-party
                                                                social media
                                                                provider. We
                                                                recommend that
                                                                you review their
                                                                privacy notice
                                                                to understand
                                                                how they
                                                                collect, use,
                                                                and share your
                                                                personal
                                                                information, and
                                                                how you can set
                                                                your privacy
                                                                preferences on
                                                                their sites and
                                                                apps.<span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                      font-size: 15px;
                                                                    "
                                                                    ><span
                                                                      style="
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                        font-size: 15px;
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                            ><span
                                                                              style="
                                                                                font-size: 15px;
                                                                              "
                                                                              ><span
                                                                                style="
                                                                                  color: rgb(
                                                                                    89,
                                                                                    89,
                                                                                    89
                                                                                  );
                                                                                "
                                                                                ><span
                                                                                  data-custom-class="body_text"
                                                                                  ><bdt
                                                                                    class="statement-end-if-in-editor"
                                                                                  ></bdt></span
                                                                                ><bdt
                                                                                  class="block-component"
                                                                                  ><span
                                                                                    data-custom-class="body_text"
                                                                                    ><bdt
                                                                                      class="block-component"
                                                                                    ></bdt></span></bdt></span></span></span></span></span></span></span></span></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="inforetain"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              color: rgb(
                                                                127,
                                                                127,
                                                                127
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    id="control"
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        0,
                                                                        0
                                                                      );
                                                                    "
                                                                    ><strong
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        >7. HOW
                                                                        LONG DO
                                                                        WE KEEP
                                                                        YOUR
                                                                        INFORMATION?</span
                                                                      ></strong
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><strong
                                                                  ><em
                                                                    >In
                                                                    Short:&nbsp;</em
                                                                  ></strong
                                                                ><em
                                                                  >We keep your
                                                                  information
                                                                  for as long as
                                                                  necessary to
                                                                  <bdt
                                                                    class="block-component"
                                                                  ></bdt
                                                                  >fulfill<bdt
                                                                    class="statement-end-if-in-editor"
                                                                  ></bdt>
                                                                  the purposes
                                                                  outlined in
                                                                  this Privacy
                                                                  Notice unless
                                                                  otherwise
                                                                  required by
                                                                  law.</em
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >We will only
                                                                keep your
                                                                personal
                                                                information for
                                                                as long as it is
                                                                necessary for
                                                                the purposes set
                                                                out in this
                                                                Privacy Notice,
                                                                unless a longer
                                                                retention period
                                                                is required or
                                                                permitted by law
                                                                (such as tax,
                                                                accounting, or
                                                                other legal
                                                                requirements).<bdt
                                                                  class="block-component"
                                                                ></bdt>
                                                                No purpose in
                                                                this notice will
                                                                require us
                                                                keeping your
                                                                personal
                                                                information for
                                                                longer than
                                                                <span
                                                                  style="
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                    "
                                                                    ><span
                                                                      data-custom-class="body_text"
                                                                      ><bdt
                                                                        class="block-component"
                                                                      ></bdt></span></span></span
                                                                ><bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >the period of
                                                                time in which
                                                                users have an
                                                                account with
                                                                us<bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                    "
                                                                    ><span
                                                                      data-custom-class="body_text"
                                                                      ><bdt
                                                                        class="else-block"
                                                                      ></bdt></span></span></span
                                                                >.</span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >When we have no
                                                                ongoing
                                                                legitimate
                                                                business need to
                                                                process your
                                                                personal
                                                                information, we
                                                                will either
                                                                delete or
                                                                <bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >anonymize<bdt
                                                                  class="statement-end-if-in-editor"
                                                                ></bdt>
                                                                such
                                                                information, or,
                                                                if this is not
                                                                possible (for
                                                                example, because
                                                                your personal
                                                                information has
                                                                been stored in
                                                                backup
                                                                archives), then
                                                                we will securely
                                                                store your
                                                                personal
                                                                information and
                                                                isolate it from
                                                                any further
                                                                processing until
                                                                deletion is
                                                                possible.<span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><bdt
                                                                    class="block-component"
                                                                  ></bdt></span></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="infosafe"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              color: rgb(
                                                                127,
                                                                127,
                                                                127
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    id="control"
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        0,
                                                                        0
                                                                      );
                                                                    "
                                                                    ><strong
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        >8. HOW
                                                                        DO WE
                                                                        KEEP
                                                                        YOUR
                                                                        INFORMATION
                                                                        SAFE?</span
                                                                      ></strong
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><strong
                                                                  ><em
                                                                    >In
                                                                    Short:&nbsp;</em
                                                                  ></strong
                                                                ><em
                                                                  >We aim to
                                                                  protect your
                                                                  personal
                                                                  information
                                                                  through a
                                                                  system of
                                                                  <bdt
                                                                    class="block-component"
                                                                  ></bdt
                                                                  >organizational<bdt
                                                                    class="statement-end-if-in-editor"
                                                                  ></bdt>
                                                                  and technical
                                                                  security
                                                                  measures.</em
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >We have
                                                                implemented
                                                                appropriate and
                                                                reasonable
                                                                technical and
                                                                <bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >organizational<bdt
                                                                  class="statement-end-if-in-editor"
                                                                ></bdt>
                                                                security
                                                                measures
                                                                designed to
                                                                protect the
                                                                security of any
                                                                personal
                                                                information we
                                                                process.
                                                                However, despite
                                                                our safeguards
                                                                and efforts to
                                                                secure your
                                                                information, no
                                                                electronic
                                                                transmission
                                                                over the
                                                                Internet or
                                                                information
                                                                storage
                                                                technology can
                                                                be guaranteed to
                                                                be 100% secure,
                                                                so we cannot
                                                                promise or
                                                                guarantee that
                                                                hackers,
                                                                cybercriminals,
                                                                or other
                                                                <bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >unauthorized<bdt
                                                                  class="statement-end-if-in-editor"
                                                                ></bdt>
                                                                third parties
                                                                will not be able
                                                                to defeat our
                                                                security and
                                                                improperly
                                                                collect, access,
                                                                steal, or modify
                                                                your
                                                                information.
                                                                Although we will
                                                                do our best to
                                                                protect your
                                                                personal
                                                                information,
                                                                transmission of
                                                                personal
                                                                information to
                                                                and from our
                                                                Services is at
                                                                your own risk.
                                                                You should only
                                                                access the
                                                                Services within
                                                                a secure
                                                                environment.<span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><bdt
                                                                    class="statement-end-if-in-editor"
                                                                  ></bdt></span
                                                                ><span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    data-custom-class="body_text"
                                                                    ><bdt
                                                                      class="block-component"
                                                                    ></bdt></span></span></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="infominors"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              color: rgb(
                                                                127,
                                                                127,
                                                                127
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    id="control"
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        0,
                                                                        0
                                                                      );
                                                                    "
                                                                    ><strong
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        >9. DO
                                                                        WE
                                                                        COLLECT
                                                                        INFORMATION
                                                                        FROM
                                                                        MINORS?</span
                                                                      ></strong
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><strong
                                                                  ><em
                                                                    >In
                                                                    Short:</em
                                                                  ></strong
                                                                ><em
                                                                  >&nbsp;We do
                                                                  not knowingly
                                                                  collect data
                                                                  from or market
                                                                  to
                                                                  <bdt
                                                                    class="block-component"
                                                                  ></bdt
                                                                  >children
                                                                  under 18 years
                                                                  of age<bdt
                                                                    class="else-block"
                                                                  ></bdt
                                                                  >.</em
                                                                ><bdt
                                                                  class="block-component"
                                                                ></bdt></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >We do not
                                                                knowingly
                                                                collect, solicit
                                                                data from, or
                                                                market to
                                                                children under
                                                                18 years of age,
                                                                nor do we
                                                                knowingly sell
                                                                such personal
                                                                information. By
                                                                using the
                                                                Services, you
                                                                represent that
                                                                you are at least
                                                                18 or that you
                                                                are the parent
                                                                or guardian of
                                                                such a minor and
                                                                consent to such
                                                                minor
                                                                dependent’s use
                                                                of the Services.
                                                                If we learn that
                                                                personal
                                                                information from
                                                                users less than
                                                                18 years of age
                                                                has been
                                                                collected, we
                                                                will deactivate
                                                                the account and
                                                                take reasonable
                                                                measures to
                                                                promptly delete
                                                                such data from
                                                                our records. If
                                                                you become aware
                                                                of any data we
                                                                may have
                                                                collected from
                                                                children under
                                                                age 18, please
                                                                contact us at
                                                                <span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    data-custom-class="body_text"
                                                                    ><bdt
                                                                      class="block-component"
                                                                    ></bdt
                                                                    ><bdt
                                                                      class="question"
                                                                      >play@courtgo.com</bdt
                                                                    ><bdt
                                                                      class="else-block"
                                                                    ></bdt></span></span
                                                                >.</span
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><bdt
                                                                  class="else-block"
                                                                  ><bdt
                                                                    class="block-component"
                                                                  ></bdt></bdt></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="privacyrights"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              color: rgb(
                                                                127,
                                                                127,
                                                                127
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    id="control"
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        0,
                                                                        0
                                                                      );
                                                                    "
                                                                    ><strong
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        >10.
                                                                        WHAT ARE
                                                                        YOUR
                                                                        PRIVACY
                                                                        RIGHTS?</span
                                                                      ></strong
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><strong
                                                                  ><em
                                                                    >In
                                                                    Short:</em
                                                                  ></strong
                                                                ><em
                                                                  >&nbsp;<span
                                                                    style="
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                    "
                                                                    ><span
                                                                      style="
                                                                        font-size: 15px;
                                                                      "
                                                                      ><span
                                                                        data-custom-class="body_text"
                                                                        ><em
                                                                          ><bdt
                                                                            class="block-component"
                                                                          ></bdt></em></span></span></span
                                                                  ><bdt
                                                                    class="block-component"
                                                                  ></bdt
                                                                  >In
                                                                  <bdt
                                                                    class="statement-end-if-in-editor"
                                                                  ></bdt
                                                                  >some regions,
                                                                  such as
                                                                  <bdt
                                                                    class="block-component"
                                                                  ></bdt
                                                                  >the European
                                                                  Economic Area
                                                                  (EEA), United
                                                                  Kingdom (UK),
                                                                  and
                                                                  Switzerland<bdt
                                                                    class="block-component"
                                                                  ></bdt
                                                                  >, you have
                                                                  rights that
                                                                  allow you
                                                                  greater access
                                                                  to and control
                                                                  over your
                                                                  personal
                                                                  information.<span
                                                                    style="
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                    "
                                                                    ><span
                                                                      style="
                                                                        font-size: 15px;
                                                                      "
                                                                      ><span
                                                                        data-custom-class="body_text"
                                                                        ><em
                                                                          ><bdt
                                                                            class="statement-end-if-in-editor"
                                                                          ></bdt></em></span></span
                                                                    >&nbsp;</span
                                                                  >You may
                                                                  review,
                                                                  change, or
                                                                  terminate your
                                                                  account at any
                                                                  time,
                                                                  depending on
                                                                  your country,
                                                                  province, or
                                                                  state of
                                                                  residence.</em
                                                                ><span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      font-size: 15px;
                                                                    "
                                                                    ><bdt
                                                                      class="block-component"
                                                                    ></bdt></span></span></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >In some regions
                                                                (like
                                                                <bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >the EEA, UK,
                                                                and
                                                                Switzerland<bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >), you have
                                                                certain rights
                                                                under applicable
                                                                data protection
                                                                laws. These may
                                                                include the
                                                                right (i) to
                                                                request access
                                                                and obtain a
                                                                copy of your
                                                                personal
                                                                information,
                                                                (ii) to request
                                                                rectification or
                                                                erasure; (iii)
                                                                to restrict the
                                                                processing of
                                                                your personal
                                                                information;
                                                                (iv) if
                                                                applicable, to
                                                                data
                                                                portability; and
                                                                (v) not to be
                                                                subject to
                                                                automated
                                                                decision-making.
                                                                In certain
                                                                circumstances,
                                                                you may also
                                                                have the right
                                                                to object to the
                                                                processing of
                                                                your personal
                                                                information. You
                                                                can make such a
                                                                request by
                                                                contacting us by
                                                                using the
                                                                contact details
                                                                provided in the
                                                                section
                                                                <bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >"<bdt
                                                                  class="statement-end-if-in-editor"
                                                                ></bdt></span></span></span
                                                          ><a
                                                            data-custom-class="link"
                                                            href="#contact"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  0,
                                                                  58,
                                                                  250
                                                                );
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    0,
                                                                    58,
                                                                    250
                                                                  );
                                                                "
                                                                ><span
                                                                  data-custom-class="body_text"
                                                                  >HOW CAN YOU
                                                                  CONTACT US
                                                                  ABOUT THIS
                                                                  NOTICE?</span
                                                                ></span
                                                              ></span
                                                            ></a
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >"<bdt
                                                                  class="statement-end-if-in-editor"
                                                                ></bdt>
                                                                below.</span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >We will
                                                                consider and act
                                                                upon any request
                                                                in accordance
                                                                with applicable
                                                                data protection
                                                                laws.<bdt
                                                                  class="block-component"
                                                                ></bdt></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            >&nbsp;</span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >If you are
                                                                located in the
                                                                EEA or UK and
                                                                you believe we
                                                                are unlawfully
                                                                processing your
                                                                personal
                                                                information, you
                                                                also have the
                                                                right to
                                                                complain to your
                                                                <span
                                                                  style="
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        58,
                                                                        250
                                                                      );
                                                                    "
                                                                    ><span
                                                                      data-custom-class="body_text"
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            0,
                                                                            58,
                                                                            250
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          ><a
                                                                            data-custom-class="link"
                                                                            href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"
                                                                            ><span
                                                                              style="
                                                                                font-size: 15px;
                                                                              "
                                                                              >Member
                                                                              State
                                                                              data
                                                                              protection
                                                                              authority</span
                                                                            ></a
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                >
                                                                or&nbsp;</span
                                                              ></span
                                                            ></span
                                                          ><a
                                                            data-custom-class="link"
                                                            href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  0,
                                                                  58,
                                                                  250
                                                                );
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    0,
                                                                    58,
                                                                    250
                                                                  );
                                                                "
                                                                ><span
                                                                  data-custom-class="body_text"
                                                                  >UK data
                                                                  protection
                                                                  authority</span
                                                                ></span
                                                              ></span
                                                            ></a
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >.</span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >If you are
                                                                located in
                                                                Switzerland, you
                                                                may contact the
                                                                <span
                                                                  style="
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        58,
                                                                        250
                                                                      );
                                                                    "
                                                                    ><span
                                                                      data-custom-class="body_text"
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            0,
                                                                            58,
                                                                            250
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          ><span
                                                                            style="
                                                                              color: rgb(
                                                                                0,
                                                                                58,
                                                                                250
                                                                              );
                                                                              font-size: 15px;
                                                                            "
                                                                            ><a
                                                                              data-custom-class="link"
                                                                              href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                              rel="noopener noreferrer"
                                                                              target="_blank"
                                                                              >Federal
                                                                              Data
                                                                              Protection
                                                                              and
                                                                              Information
                                                                              Commissioner</a
                                                                            ></span
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                >.</span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="withdrawconsent"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><strong
                                                                  ><u
                                                                    >Withdrawing
                                                                    your
                                                                    consent:</u
                                                                  ></strong
                                                                >
                                                                If we are
                                                                relying on your
                                                                consent to
                                                                process your
                                                                personal
                                                                information,<bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                ><bdt
                                                                  class="else-block"
                                                                ></bdt>
                                                                you have the
                                                                right to
                                                                withdraw your
                                                                consent at any
                                                                time. You can
                                                                withdraw your
                                                                consent at any
                                                                time by
                                                                contacting us by
                                                                using the
                                                                contact details
                                                                provided in the
                                                                section
                                                                <bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >"<bdt
                                                                  class="statement-end-if-in-editor"
                                                                ></bdt></span></span></span
                                                          ><a
                                                            data-custom-class="link"
                                                            href="#contact"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  0,
                                                                  58,
                                                                  250
                                                                );
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    0,
                                                                    58,
                                                                    250
                                                                  );
                                                                "
                                                                ><span
                                                                  data-custom-class="body_text"
                                                                  >HOW CAN YOU
                                                                  CONTACT US
                                                                  ABOUT THIS
                                                                  NOTICE?</span
                                                                ></span
                                                              ></span
                                                            ></a
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >"<bdt
                                                                  class="statement-end-if-in-editor"
                                                                ></bdt>
                                                                below<bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >.</span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >However, please
                                                              note that this
                                                              will not affect
                                                              the lawfulness of
                                                              the processing
                                                              before its
                                                              withdrawal
                                                              nor,<bdt
                                                                class="block-component"
                                                              ></bdt
                                                              ><bdt
                                                                class="else-block"
                                                              ></bdt>
                                                              will it affect the
                                                              processing of your
                                                              personal
                                                              information
                                                              conducted in
                                                              reliance on lawful
                                                              processing grounds
                                                              other than
                                                              consent.<bdt
                                                                class="block-component"
                                                              ></bdt></span></span
                                                          ><bdt
                                                            class="block-component"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                              ></span></span
                                                          ></bdt>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="heading_2"
                                                              ><strong
                                                                >Account
                                                                Information</strong
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            data-custom-class="body_text"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              >If you would at
                                                              any time like to
                                                              review or change
                                                              the information in
                                                              your account or
                                                              terminate your
                                                              account, you
                                                              can:<bdt
                                                                class="forloop-component"
                                                              ></bdt></span
                                                          ></span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              data-custom-class="body_text"
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                "
                                                                ><bdt
                                                                  class="question"
                                                                  >Log in to
                                                                  your account
                                                                  settings and
                                                                  update your
                                                                  user
                                                                  account.</bdt
                                                                ></span
                                                              ></span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            data-custom-class="body_text"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><bdt
                                                                class="forloop-component"
                                                              ></bdt></span
                                                          ></span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              data-custom-class="body_text"
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                "
                                                                ><bdt
                                                                  class="question"
                                                                  >Contact us
                                                                  using the
                                                                  contact
                                                                  information
                                                                  provided.</bdt
                                                                ></span
                                                              ></span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            data-custom-class="body_text"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><bdt
                                                                class="forloop-component"
                                                              ></bdt></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >Upon your request
                                                              to terminate your
                                                              account, we will
                                                              deactivate or
                                                              delete your
                                                              account and
                                                              information from
                                                              our active
                                                              databases.
                                                              However, we may
                                                              retain some
                                                              information in our
                                                              files to prevent
                                                              fraud,
                                                              troubleshoot
                                                              problems, assist
                                                              with any
                                                              investigations,
                                                              enforce our legal
                                                              terms and/or
                                                              comply with
                                                              applicable legal
                                                              requirements.</span
                                                            ></span
                                                          ><bdt
                                                            class="statement-end-if-in-editor"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                              ></span></span></bdt
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                    "
                                                                    ><span
                                                                      style="
                                                                        font-size: 15px;
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          ><span
                                                                            style="
                                                                              font-size: 15px;
                                                                            "
                                                                            ><span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                              ><bdt
                                                                                class="block-component"
                                                                              ></bdt></span></span></span></span></span></span></span></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><strong
                                                                  ><u
                                                                    >Cookies and
                                                                    similar
                                                                    technologies:</u
                                                                  ></strong
                                                                >
                                                                Most Web
                                                                browsers are set
                                                                to accept
                                                                cookies by
                                                                default. If you
                                                                prefer, you can
                                                                usually choose
                                                                to set your
                                                                browser to
                                                                remove cookies
                                                                and to reject
                                                                cookies. If you
                                                                choose to remove
                                                                cookies or
                                                                reject cookies,
                                                                this could
                                                                affect certain
                                                                features or
                                                                services of our
                                                                Services.
                                                                <bdt
                                                                  class="block-component"
                                                                  ><span
                                                                    style="
                                                                      font-size: 15px;
                                                                    "
                                                                    ><span
                                                                      style="
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      ><span
                                                                        data-custom-class="body_text"
                                                                        ><bdt
                                                                          class="block-component"
                                                                        ></bdt
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                            ><span
                                                                              style="
                                                                                font-size: 15px;
                                                                              "
                                                                              ><span
                                                                                style="
                                                                                  color: rgb(
                                                                                    89,
                                                                                    89,
                                                                                    89
                                                                                  );
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      color: rgb(
                                                                                        89,
                                                                                        89,
                                                                                        89
                                                                                      );
                                                                                    "
                                                                                    ><bdt
                                                                                      class="statement-end-if-in-editor"
                                                                                    ></bdt></span></span></span></span></span></span></span></span></span></bdt></span></span></span
                                                          ><bdt
                                                            class="block-component"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                              ></span></span
                                                          ></bdt>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            data-custom-class="body_text"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              >If you have
                                                              questions or
                                                              comments about
                                                              your privacy
                                                              rights, you may
                                                              email us at
                                                              <bdt
                                                                class="question"
                                                                >privacy@courtgo.com</bdt
                                                              >.</span
                                                            ></span
                                                          ><bdt
                                                            class="statement-end-if-in-editor"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                              ></span></span
                                                          ></bdt>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="DNT"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              color: rgb(
                                                                127,
                                                                127,
                                                                127
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    id="control"
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        0,
                                                                        0
                                                                      );
                                                                    "
                                                                    ><strong
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        >11.
                                                                        CONTROLS
                                                                        FOR
                                                                        DO-NOT-TRACK
                                                                        FEATURES</span
                                                                      ></strong
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >Most web
                                                                browsers and
                                                                some mobile
                                                                operating
                                                                systems and
                                                                mobile
                                                                applications
                                                                include a
                                                                Do-Not-Track
                                                                (<bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >"DNT"<bdt
                                                                  class="statement-end-if-in-editor"
                                                                ></bdt
                                                                >) feature or
                                                                setting you can
                                                                activate to
                                                                signal your
                                                                privacy
                                                                preference not
                                                                to have data
                                                                about your
                                                                online browsing
                                                                activities
                                                                monitored and
                                                                collected. At
                                                                this stage, no
                                                                uniform
                                                                technology
                                                                standard for
                                                                <bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >recognizing<bdt
                                                                  class="statement-end-if-in-editor"
                                                                ></bdt>
                                                                and implementing
                                                                DNT signals has
                                                                been
                                                                <bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >finalized<bdt
                                                                  class="statement-end-if-in-editor"
                                                                ></bdt
                                                                >. As such, we
                                                                do not currently
                                                                respond to DNT
                                                                browser signals
                                                                or any other
                                                                mechanism that
                                                                automatically
                                                                communicates
                                                                your choice not
                                                                to be tracked
                                                                online. If a
                                                                standard for
                                                                online tracking
                                                                is adopted that
                                                                we must follow
                                                                in the future,
                                                                we will inform
                                                                you about that
                                                                practice in a
                                                                revised version
                                                                of this Privacy
                                                                Notice.</span
                                                              ></span
                                                            ></span
                                                          ><bdt
                                                            class="block-component"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                            ></span
                                                          ></bdt>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><bdt
                                                                  class="block-component"
                                                                ></bdt></span></span></span
                                                          ><bdt
                                                            class="block-component"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                            ></span></bdt
                                                          ><bdt
                                                            class="block-component"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                            ></span
                                                          ></bdt>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="policyupdates"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              color: rgb(
                                                                127,
                                                                127,
                                                                127
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    id="control"
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        0,
                                                                        0
                                                                      );
                                                                    "
                                                                    ><strong
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        >12. DO
                                                                        WE MAKE
                                                                        UPDATES
                                                                        TO THIS
                                                                        NOTICE?</span
                                                                      ></strong
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><em
                                                                  ><strong
                                                                    >In
                                                                    Short:&nbsp;</strong
                                                                  >Yes, we will
                                                                  update this
                                                                  notice as
                                                                  necessary to
                                                                  stay compliant
                                                                  with relevant
                                                                  laws.</em
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >We may update
                                                                this Privacy
                                                                Notice from time
                                                                to time. The
                                                                updated version
                                                                will be
                                                                indicated by an
                                                                updated
                                                                <bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >"Revised"<bdt
                                                                  class="statement-end-if-in-editor"
                                                                ></bdt>
                                                                date at the top
                                                                of this Privacy
                                                                Notice. If we
                                                                make material
                                                                changes to this
                                                                Privacy Notice,
                                                                we may notify
                                                                you either by
                                                                prominently
                                                                posting a notice
                                                                of such changes
                                                                or by directly
                                                                sending you a
                                                                notification. We
                                                                encourage you to
                                                                review this
                                                                Privacy Notice
                                                                frequently to be
                                                                informed of how
                                                                we are
                                                                protecting your
                                                                information.</span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="contact"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              color: rgb(
                                                                127,
                                                                127,
                                                                127
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    id="control"
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        0,
                                                                        0
                                                                      );
                                                                    "
                                                                    ><strong
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        >13. HOW
                                                                        CAN YOU
                                                                        CONTACT
                                                                        US ABOUT
                                                                        THIS
                                                                        NOTICE?</span
                                                                      ></strong
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >If you have
                                                                questions or
                                                                comments about
                                                                this notice, you
                                                                may
                                                                <span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    data-custom-class="body_text"
                                                                    ><bdt
                                                                      class="block-component"
                                                                      ><bdt
                                                                        class="block-component"
                                                                      ></bdt></bdt
                                                                    >email us at
                                                                    <bdt
                                                                      class="question"
                                                                      >play@courtgo.com
                                                                      or&nbsp;</bdt
                                                                    ><bdt
                                                                      class="statement-end-if-in-editor"
                                                                      ><bdt
                                                                        class="block-component"
                                                                      ></bdt></bdt></span></span
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      font-size: 15px;
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                    "
                                                                    ><span
                                                                      data-custom-class="body_text"
                                                                      >contact
                                                                      us by post
                                                                      at:</span
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      color: rgb(
                                                                        89,
                                                                        89,
                                                                        89
                                                                      );
                                                                    "
                                                                    ><span
                                                                      style="
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      ><span
                                                                        data-custom-class="body_text"
                                                                        ><bdt
                                                                          class="question"
                                                                          >Argonaut
                                                                          Security
                                                                          LLC</bdt
                                                                        ></span
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    data-custom-class="body_text"
                                                                    ><bdt
                                                                      class="block-component"
                                                                    ></bdt></span></span></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              ><bdt
                                                                class="question"
                                                                >1632 1st Ave.,
                                                                New York, NY
                                                                10028, USA</bdt
                                                              ><span
                                                                style="
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                  "
                                                                  ><bdt
                                                                    class="block-component"
                                                                  ></bdt></span></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              ><bdt
                                                                class="question"
                                                                >New York</bdt
                                                              ><span
                                                                style="
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                  "
                                                                  ><bdt
                                                                    class="block-component"
                                                                  ></bdt
                                                                  ><bdt
                                                                    class="block-component"
                                                                  ></bdt
                                                                  >,
                                                                  <bdt
                                                                    class="question"
                                                                    >NY</bdt
                                                                  ><bdt
                                                                    class="statement-end-if-in-editor"
                                                                  ></bdt
                                                                  ><bdt
                                                                    class="block-component"
                                                                  ></bdt
                                                                  ><bdt
                                                                    class="block-component"
                                                                  ></bdt>
                                                                  <bdt
                                                                    class="question"
                                                                    >10028</bdt
                                                                  ><bdt
                                                                    class="statement-end-if-in-editor"
                                                                  ></bdt
                                                                  ><bdt
                                                                    class="block-component"
                                                                  ></bdt
                                                                  ><bdt
                                                                    class="block-component"
                                                                  ></bdt
                                                                  ><bdt
                                                                    class="block-component"
                                                                  ></bdt></span></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            data-custom-class="body_text"
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><bdt
                                                              class="question"
                                                              >United
                                                              States<span
                                                                data-custom-class="body_text"
                                                                ><span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      font-size: 15px;
                                                                    "
                                                                    ><span
                                                                      data-custom-class="body_text"
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                          "
                                                                          ><span
                                                                            data-custom-class="body_text"
                                                                            ><span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                              ><span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                "
                                                                                ><bdt
                                                                                  class="statement-end-if-in-editor"
                                                                                ></bdt></span></span></span></span></span></span></span></span></span></bdt></span
                                                          ><bdt
                                                            class="block-component"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                              ></span></span></bdt
                                                          ><bdt
                                                            class="statement-end-if-in-editor"
                                                          ></bdt
                                                          ><span
                                                            data-custom-class="body_text"
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                "
                                                                ><bdt
                                                                  class="statement-end-if-in-editor"
                                                                ></bdt></span></span></span
                                                          ><span
                                                            data-custom-class="body_text"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><bdt
                                                                    class="statement-end-if-in-editor"
                                                                    ><span
                                                                      style="
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          ><bdt
                                                                            class="block-component"
                                                                            ><bdt
                                                                              class="block-component"
                                                                            ></bdt></bdt></span></span></span></bdt></span></span></span
                                                            ><bdt
                                                              class="block-component"
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                "
                                                              ></span></bdt
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      font-size: 15px;
                                                                    "
                                                                    ><span
                                                                      data-custom-class="body_text"
                                                                      ><bdt
                                                                        class="statement-end-if-in-editor"
                                                                        ><bdt
                                                                          class="block-component"
                                                                        ></bdt></bdt></span></span></span></span></span
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="request"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              color: rgb(
                                                                127,
                                                                127,
                                                                127
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    id="control"
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        0,
                                                                        0
                                                                      );
                                                                    "
                                                                    ><strong
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        >14. HOW
                                                                        CAN YOU
                                                                        REVIEW,
                                                                        UPDATE,
                                                                        OR
                                                                        DELETE
                                                                        THE DATA
                                                                        WE
                                                                        COLLECT
                                                                        FROM
                                                                        YOU?</span
                                                                      ></strong
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >Based on the
                                                                applicable laws
                                                                of your
                                                                country<bdt
                                                                  class="block-component"
                                                                ></bdt
                                                                >, you may<bdt
                                                                  class="else-block"
                                                                  ><bdt
                                                                    class="block-component"
                                                                  >
                                                                    have the
                                                                    right to
                                                                    request
                                                                    access to
                                                                    the personal
                                                                    information
                                                                    we collect
                                                                    from you,
                                                                    details
                                                                    about how we
                                                                    have
                                                                    processed
                                                                    it, correct
                                                                    inaccuracies,
                                                                    or delete
                                                                    your
                                                                    personal
                                                                    information.
                                                                    You may also
                                                                    have the
                                                                    right to
                                                                    <bdt
                                                                      class="block-component"
                                                                    ></bdt
                                                                    >withdraw
                                                                    your consent
                                                                    to our
                                                                    processing
                                                                    of your
                                                                    personal
                                                                    information.
                                                                    These rights
                                                                    may be
                                                                    limited in
                                                                    some
                                                                    circumstances
                                                                    by
                                                                    applicable
                                                                    law. To
                                                                    request to
                                                                    review,
                                                                    update, or
                                                                    delete your
                                                                    personal
                                                                    information,
                                                                    please
                                                                    <bdt
                                                                      class="block-component"
                                                                    ></bdt
                                                                    >fill out
                                                                    and submit
                                                                    a&nbsp;</bdt
                                                                  ></bdt
                                                                ></span
                                                              ><span
                                                                style="
                                                                  color: rgb(
                                                                    0,
                                                                    58,
                                                                    250
                                                                  );
                                                                "
                                                                ><span
                                                                  data-custom-class="body_text"
                                                                  ><span
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        58,
                                                                        250
                                                                      );
                                                                      font-size: 15px;
                                                                    "
                                                                    ><a
                                                                      data-custom-class="link"
                                                                      href="https://app.termly.io/notify/f1052077-6127-4964-b388-473a848eb791"
                                                                      rel="noopener noreferrer"
                                                                      target="_blank"
                                                                      >data
                                                                      subject
                                                                      access
                                                                      request</a
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ><bdt
                                                                class="block-component"
                                                                ><span
                                                                  data-custom-class="body_text"
                                                                ></span></bdt></span></span
                                                          ><span
                                                            data-custom-class="body_text"
                                                            >.</span
                                                          >
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>`;

export class Privacy extends Component {
  render() {
    return <div dangerouslySetInnerHTML={{ __html: privacy }}></div>;
  }
}
