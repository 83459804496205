import { createContext, useReducer } from 'react';
import { setCurrentSiteId } from '../Util/Store';
import { LoadingStatus } from './LoadingStatus';

export type SiteState = {
  id: number;
};

// Currently we take the early-loading route. The amount of data pe-site
// theoretically shouldn't be too large. If this changes in the future, we
// should update this to lazy-load.
export type SiteContextType = {
  site: SiteState;
  load: (siteID: number) => Promise<void>;
  dispatch: (action: SiteAction) => void;
};

const initialState: SiteState = {
  id: 0,
};

const initialContext: SiteContextType = {
  site: initialState,
  dispatch: () => {},
  load: async () => {},
};

export const SiteContext = createContext(initialContext);

export const SetSite = 'set-site';

type SiteAction = { type: typeof SetSite; payload: SiteState };

export function reducer(state: SiteState, action: SiteAction) {
  switch (action.type) {
    case SetSite:
      setCurrentSiteId(action.payload.id);
      return {
        ...action.payload,
        loadingStatus: LoadingStatus.LOADED,
      };
    default:
      return state;
  }
}

export const SiteContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const context: SiteContextType = {
    site: state,
    dispatch: dispatch,
    load: async (siteID: number) => {
      dispatch({
        type: SetSite,
        payload: {
          id: siteID,
        },
      });
    },
  };

  return (
    <SiteContext.Provider value={context}>{children}</SiteContext.Provider>
  );
};
