import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useContext, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { SiteContext } from '../../../Contexts/SiteContext';
import {
  BookingRule,
  ValidateMemberTypeRule as ValidateBookingRule,
} from '../../../Model/BookingRule';
import {
  useAddBookingRuleMutation,
  useUpdateBookingRuleMutation,
} from '../../../Service/BookingRules';
import { getErrorMessage } from '../../../Util/Error';
import { BookingRuleSettings } from './BookingRuleSettings';

type AddEditRuleDialogProps = {
  rule: BookingRule;
  open: boolean;
  setOpen: (value: boolean) => void;
  onClose?: () => void;
};

export const AddEditRuleDialog = (props: AddEditRuleDialogProps) => {
  const [rule, setRule] = useState<BookingRule>(props.rule);

  const [error, setError] = useState<string>();

  const siteCtx = useContext(SiteContext);

  const [addBookingRule, { isLoading: isBookingRuleLoading }] =
    useAddBookingRuleMutation();
  const [updateBookingRule, { isLoading: isUpdateBookingRuleLoading }] =
    useUpdateBookingRuleMutation();

  const isSaving = isBookingRuleLoading || isUpdateBookingRuleLoading;

  const postBookingRule = async (rule: BookingRule) => {
    try {
      await addBookingRule({
        siteID: siteCtx.site.id,
        bookingRule: rule,
      }).unwrap();
      props.setOpen(false);
    } catch (e) {
      const msg = getErrorMessage(e);
      setError(msg);
    }
  };

  const putBookingRule = async (rule: BookingRule) => {
    try {
      await updateBookingRule({
        siteID: siteCtx.site.id,
        bookingRule: rule,
      }).unwrap();

      props.setOpen(false);
    } catch (e) {
      const msg = getErrorMessage(e);
      setError(msg);
    }
  };

  const handleSave = (): void => {
    setError(undefined);
    const errors = ValidateBookingRule(rule);
    if (Object.keys(errors).length > 0) {
      console.debug(errors);
      return;
    }
    if (rule.ID === 0) {
      postBookingRule(rule);
    } else {
      putBookingRule(rule);
    }
  };

  function handleOnRuleChange(value: BookingRule): void {
    setRule(value);
  }

  return (
    <Dialog open={props.open} fullWidth onClose={props.onClose}>
      <DialogTitle textAlign={'center'}>Booking Rule</DialogTitle>
      <DialogContent>
        <ErrorBoundary fallback={<div>Something went wrong...</div>}>
          <BookingRuleSettings value={rule} onChange={handleOnRuleChange} />
          <Box py={2} textAlign={'center'} sx={{ color: 'red' }}>
            {error}
          </Box>
        </ErrorBoundary>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isSaving} onClick={handleSave} color="primary">
          Save
        </LoadingButton>
        <Button color="inherit" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
