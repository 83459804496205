import { css } from '@emotion/react';

export type StyleOptions = {
  outOfRangeColor?: string;
  outOfRangeBgColor?: string;
  calendarBorder?: string;
  cellBorder?: string;
  timeSlotBorder?: string;
  timeSelectionColor?: string;
  timeSelectionBgColor?: string;
  dateSelectionBgColor?: string;

  timeGutterColor?: string;
  timeGutterFontSize?: string;

  eventBg?: string;
  eventMinWidth?: string;
  eventMinHeight?: string;
  eventBgSelected?: string;
  eventBorder?: string;
  eventBorderSelected?: string;
  eventOutline?: string;
  eventColor?: string;
  eventBorderRadius?: string;
  eventPadding?: string;
  eventZindex?: string;

  btnColor?: string;
  btnBg?: string;
  btnBorder?: string;
  btnBgHover?: string;
  btnBorderHover?: string;
  btnBgSelected?: string;
  btnBorderSelected?: string;

  currentTimeColor?: string;
  todayHighlightBg?: string;

  resourcesFontFamily?: string;
  resourcesFontColor?: string;
  resourcesFontWeight?: string;
  activeBackground?: string;
  activeBorder?: string;
};

export const DefaultStyles: StyleOptions = {
  outOfRangeColor: '#999999',
  outOfRangeBgColor: '#CCCCCC',
  calendarBorder: '#ddd',
  cellBorder: '#ddd',
  timeSlotBorder: '#F7F7F7',
  timeSelectionColor: 'white',
  timeSelectionBgColor: 'rgba(0, 0, 0, 0.5)',
  dateSelectionBgColor: 'rgba(0, 0, 0, 0.1)',

  timeGutterColor: 'gray',
  timeGutterFontSize: 'smaller',

  eventBg: '#3174ad',
  eventBgSelected: '#185B94',
  eventBorder: '#265985',
  eventBorderSelected: '#265985',

  eventOutline: '#3b99fc',
  eventColor: '#fff',
  eventBorderRadius: '5px',
  eventPadding: '2px 5px',
  eventZindex: '4',
  eventMinWidth: '140px',
  eventMinHeight: '40px',

  btnColor: '#373a3c',
  btnBg: '#fff',
  btnBorder: '#',

  btnBgHover: '#E6E6E6',
  btnBorderHover: '#E6E6E6',

  btnBgSelected: '#BFBFBF',
  btnBorderSelected: '#8C8C8C',

  currentTimeColor: '#74ad31',

  todayHighlightBg: 'eaf6ff',

  resourcesFontColor: 'grey',
  resourcesFontFamily: 'Roboto, Verdana, Geneva, Tahoma, sans-serif',
  resourcesFontWeight: 'medium',
  activeBackground: '#E6E6E6',
  activeBorder: '#ADADAD',
};

export function GenerateCss(opts?: StyleOptions) {
  const styles = { ...DefaultStyles, ...opts };
  return css`
    /****** Reset CSS View *******/

    .rbc-btn {
      color: inherit;
      font: inherit;
      margin: 0;
    }

    button.rbc-btn {
      overflow: visible;
      text-transform: none;
      cursor: pointer;
    }

    button[disabled].rbc-btn {
      cursor: not-allowed;
    }

    button.rbc-input::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    /***** Styles CSS View */

    .rbc-calendar {
      box-sizing: border-box;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .rbc-calendar *,
    .rbc-calendar *:before,
    .rbc-calendar *:after {
      box-sizing: inherit;
    }

    .rbc-abs-full {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .rbc-ellipsis {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .rbc-rtl {
      direction: rtl;
    }

    .rbc-off-range {
      color: ${styles.outOfRangeColor};
    }

    .rbc-off-range-bg {
      background: ${styles.outOfRangeBgColor};
    }

    .rbc-header {
      overflow: hidden;
      flex: 1 0 0%;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 20px 3px;
      text-align: center;
      vertical-align: middle;
      font-family: ${styles.resourcesFontFamily};
      color: ${styles.resourcesFontColor};
      font-weight: ${styles.resourcesFontWeight};
      min-height: 0;
      border-bottom: 0px solid ${styles.cellBorder};

      & + & {
        border-left: 1px solid ${styles.cellBorder};
      }

      .rbc-rtl & + & {
        border-left-width: 0;
        border-right: 1px solid ${styles.cellBorder};
      }

      & > a {
        &,
        &:active,
        &:visited {
          color: inherit;
          text-decoration: none;
        }
      }
    }

    .rbc-button-link {
      color: inherit;
      background: none;
      margin: 0;
      padding: 0;
      border: none;
      cursor: pointer;
      user-select: text;
    }

    .rbc-row-content {
      position: relative;
      user-select: none;
      -webkit-user-select: none;
      z-index: 4;
    }

    .rbc-row-content-scrollable {
      display: flex;
      flex-direction: column;
      height: 100%;

      .rbc-row-content-scroll-container {
        height: 100%;
        overflow-y: scroll;

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }

    .rbc-today {
      background-color: ${styles.todayHighlightBg};
    }

    /******** Toolbar CSS View **********/

    .rbc-toolbar {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      font-size: 16px;

      .rbc-toolbar-label {
        flex-grow: 1;
        padding: 0 10px;
        text-align: center;
      }

      & button {
        color: ${styles.btnColor};
        display: inline-block;
        margin: 0;
        text-align: center;
        vertical-align: middle;
        background: none;
        background-image: none;
        border: 1px solid ${styles.btnBorder};
        padding: 0.375rem 1rem;
        border-radius: 4px;
        line-height: normal;
        white-space: nowrap;

        &:active,
        &.rbc-active {
          background-image: none;
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          background-color: ${styles.activeBackground};
          border-color: ${styles.activeBorder};

          &:hover,
          &:focus {
            color: ${styles.btnColor};
            background-color: ${styles.btnBgHover};
            border-color: ${styles.btnBorderHover};
          }
        }

        &:focus {
          color: ${styles.btnColor};
          background-color: ${styles.activeBackground};
          border-color: ${styles.activeBorder};
        }

        &:hover {
          color: ${styles.btnColor};
          background-color: ${styles.activeBackground};
          border-color: ${styles.activeBorder};
        }
      }
    }

    .rbc-btn-group {
      display: inline-block;
      white-space: nowrap;

      > button:first-of-type:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      > button:last-child:not(:first-of-type) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .rbc-rtl & > button:first-of-type:not(:last-child) {
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .rbc-rtl & > button:last-child:not(:first-of-type) {
        border-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      > button:not(:first-of-type):not(:last-child) {
        border-radius: 0;
      }

      button + button {
        margin-left: -1px;
      }

      .rbc-rtl & button + button {
        margin-left: 0;
        margin-right: -1px;
      }

      & + &,
      & + button {
        margin-left: 10px;
      }
    }

    /******* Agenda CSS View *******/

    .rbc-agenda-view {
      display: flex;
      flex-direction: column;
      flex: 1 0 0;
      overflow: auto;

      table.rbc-agenda-table {
        width: 100%;
        border: 1px solid ${styles.cellBorder};
        border-spacing: 0;
        border-collapse: collapse;

        tbody > tr > td {
          padding: 5px 10px;
          vertical-align: top;
        }

        .rbc-agenda-time-cell {
          padding-left: 15px;
          padding-right: 15px;
          text-transform: lowercase;
        }

        tbody > tr > td + td {
          border-left: 1px solid ${styles.cellBorder};
        }

        .rbc-rtl & {
          tbody > tr > td + td {
            border-left-width: 0;
            border-right: 1px solid ${styles.cellBorder};
          }
        }

        tbody > tr + tr {
          border-top: 1px solid ${styles.cellBorder};
        }

        thead > tr > th {
          padding: 3px 5px;
          text-align: left;
          border-bottom: 1px solid ${styles.cellBorder};

          .rbc-rtl & {
            text-align: right;
          }
        }
      }
    }

    .rbc-agenda-time-cell {
      text-transform: lowercase;

      .rbc-continues-after:after {
        content: ' »';
      }
      .rbc-continues-prior:before {
        content: '« ';
      }
    }

    .rbc-agenda-date-cell,
    .rbc-agenda-time-cell {
      white-space: nowrap;
    }

    .rbc-agenda-event-cell {
      width: 100%;
    }

    /********* Month CSS View **********/

    .rbc-row {
      display: flex;
      flex-direction: row;
    }

    .rbc-row-segment {
      padding: 0 1px 1px 1px;

      .rbc-event-content {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .rbc-selected-cell {
      background-color: ${styles.dateSelectionBgColor};
    }

    .rbc-show-more {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: rgba(255, 255, 255, 0.3);
      z-index: ${styles.eventZindex};
      font-weight: bold;
      font-size: 85%;
      height: auto;
      line-height: normal;
      color: ${styles.eventBg};
      &:hover,
      &:focus {
        color: ${styles.eventBgSelected};
        border-color: ${styles.eventBorderSelected};
      }
    }

    .rbc-month-view {
      position: relative;
      border: 1px solid ${styles.calendarBorder};
      display: flex;
      flex-direction: column;
      flex: 1 0 0;
      width: 100%;
      user-select: none;
      -webkit-user-select: none;

      height: 100%; // ie-fix
    }

    .rbc-month-header {
      display: flex;
      flex-direction: row;
    }

    .rbc-month-row {
      display: flex;
      position: relative;
      flex-direction: column;
      flex: 1 0 0; // postcss will remove the 0px here hence the duplication below
      flex-basis: 0px;
      overflow: hidden;

      height: 100%; // ie-fix

      & + & {
        border-top: 1px solid ${styles.cellBorder};
      }
    }

    .rbc-date-cell {
      flex: 1 1 0;
      min-width: 0;
      padding-right: 5px;
      text-align: right;

      &.rbc-now {
        font-weight: bold;
      }

      > a {
        &,
        &:active,
        &:visited {
          color: inherit;
          text-decoration: none;
        }
      }
    }

    .rbc-row-bg {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      flex: 1 0 0;
      overflow: hidden;
    }

    .rbc-day-bg {
      flex: 1 0 0%;

      & + & {
        border-left: 1px solid ${styles.cellBorder};
      }

      .rbc-rtl & + & {
        border-left-width: 0;
        border-right: 1px solid ${styles.cellBorder};
      }
    }

    .rbc-overlay {
      position: absolute;
      z-index: ${styles.eventZindex} + 1;
      border: 1px solid #e5e5e5;
      background-color: #fff;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
      padding: 10px;

      > * + * {
        margin-top: 1px;
      }
    }

    .rbc-overlay-header {
      border-bottom: 1px solid #e5e5e5;
      margin: -10px -10px 5px -10px;
      padding: 2px 10px;
    }

    /** Event CSS View */

    .rbc-event {
      border: none;
      box-sizing: border-box;
      box-shadow: none;
      margin: 0;
      padding: ${styles.eventPadding};
      background-color: ${styles.eventBg};
      border-radius: ${styles.eventBorderRadius};
      font-family: ${styles.resourcesFontFamily};
      border-color: ${styles.eventBorder};
      color: ${styles.eventColor};
      cursor: pointer;
      width: 100%;
      text-align: left;

      .rbc-slot-selecting & {
        cursor: inherit;
        pointer-events: none;
      }

      &.rbc-selected {
        background-color: ${styles.eventBgSelected};
        border-color: ${styles.eventBorderSelected};
      }

      &:focus {
        outline: 5px auto ${styles.eventOutline};
      }
    }

    .rbc-event-label {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 80%;
    }

    .rbc-event-overlaps {
      box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
    }

    .rbc-event-continues-prior {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .rbc-event-continues-after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .rbc-event-continues-earlier {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .rbc-event-continues-later {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    /********** Toolbar CSS View ***********/

    .rbc-toolbar {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      font-size: 16px;

      .rbc-toolbar-label {
        flex-grow: 1;
        padding: 0 10px;
        text-align: center;
      }

      & button {
        color: ${styles.btnColor};
        display: inline-block;
        margin: 0;
        text-align: center;
        vertical-align: middle;
        background: none;
        background-image: none;
        border: 1px solid ${styles.btnBorder};
        padding: 0.375rem 1rem;
        border-radius: 4px;
        line-height: normal;
        white-space: nowrap;

        &:active,
        &.rbc-active {
          background-image: none;
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          background-color: ${styles.activeBackground};
          border-color: ${styles.activeBorder};

          &:hover,
          &:focus {
            color: ${styles.btnColor};
            background-color: ${styles.btnBgSelected};
            border-color: ${styles.btnBorderHover};
          }
        }

        &:focus {
          color: ${styles.btnColor};
          background-color: ${styles.activeBackground};
          border-color: ${styles.activeBorder};
        }

        &:hover {
          color: ${styles.btnColor};
          background-color: ${styles.activeBackground};
          border-color: ${styles.activeBorder};
        }
      }
    }

    .rbc-btn-group {
      display: inline-block;
      white-space: nowrap;

      > button:first-of-type:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      > button:last-child:not(:first-of-type) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .rbc-rtl & > button:first-of-type:not(:last-child) {
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .rbc-rtl & > button:last-child:not(:first-of-type) {
        border-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      > button:not(:first-of-type):not(:last-child) {
        border-radius: 0;
      }

      button + button {
        margin-left: -1px;
      }

      .rbc-rtl & button + button {
        margin-left: 0;
        margin-right: -1px;
      }

      & + &,
      & + button {
        margin-left: 10px;
      }
    }

    /*************** Time Grid View ************/

    .rbc-slot-selection {
      z-index: 10;
      position: absolute;
      background-color: ${styles.timeSelectionBgColor};
      color: ${styles.timeSelectionColor};
      font-size: 75%;
      width: 100%;
      padding: 3px;
    }

    .rbc-slot-selecting {
      cursor: move;
    }

    .rbc-time-view {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      border-top: 1px solid ${styles.calendarBorder};
      min-height: 0;

      .rbc-time-gutter {
        white-space: nowrap;
        text-align: right;
      }

      .rbc-allday-cell {
        display: none;
      }
      .rbc-allday-cell + .rbc-allday-cell {
        border-left: 0px solid ${styles.cellBorder};
      }

      .rbc-allday-events {
        position: relative;
        z-index: 4;
      }

      .rbc-row {
        box-sizing: border-box;
        min-height: 20px;
      }
    }

    .rbc-time-header {
      display: flex;
      flex: 0 0 auto; // should not shrink below height
      flex-direction: row;

      &.rbc-overflowing {
        border-right: 1px solid ${styles.cellBorder};
      }

      .rbc-rtl &.rbc-overflowing {
        border-right-width: 0;
        border-left: 1px solid ${styles.cellBorder};
      }

      > .rbc-row:first-of-type {
        border-bottom: 1px solid ${styles.cellBorder};
      }

      > .rbc-row.rbc-row-resource {
        border-bottom: 1px solid ${styles.cellBorder};
      }

      // .rbc-gutter-cell {
      //   flex: none;
      // }

      // > .rbc-gutter-cell + * {
      //   width: 100%;
      // }
    }

    .rbc-time-header-cell-single-day {
      display: none;
    }

    .rbc-time-header-content {
      flex: 1;
      display: flex;
      min-width: 0;
      flex-direction: column;
      /* border-left: 1px solid ${styles.cellBorder}; */

      .rbc-rtl & {
        border-left-width: 0;
        border-right: 1px solid ${styles.cellBorder};
      }

      > .rbc-row.rbc-row-resource {
        border-bottom: 0px solid ${styles.cellBorder};
        flex-shrink: 0;
      }
    }

    .rbc-time-content {
      display: flex;
      flex: 1 0 0%;
      align-items: flex-start;
      width: 100%;
      border-top: 2px solid ${styles.calendarBorder};
      overflow-y: auto;
      position: relative;

      > .rbc-time-gutter {
        flex: none;
      }

      > * + * > * {
        border-left: 1px solid ${styles.cellBorder};
      }

      .rbc-rtl & > * + * > * {
        border-left-width: 0;
        border-right: 1px solid ${styles.cellBorder};
      }

      > .rbc-day-slot {
        width: 100%;
        user-select: none;
        -webkit-user-select: none;
      }
    }

    .rbc-current-time-indicator {
      position: absolute;
      z-index: 3;
      left: 0;
      right: 0;
      height: 1px;

      background-color: ${styles.currentTimeColor};
      pointer-events: none;
    }

    /********** Time Column View ***********/

    .rbc-time-column {
      display: flex;
      flex-direction: column;
      min-height: 100%;

      .rbc-timeslot-group {
        flex: 1;
        border-right: 1px solid ${styles.cellBorder};
      }
    }

    .rbc-timeslot-group {
      border-bottom: 1px solid ${styles.cellBorder};
      min-height: ${styles.eventMinHeight};
      display: flex;
      flex-flow: column nowrap;
    }

    .rbc-time-gutter,
    .rbc-header-gutter {
      flex: none;
    }

    .rbc-label {
      padding: 0 5px;
      font-size: ${styles.timeGutterFontSize};
      color: ${styles.timeGutterColor};
    }

    .rbc-day-slot {
      position: relative;

      .rbc-events-container {
        border: none;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        margin-right: 5px;
        margin-left: 5px;
        top: 0;

        &.rbc-rtl {
          left: 2px;
          right: 0;
        }
      }

      .rbc-event {
        border: 1px solid ${styles.eventBorder};
        display: flex;
        max-height: 100%;
        flex-flow: column wrap;
        align-items: flex-start;
        overflow: hidden;
        position: absolute;
      }

      .rbc-background-event {
        @extend .rbc-event;
        opacity: 0.75;
      }

      .rbc-event-label {
        flex: none;
        padding-right: 5px;
        width: auto;
      }

      .rbc-event-content {
        width: 100%;
        flex: 1 1 0;
        word-wrap: break-word;
        line-height: 1;
        height: 100%;
        min-height: 1em;
      }

      .rbc-time-slot {
        border-top: 1px solid ${styles.timeSlotBorder};
      }
    }

    .rbc-time-view-resources {
      .rbc-time-gutter,
      .rbc-time-header-gutter {
        position: sticky;
        left: 0;
        background-color: white;
        /* border-right: 1px solid ${styles.cellBorder}; */
        z-index: 10;
        margin-right: -1px;
      }

      .rbc-time-header {
        overflow: hidden;
      }

      .rbc-time-header-content {
        min-width: auto;
        flex: 1 0 0;
        flex-basis: 0px;
      }

      .rbc-time-header-cell-single-day {
        display: none;
      }

      .rbc-day-slot {
        min-width: ${styles.eventMinWidth};
      }

      .rbc-header,
      .rbc-day-bg {
        width: 140px;
        // min-width: 0;
        flex: 1 1 0;
        flex-basis: 0 px;
      }
    }

    .rbc-time-header-content + .rbc-time-header-content {
      margin-left: -1px;
    }

    .rbc-time-slot {
      flex: 1 0 0;

      &.rbc-now {
        font-weight: bold;
      }
    }

    .rbc-day-header {
      text-align: center;
    }
  `;
}
