import { createApi } from '@reduxjs/toolkit/query/react';
import { EventType } from '../Model/EventType';
import { baseQuery } from './BaseQuery';
import { ErrorTags, getErrorTag } from './Error';

export const eventTypesApi = createApi({
  reducerPath: 'eventTypesApi',
  baseQuery,
  tagTypes: [...ErrorTags, 'EventType'],
  endpoints: (build) => ({
    getEventTypes: build.query<EventType[], number>({
      query: (siteID: number) => `/sites/${siteID}/event-types`,
      providesTags: (result, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return result
          ? [
              ...result.map(
                ({ ID }) => ({ type: 'EventType', id: ID } as const)
              ),
              { type: 'EventType', id: 'LIST' } as const,
            ]
          : [{ type: 'EventType', id: 'LIST' } as const];
      },
    }),
    addEventType: build.mutation<EventType, EventType>({
      query: (eventType: EventType) => ({
        url: `/sites/${eventType.SiteID}/event-types`,
        method: 'POST',
        body: eventType,
      }),
      invalidatesTags: (result) => [
        { type: 'EventType', id: result?.ID },
        { type: 'EventType', id: 'LIST' },
      ],
    }),
    updateEventType: build.mutation<EventType, EventType>({
      query: (eventType: EventType) => ({
        url: `/sites/${eventType.SiteID}/event-types/${eventType.ID}`,
        method: 'PUT',
        body: eventType,
      }),
      invalidatesTags: (result) => [
        {
          type: 'EventType',
          id: result?.ID,
        },
      ],
    }),
    deleteEventType: build.mutation<EventType, EventType>({
      query: (eventType: EventType) => ({
        url: `/sites/${eventType.SiteID}/event-types/${eventType.ID}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [
        {
          type: 'EventType',
          id: result?.ID,
        },
      ],
    }),
  }),
});

export const {
  useGetEventTypesQuery,
  useAddEventTypeMutation,
  useUpdateEventTypeMutation,
  useDeleteEventTypeMutation,
} = eventTypesApi;
