import { Add } from '@mui/icons-material';
import { Divider, Fab, Stack, Tooltip, Typography } from '@mui/material';
import { Fragment, useContext, useState } from 'react';
import {
  HasSitePerms,
  PermAddSiteMemberType,
  PermsContext,
} from '../../Contexts/PermissionsContext';
import { SiteContext } from '../../Contexts/SiteContext';
import { useGetMemberTypesQuery } from '../../Service/MemberTypes';
import { AddEditMemberTypeDialog } from './AddEditMemberTypeDialog';
import { MemberTypeAccordion } from './MemberTypeAccordion';

export const ManageMembersPage = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { perms } = useContext(PermsContext);

  const siteID = useContext(SiteContext).site.id;
  const addMemberTypeDialog = (): void => {
    setDialogOpen(true);
  };

  const { data: memberTypes } = useGetMemberTypesQuery(siteID);

  return (
    <Fragment>
      <Stack spacing={2}>
        <Stack spacing={3} alignItems="center" direction="row">
          {HasSitePerms(perms, siteID, PermAddSiteMemberType) && (
            <Tooltip title="Add Member Type">
              <Fab
                size="small"
                onClick={addMemberTypeDialog}
                color="primary"
                aria-label="add-member-type"
              >
                <Add />
              </Fab>
            </Tooltip>
          )}
          <Typography variant="h6">Manage Member Types</Typography>
        </Stack>
        <Divider />
        <Stack>
          {memberTypes?.map((memberType) => {
            return (
              <MemberTypeAccordion
                key={memberType.ID}
                memberType={memberType}
              />
            );
          })}
        </Stack>
      </Stack>
      <AddEditMemberTypeDialog
        title="Add Member Type"
        open={dialogOpen}
        setOpen={setDialogOpen}
      ></AddEditMemberTypeDialog>
    </Fragment>
  );
};
