import { createApi } from '@reduxjs/toolkit/query/react';
import { KB } from '../Model/KB';
import { baseQuery } from './BaseQuery';
import { ErrorTags, getErrorTag } from './Error';

export const kbApi = createApi({
  reducerPath: 'kbApi',
  baseQuery,
  tagTypes: [...ErrorTags, 'KB'],
  endpoints: (build) => ({
    getKB: build.query<KB, void>({
      query: () => '/kb',
      transformResponse: (response: KB) => {
        response.Countries = response.Countries.sort((a, b) =>
          a.Name.localeCompare(b.Name)
        );
        return response;
      },
      providesTags: (_, error) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return ['KB'];
      },
    }),
  }),
});

export const { useGetKBQuery } = kbApi;
