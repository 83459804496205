import { Add, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Fab,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useContext, useState } from 'react';
import { ConfirmDialog } from '../../Components/Dialog/ConfirmDialog';
import {
  HasSitePerms,
  PermDeleteSiteSlotType,
  PermEditSiteSlotType,
  PermsContext,
} from '../../Contexts/PermissionsContext';
import { isExpanded, setExpanded } from '../../Contexts/UIState';
import { NewBookingRule } from '../../Model/BookingRule';
import { SlotType } from '../../Model/SlotType';
import { useGetBookingRulesQuery } from '../../Service/BookingRules';
import { useDeleteSlotTypeMutation } from '../../Service/SlotTypes';
import { getErrorMessage } from '../../Util/Error';
import { AddEditSlotTypeDialog } from './AddEditSlotTypeDialog';
import { AddEditRuleDialog } from './Rules/AddEditRuleDialog';
import { BookingRuleView } from './Rules/BookingRuleView';

type SlotTypeAccordionProps = {
  slotType: SlotType;
  onDelete?: (slotTypeID: number) => void;
  onSlotTypeSaved?: (slotType: SlotType) => void;
};

export const SlotTypeAccordion = (props: SlotTypeAccordionProps) => {
  const { perms } = useContext(PermsContext);

  const [openDeleteConfirm, setDeleteOpenConfirm] = useState(false);
  const [error, setError] = useState<string>();

  const siteId = props.slotType.SiteID;

  const [deleteSlotType] = useDeleteSlotTypeMutation();
  const handleDeleteConfirm = async (): Promise<void> => {
    try {
      await deleteSlotType(props.slotType);
    } catch (e) {
      const msg = getErrorMessage(e);
      setError('Error deleting slot type. ' + msg);
    }
  };

  const handleOpenDeleteDialog = (): void => {
    setDeleteOpenConfirm(true);
  };

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const handleOpenEditDialog = (): void => {
    setOpenEditDialog(true);
  };

  const [openBookingRuleDialog, setOpenBookingRuleDialog] = useState(false);

  const { data: bookingRules } = useGetBookingRulesQuery(siteId);
  const slotTypeRules = bookingRules?.filter(
    (br) => br.SlotTypeID === props.slotType.ID
  );

  const expansionKey = `slotType-${props.slotType.ID}`;
  const expanded = isExpanded(expansionKey);
  const handleOnExpansionChange = (
    _event: SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => {
    setExpanded(expansionKey, expanded);
  };

  return (
    <>
      <Accordion defaultExpanded={expanded} onChange={handleOnExpansionChange}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Stack
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
            justifyContent="space-between"
            direction="row"
            spacing={1}
          >
            <Typography variant="body1">{props.slotType.Name}</Typography>
            {props.slotType.IsRestrictive && (
              <Typography variant="body2">Restrictive</Typography>
            )}
            <Box
              sx={{
                width: '1rem',
                height: '1rem',
                borderRadius: '0.5rem',
                backgroundColor: `${props.slotType.Color}`,
              }}
            />
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={1}
                justifyContent={'flex-start'}
              >
                <Tooltip title="Add Booking Rule">
                  <Fab
                    variant="extended"
                    size="small"
                    onClick={() => setOpenBookingRuleDialog(true)}
                    color="primary"
                  >
                    <Add />
                  </Fab>
                </Tooltip>
                <Typography variant="body1">Add Booking Rule</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} my={2}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography>{props.slotType.Description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color={'error'}>{error}</Typography>
            </Grid>
            {slotTypeRules?.map((rule) => (
              <Grid item xs={12} sm={4} key={rule.ID}>
                <BookingRuleView slotType={props.slotType} rule={rule} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          {HasSitePerms(perms, siteId, PermEditSiteSlotType) && (
            <Button onClick={handleOpenEditDialog}>Edit</Button>
          )}
          {HasSitePerms(perms, siteId, PermDeleteSiteSlotType) && (
            <Button onClick={handleOpenDeleteDialog} color="error">
              Delete
            </Button>
          )}
        </AccordionActions>
      </Accordion>
      <ConfirmDialog
        open={openDeleteConfirm}
        setOpen={setDeleteOpenConfirm}
        title="Delete Slot Type"
        message="Are you sure you want to delete this slot type?"
        onConfirm={handleDeleteConfirm}
        danger
        confirmText="Delete"
      />
      <AddEditSlotTypeDialog
        slotType={props.slotType}
        title="Edit Slot Type"
        open={openEditDialog}
        setOpen={setOpenEditDialog}
      />
      <AddEditRuleDialog
        open={openBookingRuleDialog}
        rule={NewBookingRule(0, props.slotType.ID)}
        onClose={() => setOpenBookingRuleDialog(false)}
        setOpen={setOpenBookingRuleDialog}
      />
    </>
  );
};
