import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { Dispatch, useState } from 'react';
import { EventDetailsDialog } from '../../Components/Booking/EventDetailsDialog';
import { Event } from '../../Model/Event';
export type Props = {
  event?: Event;
  onPaymentRequested?: Dispatch<Event | undefined>;
};
export const BookingCard = ({ event, onPaymentRequested }: Props) => {
  const [open, setOpen] = useState(false);

  const handleViewDetails = () => {
    setOpen(true);
  };

  const startTime = DateTime.fromISO(event?.Start || '');

  const formattedDate = startTime.toFormat('EEE, d MMM');
  const formattedTime = startTime.toFormat('HH:mm');

  return (
    <>
      <Paper>
        <Card>
          <CardHeader title={`Booking ${event?.Booking?.ID}`} />
          <CardContent>
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item xs={12}>
                <Typography variant="body1">{event?.Title}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Date</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{formattedDate}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Time</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{formattedTime}</Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center' }}>
            <Button onClick={handleViewDetails}>View Details</Button>
          </CardActions>
        </Card>
      </Paper>
      <EventDetailsDialog
        event={event}
        open={open}
        onPaymentRequested={onPaymentRequested}
        setOpen={setOpen}
      />
    </>
  );
};
