import {
  GetPeriodTypeMinutes,
  GetPeriodTypeName,
  PeriodType,
} from './PeriodType';

export type TimeRangeAnchor = {
  ID: number;
  Name: string;
};

export enum AnchorType {
  None = 0,
  BeforeStartOfDay = 1,
  AfterStartOfDay = 2,
  BeforeSlotStart = 3,
}

export const Anchors: AnchorType[] = [
  AnchorType.None,
  AnchorType.BeforeStartOfDay,
  AnchorType.AfterStartOfDay,
  AnchorType.BeforeSlotStart,
];

export function GetAnchorString(anchor: AnchorType): string {
  switch (anchor) {
    case AnchorType.None:
      return 'None';
    case AnchorType.BeforeStartOfDay:
      return 'before start of slot day';
    case AnchorType.AfterStartOfDay:
      return 'after start of slot day';
    case AnchorType.BeforeSlotStart:
      return 'before slot start';
    default:
      throw new Error('Unknown anchor type');
  }
}

export type TimeRange = {
  AnchorType: AnchorType;
  StartPeriodType: PeriodType;
  StartPeriodValue: number;
  EndPeriodType: PeriodType;
  EndPeriodValue: number;
};

export function NewTimeRange(): TimeRange {
  return {
    AnchorType: AnchorType.None,
    StartPeriodType: PeriodType.None,
    StartPeriodValue: 0,
    EndPeriodType: PeriodType.None,
    EndPeriodValue: 0,
  };
}

export function Validate(timeRange?: TimeRange): string | undefined {
  if (!timeRange || timeRange.AnchorType === AnchorType.None) {
    return;
  }

  if (timeRange.StartPeriodType === PeriodType.None) {
    return;
  }

  if (timeRange.EndPeriodType === PeriodType.None) {
    return;
  }

  const startMinutes =
    GetPeriodTypeMinutes(timeRange.StartPeriodType) *
    timeRange.StartPeriodValue;
  const endMinutes =
    GetPeriodTypeMinutes(timeRange.EndPeriodType) * timeRange.EndPeriodValue;

  switch (timeRange.AnchorType) {
    case AnchorType.BeforeStartOfDay:
    case AnchorType.BeforeSlotStart:
      if (startMinutes <= endMinutes) {
        return 'Start must be before end';
      }
      break;
    case AnchorType.AfterStartOfDay:
      if (startMinutes >= endMinutes) {
        return 'Start must be before end';
      }
      break;
  }
}

export function StartTimeToString(timeRange?: TimeRange): string {
  if (
    !timeRange ||
    timeRange.AnchorType === AnchorType.None ||
    timeRange.StartPeriodType === PeriodType.None
  ) {
    return 'Unrestricted';
  }

  let result = '';
  const periodTypeName = GetPeriodTypeName(timeRange.StartPeriodType);
  result +=
    timeRange.StartPeriodValue +
    ' ' +
    periodTypeName.toLowerCase() +
    '(s) ' +
    GetAnchorString(timeRange.AnchorType);

  return result;
}

export function EndTimeToString(timeRange?: TimeRange): string {
  if (
    !timeRange ||
    timeRange.AnchorType === AnchorType.None ||
    timeRange.EndPeriodType === PeriodType.None
  ) {
    return 'Unrestricted';
  }

  let result = '';
  let periodTypeName = 'up to';
  periodTypeName = GetPeriodTypeName(timeRange.EndPeriodType);
  periodTypeName += '(s)';
  result +=
    timeRange.EndPeriodValue +
    ' ' +
    periodTypeName.toLowerCase() +
    ' ' +
    GetAnchorString(timeRange.AnchorType);

  return result;
}
