import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { apiBaseURL } from '../Util/Api';

export const baseQuery = fetchBaseQuery({
  baseUrl: apiBaseURL(),
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});
