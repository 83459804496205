import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
} from '@mui/material';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { ColorPicker } from '../../Components/UI/ColorPicker';
import { SiteContext } from '../../Contexts/SiteContext';
import { EventType } from '../../Model/EventType';
import {
  useAddEventTypeMutation,
  useUpdateEventTypeMutation,
} from '../../Service/EventTypes';
import { getErrorMessage } from '../../Util/Error';

type Props = {
  eventType?: EventType;
  open: boolean;
  setOpen: (open: boolean) => void;
};
export const AddEditEventTypeDialog = (props: Props) => {
  const schema = Yup.object().shape({
    ID: Yup.number(),
    Name: Yup.string().required('Name is required'),
    Description: Yup.string(),
    Color: Yup.string(),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    register,
  } = useForm({
    defaultValues: {
      ID: 0,
      Name: '',
      Description: '',
      Color: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (props.eventType) {
      reset({
        Name: props.eventType.Name,
        Description: props.eventType.Description,
        Color: props.eventType.Color,
      });
    } else {
      reset({
        Name: '',
        Description: '',
        Color: '',
      });
    }
  }, [props.eventType, reset]);

  const siteID = useContext(SiteContext).site.id;
  const [updateEventType, { isLoading: isUpdating }] =
    useUpdateEventTypeMutation();
  async function handlePutEventType(data: any) {
    try {
      await updateEventType({
        ...data,
        SiteID: siteID,
      }).unwrap();
      props.setOpen(false);
    } catch (e: any) {
      const msg = getErrorMessage(e);
      setError('root', { message: msg });
    }
  }

  const [addEventType, { isLoading: isAdding }] = useAddEventTypeMutation();

  const isSaving = isAdding || isUpdating;
  async function handlePostEventType(formData: any) {
    try {
      await addEventType({
        ...formData,
        SiteID: siteID,
      }).unwrap();
      props.setOpen(false);
    } catch (e: any) {
      const msg = getErrorMessage(e);
      setError('root', { message: msg });
    }
  }

  const onSubmit = (data: any) => {
    console.debug('save event type', data);
    if (props.eventType) {
      handlePutEventType(data);
    } else {
      handlePostEventType(data);
    }
  };

  return (
    <Dialog open={props.open} onClose={() => props.setOpen(false)}>
      <DialogTitle textAlign={'center'}>
        {props.eventType ? 'Edit' : 'Add'} Event Type
      </DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <input
          type="hidden"
          {...register('ID')}
          value={props.eventType?.ID || 0}
        />
        <DialogContent>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel>Name</InputLabel>
              <Controller
                name="Name"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    label="Name"
                    error={Boolean(errors.Name)}
                  />
                )}
              />
              <FormHelperText>{errors.Name?.message}</FormHelperText>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Description</InputLabel>
              <Controller
                name="Description"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    label="Description"
                    error={Boolean(errors.Description)}
                  />
                )}
              />
              <FormHelperText>{errors.Description?.message}</FormHelperText>
            </FormControl>
            <FormControl fullWidth error={!!errors.Color}>
              <Controller
                name="Color"
                control={control}
                render={({ field }) => <ColorPicker {...field} label="Color" />}
              />
              <FormHelperText>{errors.Color?.message}</FormHelperText>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isSaving} type="submit" color="primary">
            Save
          </LoadingButton>
          <Button color="inherit" onClick={() => props.setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
