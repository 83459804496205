import {
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import {
  NumberFormatValues,
  NumericFormat,
  SourceInfo,
} from 'react-number-format';

type NumberInputExtendedProps = {
  value?: number;
  onChange?: (value?: number) => void;
  onUnlimitedClick?: (value: boolean) => void;
  unlimitedValue?: number;
  resetValue?: number;
  min?: number;
  max?: number;
  variant?: 'outlined' | 'contained';
  label?: string;
};
export const NumberInputExtended = (props: NumberInputExtendedProps) => {
  const [unlimited, setUnlimited] = useState(
    props.unlimitedValue !== undefined && props.value === props.unlimitedValue
  );

  function handleSwitchClick(event: ChangeEvent<HTMLInputElement>): void {
    setUnlimited(event.target.checked);

    if (props.onUnlimitedClick) {
      props.onUnlimitedClick(event.target.checked);
    }
  }

  function handleQuantityChange(
    values: NumberFormatValues,
    _sourceInfo: SourceInfo
  ): void {
    if (values.floatValue === undefined) {
      return;
    }

    if (props.min && values.floatValue < props.min) {
      return;
    }

    if (props.max && values.floatValue > props.max) {
      return;
    }

    if (props.onChange) {
      props.onChange(values.floatValue);
    }
  }

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      textAlign={'center'}
      spacing={2}
      border={'1px solid lightgray'}
      px={'1rem'}
      borderRadius={'0.5rem'}
    >
      <NumericFormat
        label={props.label}
        value={unlimited ? props.unlimitedValue : props.value}
        onValueChange={handleQuantityChange}
        customInput={TextField}
        variant="standard"
        disabled={props.unlimitedValue === props.value}
      />
      {props.onUnlimitedClick && (
        <FormControl>
          <FormControlLabel
            labelPlacement="bottom"
            componentsProps={{
              typography: { color: 'text.secondary', variant: 'caption' },
            }}
            control={
              <Switch checked={unlimited} onChange={handleSwitchClick} />
            }
            label="Unlimited"
          />
        </FormControl>
      )}
    </Stack>
  );
};
