import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import axios from 'axios';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import * as yup from 'yup';
import { toApiUrl } from '../../Util/Api';
import { getErrorMessage } from '../../Util/Error';

export const SendEmailConfirmation = () => {
  const schema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    console.log('handleSendEmail', data);

    try {
      const res = await axios.post(toApiUrl('/auth/send-confirmation-token'), {
        email: data.email,
      });

      if (res.status === 208) {
        setError('root', { message: 'Email already confirmed' });
      }
    } catch (e: any) {
      console.log('while sending email confirmation', e);
      const msg = getErrorMessage(e);
      setError('root', { message: msg });
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} textAlign={'center'}>
        <Grid item xs={12}>
          <Typography variant="h6">Email Confirmation</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <p>
              To be able to login and begin using our platform, you'll need to
              confirm your email address.
            </p>
            <p>Please enter your email below and click 'Send Email'.</p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {!isSubmitSuccessful && (
            <FormControl
              fullWidth
              sx={{ marginTop: 1, marginBottom: 1 }}
              error={!!errors.email}
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    id="email"
                    label="Email"
                    type="email"
                    autoComplete="email"
                    autoFocus
                  />
                )}
              />
              <FormHelperText
                error
                id="standard-weight-helper-text-email-confirm"
              >
                {errors.email?.message}
              </FormHelperText>
            </FormControl>
          )}
        </Grid>
        {isSubmitSuccessful && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" textAlign={'center'}>
              If you have an account with us, an email has been sent.
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="subtitle2" textAlign={'center'} color={'error'}>
            {errors.root?.message}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display={'flex'} sx={{ mt: 2 }} justifyContent={'center'}>
            {!isSubmitSuccessful && (
              <Button fullWidth size="large" variant="contained" type="submit">
                Send Link
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
