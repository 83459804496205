import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Fragment, SyntheticEvent, useContext, useState } from 'react';
import { ConfirmDialog } from '../../Components/Dialog/ConfirmDialog';
import {
  HasSitePerms,
  PermDeleteSiteMemberType,
  PermEditSiteMemberType,
  PermsContext,
} from '../../Contexts/PermissionsContext';
import { SiteContext } from '../../Contexts/SiteContext';
import { isExpanded, setExpanded } from '../../Contexts/UIState';
import { NewBookingRule } from '../../Model/BookingRule';
import { MemberType } from '../../Model/MemberType';
import { PriorityType } from '../../Model/Priority';
import { useGetBookingRulesQuery } from '../../Service/BookingRules';
import { useDeleteMemberTypeMutation } from '../../Service/MemberTypes';
import { AddEditRuleDialog } from '../SlotTypes/Rules/AddEditRuleDialog';
import { BookingRuleView } from '../SlotTypes/Rules/BookingRuleView';
import { AddEditMemberTypeDialog } from './AddEditMemberTypeDialog';
import { ManageMembersGrid } from './ManageMembersGrid';

type MemberTypeAccordionProps = {
  memberType: MemberType;
};
export const MemberTypeAccordion = (props: MemberTypeAccordionProps) => {
  const { perms: permsState } = useContext(PermsContext);
  const [openDeleteMemberTypeConfirm, setDeleteMemberTypeConfirm] =
    useState(false);
  const [openEditMemberTypeDialog, setOpenEditMemberTypeDialog] =
    useState(false);
  const [deletionError, setDeletionError] = useState<string>();

  const [deleteMemberType] = useDeleteMemberTypeMutation();
  async function handleDeleteConfirm(): Promise<void> {
    try {
      await deleteMemberType({
        SiteID: props.memberType.SiteID,
        MemberTypeID: props.memberType.ID,
      });
    } catch (res) {
      console.log(res);
      setDeletionError('Error deleting member type. Please try again later');
    }
  }

  function handleOpenEditDialog(): void {
    setOpenEditMemberTypeDialog(true);
  }

  const [openAddEditRuleDialog, setOpenAddEditRuleDialog] = useState(false);
  function handleOpenAddRule(): void {
    setOpenAddEditRuleDialog(true);
  }

  const siteCtx = useContext(SiteContext);
  const { data: bookingRules } = useGetBookingRulesQuery(siteCtx.site.id);
  const memberTypeRules = bookingRules?.filter(
    (rule) => rule.MemberTypeID === props.memberType.ID && rule.SlotTypeID === 0
  );

  const expansionKey = 'member-type-' + props.memberType.ID;

  function handleOnExpansionChange(
    _event: SyntheticEvent<Element, Event>,
    expanded: boolean
  ): void {
    setExpanded(expansionKey, expanded);
  }

  const expanded = isExpanded(expansionKey);

  return (
    <Fragment>
      {deletionError && <Alert severity="error">{deletionError}</Alert>}
      <Accordion defaultExpanded={expanded} onChange={handleOnExpansionChange}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Stack
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
            direction="row"
            spacing={1}
            justifyContent={'space-between'}
          >
            <Typography variant="body1">{props.memberType.Name}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {props.memberType.Description}
              </Typography>
            </Grid>
            {memberTypeRules?.map((rule) => (
              <Grid item xs={12} key={rule.ID} sm={6}>
                <BookingRuleView rule={rule} />
              </Grid>
            ))}
            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                display={'flex'}
              >
                <Button onClick={handleOpenAddRule}>Add Booking Rule</Button>
              </Stack>
            </Grid>
            <Grid item my={2} xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <ManageMembersGrid memberType={props.memberType} />
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionActions sx={{ justifyContent: 'center' }}>
          <Stack direction={'row'} spacing={1}>
            {HasSitePerms(
              permsState,
              props.memberType.SiteID,
              PermEditSiteMemberType
            ) && (
              <Button onClick={handleOpenEditDialog}>Edit Member Type</Button>
            )}
            {HasSitePerms(
              permsState,
              props.memberType.SiteID,
              PermDeleteSiteMemberType
            ) && (
              <Button
                color="error"
                onClick={() => setDeleteMemberTypeConfirm(true)}
              >
                Delete Member Type
              </Button>
            )}
          </Stack>
        </AccordionActions>
      </Accordion>
      <ConfirmDialog
        open={openDeleteMemberTypeConfirm}
        setOpen={setDeleteMemberTypeConfirm}
        title="Confirm Delete Member Type"
        confirmText="Delete"
        danger
        message="Are you sure you want to delete this member type?"
        onConfirm={handleDeleteConfirm}
      />
      <AddEditMemberTypeDialog
        memberType={props.memberType}
        title="Edit Member Type"
        setOpen={setOpenEditMemberTypeDialog}
        open={openEditMemberTypeDialog}
      />
      <AddEditRuleDialog
        rule={NewBookingRule(props.memberType.ID, 0, PriorityType.MemberType)}
        setOpen={setOpenAddEditRuleDialog}
        open={openAddEditRuleDialog}
        onClose={() => setOpenAddEditRuleDialog(false)}
      />
    </Fragment>
  );
};
