import PropTypes from 'prop-types';

// material-ui
import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import MainCard from '../../../Components/UI/MainCard';

// ==============================|| AUTHENTICATION CARD WRAPPER ||============================== //

export type PaymentCardWrapperProps = {
  other?: any;
};
const PaymentCardWrapper = (
  props: PropsWithChildren<PaymentCardWrapperProps>
) => {
  const { children, other } = props;

  return (
    <MainCard
      elevation={2}
      sx={{
        maxWidth: { xs: 400, lg: 475 },
        margin: { xs: 2.5, md: 3 },
        '& > *': {
          flexGrow: 1,
          flexBasis: '50%',
        },
      }}
      content={false}
      {...other}
    >
      <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
    </MainCard>
  );
};

PaymentCardWrapper.propTypes = {
  children: PropTypes.node,
};

export default PaymentCardWrapper;
