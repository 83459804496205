import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { HasAnyGlobalPerm, PermsContext } from '../Contexts/PermissionsContext';

export function GlobalAdminRoute(props: {
  children: React.ReactNode;
}): JSX.Element {
  const permsCtx = useContext(PermsContext);
  if (!HasAnyGlobalPerm(permsCtx.perms)) {
    return <Navigate to="/" />;
  }

  return <>{props.children}</>;
}
