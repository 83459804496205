import { Button, ButtonGroup, Stack, Typography } from '@mui/material';

type NumberInputProps = {
  value?: number;
  label?: string;
  id?: string;
  min?: number;
  max?: number;
  resetValue?: number;
  disabled?: boolean;
  variant?: 'outlined' | 'contained';
  step?: number;
  onChange?: (quantity: number) => void;
};
export const NumberInput = (props: NumberInputProps) => {
  const step = props.step ? props.step : 1;
  const value = props.value ? props.value : 0;
  const handleQuantityPlus = () => {
    if (value === props.max) {
      return;
    }

    if (props.onChange) {
      props.onChange(value + step);
    }
  };

  const handleQuantityMinus = () => {
    if (value === props.min) {
      return;
    }

    if (props.onChange) {
      props.onChange(value - step);
    }
  };

  let variant = props.variant ? props.variant : 'contained';
  let valueVariant = 'contained';
  if (variant === 'contained') {
    valueVariant = 'outlined';
  }

  function handleReset(): void {
    if (props.resetValue !== undefined && props.onChange) {
      props.onChange(props.resetValue);
    }
  }

  return (
    <Stack direction="column" alignItems="center" spacing={0}>
      <Typography variant="body2">{props.label}</Typography>
      <Stack direction="row" alignItems="center" spacing={2}>
        <ButtonGroup
          disableElevation
          disabled={props.disabled}
          id={props.id}
          size="small"
          variant={variant}
          aria-label="quantity-input"
        >
          <Button onClick={handleQuantityMinus}>-</Button>
          <Button
            onClick={handleReset}
            variant={valueVariant as 'outlined' | 'contained'}
          >
            {props.value}
          </Button>
          <Button onClick={handleQuantityPlus}>+</Button>
        </ButtonGroup>
      </Stack>
    </Stack>
  );
};
