import { Close } from '@mui/icons-material';
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MouseEvent } from 'react';
import {
  MinutesToLabel,
  SlotBucket,
  SlotBucketEndMinutes,
} from '../../../Model/SlotBucket';
import { SlotType } from '../../../Model/SlotType';

type SlotBucketComponentProps = {
  bucket: SlotBucket;
  slotTypes?: SlotType[];
  onUpdate?: (bucket: SlotBucket) => void;
  onDeleteClicked?: () => void;
};

export const SlotBucketComponent = (props: SlotBucketComponentProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const bucket = props.bucket;
  const updateBucket = (updated: SlotBucket) => {
    if (props.onUpdate) {
      props.onUpdate(updated);
    }
  };

  function handleNumSlotsChange(
    _event: Event,
    newValue: number | number[]
  ): void {
    const value = Number(newValue);
    if (value < 1 || value > 50) {
      return;
    }
    updateBucket({
      ...bucket,
      NumSlots: value,
    });
  }

  function handleDurationChange(
    _event: Event,
    newValue: number | number[]
  ): void {
    const value = Number(newValue);
    if (value < 1 || value > 180) {
      return;
    }
    updateBucket({
      ...bucket,
      DurationMinutes: value,
    });
  }

  function handleDeleteClicked(_: MouseEvent<HTMLButtonElement>): void {
    if (props.onDeleteClicked) {
      props.onDeleteClicked();
    }
  }

  const startTimeLabel = MinutesToLabel(bucket.StartTimeMinutes);
  const endMinutes = SlotBucketEndMinutes(bucket);
  const endTimeLabel = MinutesToLabel(endMinutes);

  function handleSlotTypeChange(event: SelectChangeEvent<number>): void {
    updateBucket({
      ...bucket,
      SlotTypeID: Number(event.target.value),
    });
  }

  return (
    <Stack direction={'row'} spacing={1}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={3}
            justifyContent={'center'}
            alignContent={'center'}
          >
            <Typography variant="subtitle1">
              Start
              {isSmallScreen ? <br /> : ' '}
              {startTimeLabel}
            </Typography>
            <Stack
              sx={{ width: '100%' }}
              direction="column"
              alignItems="center"
              spacing={1}
            >
              <Typography pb={3} variant="subtitle1">
                Slots
              </Typography>
              <Slider
                aria-label="number-of-slots"
                defaultValue={1}
                min={1}
                valueLabelDisplay="on"
                max={10}
                value={bucket.NumSlots}
                onChange={handleNumSlotsChange}
              />
              <Typography pb={3} variant="subtitle1">
                Duration
              </Typography>
              <Slider
                aria-label="duration"
                defaultValue={90}
                min={10}
                step={10}
                valueLabelDisplay="on"
                max={180}
                valueLabelFormat={(value) => `${value} min`}
                value={bucket.DurationMinutes}
                onChange={handleDurationChange}
              />
            </Stack>
            <Typography variant="subtitle1">
              End
              {isSmallScreen ? <br /> : ' '}
              {endTimeLabel}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <FormControl sx={{ width: '50%' }}>
            <InputLabel id="slot-type-select-label">Slot Type</InputLabel>
            <Select
              color="primary"
              fullWidth
              value={bucket.SlotTypeID === 0 ? '' : bucket.SlotTypeID}
              label="Slot Type"
              onChange={handleSlotTypeChange}
            >
              {props.slotTypes?.map((slotType) => (
                <MenuItem key={slotType.ID} value={slotType.ID}>
                  {slotType.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <Divider variant="middle" sx={{ width: '25%' }} />
        </Grid>
      </Grid>
      <Box alignContent={'center'}>
        <IconButton color="error" size="small" onClick={handleDeleteClicked}>
          <Close />
        </IconButton>
      </Box>
    </Stack>
  );
};
