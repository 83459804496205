import { PriorityType } from './Priority';
import { PeriodType } from './Time/PeriodType';
import { AnchorType, TimeRange } from './Time/TimeRange';

export interface BookingRule {
  ID: number;
  MemberTypeID: number;
  SlotTypeID: number;
  GameTypeID: number;
  AmountsPriorityType: PriorityType;
  MaxSimultaneousBookings: number;
  NumFreePeriodType: PeriodType;
  NumFree: number;
  NumMaxPeriodType: PeriodType;
  NumMax: number;
  NumPaidPeriodType: PeriodType;
  NumPaid: number;
  PaidPrice: number;
  NumFreePublicInvitesPeriodType: PeriodType;
  NumFreePublicInvites: number;
  NumPaidPublicInvitesPeriodType: PeriodType;
  NumPaidPublicInvites: number;
  PaidInvitePrice: number;
  NumMaxPublicInvitesPeriodType: PeriodType;
  NumMaxPublicInvites: number;
  TimeRangePriorityType: PriorityType;
  BookingAnchorType: AnchorType;
  BookingStartPeriodType: PeriodType;
  BookingStartPeriodValue: number;
  BookingEndPeriodType: PeriodType;
  BookingEndPeriodValue: number;
  CancellationAnchorType: AnchorType;
  CancellationStartPeriodType: PeriodType;
  CancellationStartPeriodValue: number;
  CancellationEndPeriodType: PeriodType;
  CancellationEndPeriodValue: number;
}

export function ToCancellationTimeRange(br: BookingRule): TimeRange {
  return {
    AnchorType: br.CancellationAnchorType,
    StartPeriodType: br.CancellationStartPeriodType,
    StartPeriodValue: br.CancellationStartPeriodValue,
    EndPeriodType: br.CancellationEndPeriodType,
    EndPeriodValue: br.CancellationEndPeriodValue,
  };
}

export function ToBookingTimeRange(br: BookingRule): TimeRange {
  return {
    AnchorType: br.BookingAnchorType,
    StartPeriodType: br.BookingStartPeriodType,
    StartPeriodValue: br.BookingStartPeriodValue,
    EndPeriodType: br.BookingEndPeriodType,
    EndPeriodValue: br.BookingEndPeriodValue,
  };
}
export function NewBookingRule(
  memberTypeID: number = 0,
  slotTypeID: number = 0,
  amountsPriorityType: PriorityType = PriorityType.MemberType,
  timeRangePriorityType: PriorityType = PriorityType.MemberType
): BookingRule {
  return {
    ID: 0,
    SlotTypeID: slotTypeID,
    MemberTypeID: memberTypeID,
    GameTypeID: 0,
    AmountsPriorityType: amountsPriorityType,
    MaxSimultaneousBookings: -1,
    NumFreePeriodType: PeriodType.Month,
    NumFree: -1,
    NumPaidPeriodType: PeriodType.Month,
    NumPaid: 0,
    PaidPrice: 0,
    NumMaxPeriodType: PeriodType.Month,
    NumMax: -1,
    NumFreePublicInvitesPeriodType: PeriodType.Month,
    NumFreePublicInvites: -1,
    NumPaidPublicInvitesPeriodType: PeriodType.Month,
    NumPaidPublicInvites: 0,
    PaidInvitePrice: 0,
    NumMaxPublicInvitesPeriodType: PeriodType.Month,
    NumMaxPublicInvites: -1,
    TimeRangePriorityType: timeRangePriorityType,
    BookingAnchorType: AnchorType.None,
    BookingStartPeriodType: PeriodType.None,
    BookingStartPeriodValue: 0,
    BookingEndPeriodType: PeriodType.None,
    BookingEndPeriodValue: 0,
    CancellationAnchorType: AnchorType.None,
    CancellationStartPeriodType: PeriodType.None,
    CancellationStartPeriodValue: 0,
    CancellationEndPeriodType: PeriodType.None,
    CancellationEndPeriodValue: 0,
  };
}

export function ValidateMemberTypeRule(rule: BookingRule): any {
  const errors: any = {};

  if ((rule.NumFree ?? 0) < -1) {
    errors.NumFree = 'Please enter a valid number of free slots.';
  }

  if ((rule.NumPaid ?? 0) < -1) {
    errors.NumPaid = 'Please enter a valid number of paid slots.';
  }
  if ((rule.NumMax ?? 0) < -1) {
    errors.NumMax = 'Please enter a valid number of max slots.';
  }

  if ((rule.NumFreePublicInvites ?? 0) < -1) {
    errors.NumPublicInvites = 'Please enter a valid number of public invites.';
  }

  if (rule.GameTypeID > 0 && rule.NumPaid > 0 && rule.PaidPrice === 0) {
    errors.PaidPrice = 'Please enter a paid price for paid slots.';
  }

  if (
    rule.GameTypeID > 0 &&
    rule.NumPaidPublicInvites > 0 &&
    rule.PaidInvitePrice === 0
  ) {
    errors.PaidInvitePrice = 'Please enter a paid public invite price.';
  }

  if ((rule.NumPaidPublicInvites ?? 0) < -1) {
    errors.NumPaidPublicInvites =
      'Please enter a valid number of paid invites.';
  }

  if (
    rule.MemberTypeID === 0 &&
    rule.AmountsPriorityType === PriorityType.MemberType &&
    rule.TimeRangePriorityType === PriorityType.MemberType
  ) {
    const msg =
      "At least one of 'Amounts Priority' and 'Time Range Priority' must be set to SlotType.";
    errors.AmountsPriorityType = msg;
    errors.TimeRangePriorityType = msg;
  }

  return errors;
}

export function getRuleBySlotTypeID(
  slotTypeID: number,
  rules: BookingRule[]
): BookingRule | undefined {
  return rules.find((rule) => rule.SlotTypeID === slotTypeID);
}
