import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { SiteContext } from '../../Contexts/SiteContext';
import { Event } from '../../Model/Event';
import { useCreatePaymentOrderMutation } from '../../Service/Payments';
import { getErrorMessage } from '../../Util/Error';

enum PaymentStatus {
  Idle,
  CreatingOrder,
  Redirecting,
  Failed,
}

function isInProgress(status: PaymentStatus) {
  return (
    status === PaymentStatus.CreatingOrder ||
    status === PaymentStatus.Redirecting
  );
}

function getPaymentStatusMessage(status: PaymentStatus) {
  switch (status) {
    case PaymentStatus.Idle:
      return 'Waiting. Please wait...';
    case PaymentStatus.CreatingOrder:
      return 'Creating order. Do not close this page...';
    case PaymentStatus.Redirecting:
      return 'Redirecting to payment gateway. Do not close this page...';
    case PaymentStatus.Failed:
      return 'Payment Failed. Please try again later...';
  }
}

type Props = {
  open: boolean;
  onClose: () => void;
  event?: Event;
};

export const PaymentDialog = (props: Props) => {
  const [status, setStatus] = useState(PaymentStatus.Idle);

  const bookingID = props.event?.Booking?.ID || 0;
  const siteID = useContext(SiteContext).site.id;

  const [createPaymentOrder] = useCreatePaymentOrderMutation();

  const [error, setError] = useState<string>();

  const sendOrder = useCallback(async (): Promise<void> => {
    if (!props.open) {
      return;
    }

    try {
      setStatus(PaymentStatus.CreatingOrder);

      console.log('sending order', bookingID, siteID);
      const res = await createPaymentOrder({
        bookingID,
        siteID,
      }).unwrap();

      setStatus(PaymentStatus.Redirecting);
      window.location.href = res.PaymentURL;
    } catch (e) {
      setError(getErrorMessage(e));
      setStatus(PaymentStatus.Failed);
    }
  }, [createPaymentOrder, bookingID, siteID, props.open]);

  useEffect(() => {
    const timer = setTimeout(() => {
      sendOrder();
    }, 500);

    return () => clearTimeout(timer);
  }, [props.open, sendOrder]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle textAlign={'center'}>Payment</DialogTitle>
      <DialogContent>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} textAlign={'center'}>
            {isInProgress(status) && <CircularProgress />}
          </Grid>
          <Grid item xs={12} textAlign={'center'}>
            <Typography variant={'h6'}>
              {getPaymentStatusMessage(status)}
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign={'center'} mt={2}>
            <Typography color={'error'} variant={'body1'}>
              {error}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
