import { Global } from '@emotion/react';
import { Grid, LinearProgress, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { Fragment, useCallback, useContext, useMemo, useState } from 'react';
import { Calendar, Views, luxonLocalizer } from 'react-big-calendar';
import BookingModal from '../../Components/Booking/BookingDialog';
import { EventDetailsDialog } from '../../Components/Booking/EventDetailsDialog';
import { PaymentDialog } from '../../Components/Booking/PaymentDialog';
import { SiteContext } from '../../Contexts/SiteContext';
import useWindowSize from '../../Hooks/UseWindowSize';
import { Event } from '../../Model/Event';
import { GetEventTypeColor } from '../../Model/EventType';
import { useGetCourtsQuery } from '../../Service/Courts';
import { useGetEventsQuery } from '../../Service/Events';
import { useGetEventTypesQuery } from '../../Service/EventTypes';
import { useGetKBQuery } from '../../Service/KB';
import { today } from '../../Util/Dates';
import { CourtHeader } from './CourtHeader';
import * as Style from './Style';
import ToolBar from './ToolBar';

const localizer = luxonLocalizer(DateTime);

const maxTime = today().set({ hour: 23, minute: 59, second: 59 });
const minTime = today().set({ hour: 6 });

const EventWrapper = (props: any) => {
  return <div>{props.children}</div>;
};

const CourtsCalendar = () => {
  const [bookingModalOpen, setBookingModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event>({
    SiteID: 0,
    SlotID: 0,
    SlotTypeID: 0,
    CourtID: 0,
    Title: '',
    Start: '',
    End: '',
    GameTypeCosts: undefined,
    Booking: undefined,
  } as Event);

  const handleSelectEvent = (e: Event) => {
    setSelectedEvent(e);
    if (e.Booking) {
      setEventDetailsDialogOpen(true);
    } else {
      setBookingModalOpen(true);
    }
  };

  const { data: kb } = useGetKBQuery();
  const minDate = today();
  const maxDate = today().plus({ days: kb?.MaxScheduledDays });

  const [date, setDate] = useState<DateTime>(today());
  const theme = useTheme();

  const siteCtx = useContext(SiteContext);

  const { data: events, isLoading: isEventsLoading } = useGetEventsQuery({
    startMillis: date.toMillis(),
    endMillis: date.plus({ days: 1 }).toMillis(),
    siteID: siteCtx.site.id,
  });

  const { data: eventTypes } = useGetEventTypesQuery(siteCtx.site.id);

  const eventPropGetter = useCallback(
    (e: Event, _start: Date, _end: Date, _isSelected: boolean) => ({
      ...(e.Booking &&
        e.Booking?.EventTypeID === 0 && {
          style: {
            backgroundColor: `${theme.palette.secondary.main}`,
            color: `${theme.palette.text.primary}`,
          },
        }),
      ...(e.Booking &&
        e.Booking?.EventTypeID > 0 && {
          style: {
            backgroundColor: `${GetEventTypeColor(
              e.Booking?.EventTypeID,
              eventTypes
            )}`,
            color: `${theme.palette.text.primary}`,
          },
        }),
      ...(!e.Booking && {
        style: {
          backgroundColor: `${theme.palette.primary.light}`,
          color: `${theme.palette.text.primary}`,
        },
      }),
    }),
    [
      theme.palette.primary.light,
      theme.palette.secondary.main,
      theme.palette.text.primary,
      eventTypes,
    ]
  );

  const [width] = useWindowSize();

  function handleDateSet(date: DateTime<boolean>): void {
    if (date < minDate || date > maxDate) {
      return;
    }

    setDate(date);
  }

  const components = useMemo(() => {
    return {
      eventWrapper: EventWrapper,
      resourceHeader: CourtHeader,
    };
  }, []);

  const { data: courts, isLoading: isCourtsLoading } = useGetCourtsQuery(
    siteCtx.site.id
  );

  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const handleEventBooked = (e?: Event) => {
    if (!e || e.Booking?.Cost === 0) {
      return;
    }

    setSelectedEvent(e);
    setBookingModalOpen(false);
    setEventDetailsDialogOpen(false);
    setOpenPaymentDialog(true);
  };

  const [eventDetailsDialogOpen, setEventDetailsDialogOpen] = useState(false);
  return (
    <Fragment>
      <Global
        styles={Style.GenerateCss({
          todayHighlightBg: theme.palette.background.paper,
          eventBgSelected: theme.palette.primary.dark,
          eventBorderSelected: theme.palette.secondary.dark,
          resourcesFontFamily: theme.typography.fontFamily,
          resourcesFontColor: theme.palette.grey[600],
          currentTimeColor: theme.palette.primary.main,
          eventMinHeight: '75px',
        })}
      />

      <Grid container justifyContent="center">
        <Grid item>
          <ToolBar
            initialDate={date}
            minDate={minDate}
            maxDate={maxDate}
            onDateSet={handleDateSet}
          />
        </Grid>
        <Grid item width={width - 50}>
          {isCourtsLoading || (isEventsLoading && <LinearProgress />)}
          <Calendar
            components={components}
            localizer={localizer}
            step={10}
            timeslots={6}
            toolbar={false}
            resources={courts}
            events={events}
            resourceIdAccessor="ID"
            resourceTitleAccessor="Name"
            resourceAccessor={'CourtID'}
            tooltipAccessor={'Title'}
            titleAccessor={'Title'}
            endAccessor={(e: Event) => DateTime.fromISO(e.End || '').toJSDate()}
            startAccessor={(e: Event) =>
              DateTime.fromISO(e.Start || '').toJSDate()
            }
            eventPropGetter={eventPropGetter}
            view={Views.DAY}
            onSelectEvent={handleSelectEvent}
            max={maxTime.toJSDate()}
            min={minTime.toJSDate()}
            onView={() => {}}
            onNavigate={(date) => setDate(DateTime.fromJSDate(date))}
            date={date.toJSDate()}
          />
        </Grid>
      </Grid>
      <BookingModal
        event={selectedEvent}
        open={bookingModalOpen}
        onEventBooked={handleEventBooked}
        setOpen={setBookingModalOpen}
      />
      <EventDetailsDialog
        open={eventDetailsDialogOpen}
        setOpen={setEventDetailsDialogOpen}
        onPaymentRequested={handleEventBooked}
        event={selectedEvent}
      />
      <PaymentDialog
        open={openPaymentDialog}
        onClose={() => setOpenPaymentDialog(false)}
        event={selectedEvent}
      />
    </Fragment>
  );
};

export default CourtsCalendar;
