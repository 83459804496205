import {
  Bookmark,
  BookOnline,
  CalendarMonth,
  CalendarViewMonth,
  Category,
  CurrencyExchange,
  Domain,
  DomainAdd,
  Groups,
  Label,
  SupervisedUserCircle,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  HasAnyGlobalPerm,
  HasGlobalPerm,
  HasSiteAdminPerms,
  HasSiteCourtAdminPerm,
  HasSiteEventTypeAdminPerms,
  HasSiteMemberTypeMappingAdminPerms,
  HasSitePaymentProviderAdminPerms,
  HasSiteSlotTypeAdminPerms,
  HasSiteUserRolesAdminPerms,
  PermAddSite,
  PermsContext,
} from '../../../Contexts/PermissionsContext';
import { SiteContext } from '../../../Contexts/SiteContext';
import {
  AddSitePath,
  BookingsPath,
  CalendarPath,
  ManageCourtsPath,
  ManageEventTypesPath,
  ManageMembersPath,
  ManagePaymentsPath,
  ManageSitePath,
  ManageSlotTypesPath,
  ManageUserRolesPath,
  OpenSlotsPath,
} from '../../../Routes';
import {
  getAssociatedSiteIDs,
  useGetRolesAndMembershipsQuery,
} from '../../../Service/Me';
import { useGetSiteQuery } from '../../../Service/Site';
interface SidebarProps {
  drawerOpen: boolean;
  onRequestClose: () => void;
  drawerWidth?: number;
  window?: () => Window;
}

const Sidebar = (props: SidebarProps) => {
  const theme = useTheme();
  const { perms: permState } = useContext(PermsContext);

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const handleCloseDrawer = () => {
    if (matchDownMd) {
      props.onRequestClose();
    }
  };

  const siteCtx = useContext(SiteContext);

  const { data: rm } = useGetRolesAndMembershipsQuery();
  const isMember = getAssociatedSiteIDs(rm).includes(siteCtx.site.id);

  const { data: site } = useGetSiteQuery(siteCtx.site.id);

  const location = useLocation();

  const buttonColor = (currentPath: string) => {
    if (currentPath === location.pathname) {
      return 'primary.main';
    }
    return '';
  };

  const drawerContent = (
    <div>
      <Toolbar sx={{ border: 0 }} />
      <Typography
        variant="body1"
        noWrap
        component="div"
        sx={{ fontWeight: 600, p: 1 }}
      >
        {site?.Name}
      </Typography>
      <List sx={{ p: 1 }}>
        <ListItem key={'site'} disablePadding>
          <Typography
            variant="caption"
            sx={{ mt: 2, fontWeight: 600 }}
            display="block"
            gutterBottom
          >
            Bookings
          </Typography>
        </ListItem>
        <ListItem key={'slots'} disablePadding>
          <ListItemButton
            component={Link}
            to={OpenSlotsPath}
            onClick={handleCloseDrawer}
            sx={{
              borderRadius: '7px',
              color: buttonColor(OpenSlotsPath),
            }}
          >
            <ListItemIcon sx={{ color: buttonColor(OpenSlotsPath) }}>
              <BookOnline />
            </ListItemIcon>
            <ListItemText primary={'Open Slots'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={'bookings'} disablePadding>
          <ListItemButton
            component={Link}
            to={BookingsPath}
            onClick={handleCloseDrawer}
            sx={{
              borderRadius: '7px',
              color: buttonColor(BookingsPath),
            }}
          >
            <ListItemIcon sx={{ color: buttonColor(BookingsPath) }}>
              <Bookmark />
            </ListItemIcon>
            <ListItemText primary={'My Bookings'} />
          </ListItemButton>
        </ListItem>
        {isMember && (
          <ListItem key={'calendar'} disablePadding>
            <ListItemButton
              sx={{ borderRadius: '7px', color: buttonColor(CalendarPath) }}
              component={Link}
              to={CalendarPath}
              onClick={handleCloseDrawer}
            >
              <ListItemIcon sx={{ color: buttonColor(CalendarPath) }}>
                <CalendarMonth />
              </ListItemIcon>
              <ListItemText primary={'Calendar'} />
            </ListItemButton>
          </ListItem>
        )}

        {HasSiteAdminPerms(permState, siteCtx.site.id) && (
          <>
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
            <Typography
              variant="caption"
              sx={{ mt: 2, fontWeight: 600 }}
              display="block"
              gutterBottom
            >
              Site Administration
            </Typography>
            {(HasSiteAdminPerms(permState, siteCtx.site.id) ||
              HasSitePaymentProviderAdminPerms(permState, siteCtx.site.id)) && (
              <ListItem key={'manageSite'} disablePadding>
                <ListItemButton
                  component={Link}
                  to={ManageSitePath}
                  onClick={handleCloseDrawer}
                  sx={{
                    borderRadius: '7px',

                    color: buttonColor(ManageSitePath),
                  }}
                >
                  <ListItemIcon sx={{ color: buttonColor(ManageSitePath) }}>
                    <Domain />
                  </ListItemIcon>
                  <ListItemText primary={'Site'} />
                </ListItemButton>
              </ListItem>
            )}
            {HasSiteCourtAdminPerm(permState, siteCtx.site.id) && (
              <ListItem key={'manageCourts'} disablePadding>
                <ListItemButton
                  component={Link}
                  to={ManageCourtsPath}
                  onClick={handleCloseDrawer}
                  sx={{
                    borderRadius: '7px',
                    color: buttonColor(ManageCourtsPath),
                  }}
                >
                  <ListItemIcon sx={{ color: buttonColor(ManageCourtsPath) }}>
                    <CalendarViewMonth />
                  </ListItemIcon>
                  <ListItemText primary={'Courts'} />
                </ListItemButton>
              </ListItem>
            )}
            {HasSiteEventTypeAdminPerms(permState, siteCtx.site.id) && (
              <ListItem key={'manageEventTypes'} disablePadding>
                <ListItemButton
                  component={Link}
                  to={ManageEventTypesPath}
                  onClick={handleCloseDrawer}
                  sx={{
                    borderRadius: '7px',
                    color: buttonColor(ManageEventTypesPath),
                  }}
                >
                  <ListItemIcon
                    sx={{ color: buttonColor(ManageEventTypesPath) }}
                  >
                    <Category />
                  </ListItemIcon>
                  <ListItemText primary={'Event Types'} />
                </ListItemButton>
              </ListItem>
            )}
            {HasSiteSlotTypeAdminPerms(permState, siteCtx.site.id) && (
              <ListItem key={'manageSlotTypes'} disablePadding>
                <ListItemButton
                  component={Link}
                  to={ManageSlotTypesPath}
                  onClick={handleCloseDrawer}
                  sx={{
                    borderRadius: '7px',
                    color: buttonColor(ManageSlotTypesPath),
                  }}
                >
                  <ListItemIcon
                    sx={{ color: buttonColor(ManageSlotTypesPath) }}
                  >
                    <Label />
                  </ListItemIcon>
                  <ListItemText primary={'Slot Types'} />
                </ListItemButton>
              </ListItem>
            )}
            {HasSiteMemberTypeMappingAdminPerms(permState, siteCtx.site.id) && (
              <ListItem key={'manageMembers'} disablePadding>
                <ListItemButton
                  component={Link}
                  to={ManageMembersPath}
                  onClick={handleCloseDrawer}
                  sx={{
                    borderRadius: '7px',
                    color: buttonColor(ManageMembersPath),
                  }}
                >
                  <ListItemIcon sx={{ color: buttonColor(ManageMembersPath) }}>
                    <Groups />
                  </ListItemIcon>
                  <ListItemText primary={'Members'} />
                </ListItemButton>
              </ListItem>
            )}
            {HasSitePaymentProviderAdminPerms(permState, siteCtx.site.id) && (
              <ListItem key={'managePayments'} disablePadding>
                <ListItemButton
                  component={Link}
                  to={ManagePaymentsPath}
                  onClick={handleCloseDrawer}
                  sx={{
                    borderRadius: '7px',
                    color: buttonColor(ManagePaymentsPath),
                  }}
                >
                  <ListItemIcon sx={{ color: buttonColor(ManagePaymentsPath) }}>
                    <CurrencyExchange />
                  </ListItemIcon>
                  <ListItemText primary={'Payments'} />
                </ListItemButton>
              </ListItem>
            )}
            {HasSiteUserRolesAdminPerms(permState, siteCtx.site.id) && (
              <ListItem key={'manageUserRoles'} disablePadding>
                <ListItemButton
                  component={Link}
                  to={ManageUserRolesPath}
                  onClick={handleCloseDrawer}
                  sx={{
                    borderRadius: '7px',
                    color: buttonColor(ManageUserRolesPath),
                  }}
                >
                  <ListItemIcon
                    sx={{ color: buttonColor(ManageUserRolesPath) }}
                  >
                    <SupervisedUserCircle />
                  </ListItemIcon>
                  <ListItemText primary={'User Roles'} />
                </ListItemButton>
              </ListItem>
            )}
          </>
        )}
        {HasAnyGlobalPerm(permState) && (
          <>
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
            <Typography
              variant="caption"
              sx={{ mt: 2, fontWeight: 600 }}
              display="block"
              gutterBottom
            >
              Global Administration
            </Typography>
            {HasGlobalPerm(permState, PermAddSite) && (
              <ListItem key={'addSite'} disablePadding>
                <ListItemButton
                  component={Link}
                  to={'/add-site'}
                  onClick={handleCloseDrawer}
                  sx={{ borderRadius: '7px', color: buttonColor(AddSitePath) }}
                >
                  <ListItemIcon sx={{ color: buttonColor(AddSitePath) }}>
                    <DomainAdd />
                  </ListItemIcon>
                  <ListItemText primary={'Add Site'} />
                </ListItemButton>
              </ListItem>
            )}
          </>
        )}
      </List>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: matchDownMd ? 'auto' : props.drawerWidth,
      }}
    >
      <Drawer
        sx={{
          width: props.drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: props.drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant={matchDownMd ? 'temporary' : 'persistent'}
        anchor="left"
        open={props.drawerOpen}
        onClose={handleCloseDrawer}
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
