import { Divider, Grid, Stack, Typography } from '@mui/material';
import { Fragment, useContext, useState } from 'react';
import { PaymentDialog } from '../../Components/Booking/PaymentDialog';
import { SiteContext } from '../../Contexts/SiteContext';
import { Event } from '../../Model/Event';
import { useGetMyBookingsQuery } from '../../Service/Events';
import { BookingCard } from './BookingCard';

export const BookingsPage = () => {
  const siteId = useContext(SiteContext).site.id;
  const { data: myBookings } = useGetMyBookingsQuery(siteId);

  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);

  const handlePaymentRequested = (event?: Event) => {
    setSelectedEvent(event);
    setOpenPaymentDialog(true);
  };

  return (
    <Fragment>
      <Stack spacing={2}>
        <Typography variant={'h6'}>My Bookings</Typography>
        <Divider />
      </Stack>

      <Grid container spacing={2} justifyContent={'center'}>
        <Grid item xs={12}></Grid>
        {myBookings?.map((b) => (
          <Grid item xs={3} key={b.Booking?.ID}>
            <BookingCard
              event={b}
              onPaymentRequested={handlePaymentRequested}
            />
          </Grid>
        ))}
      </Grid>
      <PaymentDialog
        event={selectedEvent}
        open={openPaymentDialog}
        onClose={() => setOpenPaymentDialog(false)}
      />
    </Fragment>
  );
};
