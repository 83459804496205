import { useContext, useState } from 'react';

// material-ui
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';

import * as Yup from 'yup';

// assets
import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { Link } from 'react-router-dom';
import { SiteContext } from '../../Contexts/SiteContext';
import { OpErr } from '../../Model/OpErr';
import { SendResetPasswordLinkPath, SignupPath } from '../../Routes';
import {
  getAssociatedSiteIDs,
  useGetRolesAndMembershipsQuery,
} from '../../Service/Me';
import { toApiUrl } from '../../Util/Api';
import { getCurrentSiteId, setToken } from '../../Util/Store';

const Login = ({ ...others }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    password: Yup.string().max(255).required('Password is required'),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: rm } = useGetRolesAndMembershipsQuery();
  const siteCtx = useContext(SiteContext);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setIsSubmitting(true);

    try {
      const res = await axios.post(toApiUrl('/auth/login'), data);

      if (res.status === 200) {
        setToken(res.data.token);

        let siteId = getCurrentSiteId();
        if (siteId === 0) {
          // TODO: Fix this once we have more than one site
          siteId = getAssociatedSiteIDs(rm).find((s) => s > 0) || 1;
        }

        if (siteId > 0) {
          await siteCtx.load(siteId);
        }

        reset();

        setIsSubmitting(false);

        // Using href so we load everything we need in App.tsx
        window.location.href = '/';
      }
    } catch (e: any) {
      if (e.response) {
        const opErr = e.response.data as OpErr;
        setError('root', { message: opErr.message });
      } else if (e.message) {
        setError('root', { message: e.message });
      } else {
        setError('root', { message: 'Something went wrong.' });
      }
    }

    setIsSubmitting(false);
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <form noValidate onSubmit={handleSubmit(onSubmit)} {...others}>
          <FormControl
            fullWidth
            error={Boolean(errors.email)}
            sx={{ marginTop: 1, marginBottom: 1 }}
          >
            <InputLabel htmlFor="outlined-adornment-email-login">
              Email Address
            </InputLabel>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  id="outlined-adornment-email-login"
                  type="email"
                  label="Email Address"
                  disabled={isSubmitting}
                  inputProps={{}}
                />
              )}
            />
            {errors.email && (
              <FormHelperText
                error
                id="standard-weight-helper-text-email-login"
              >
                {errors.email.message}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            fullWidth
            error={Boolean(errors.password)}
            sx={{ marginTop: 1, marginBottom: 1 }}
          >
            <InputLabel htmlFor="outlined-adornment-password-login">
              Password
            </InputLabel>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  id="outlined-adornment-password-login"
                  disabled={isSubmitting}
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
            />
            {errors.password && (
              <FormHelperText
                error
                id="standard-weight-helper-text-password-login"
              >
                {errors.password.message}
              </FormHelperText>
            )}
          </FormControl>
          <Stack direction="row" justifyContent="right" spacing={1}>
            <Typography
              component={Link}
              to={SendResetPasswordLinkPath}
              variant="subtitle1"
              color="secondary"
              sx={{ textDecoration: 'none', cursor: 'pointer' }}
            >
              Forgot Password?
            </Typography>
          </Stack>
          {errors.root && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.root.message}</FormHelperText>
            </Box>
          )}

          <Box sx={{ mt: 2 }} display={'flex'} justifyContent={'center'}>
            {!isSubmitting && (
              <Button
                fullWidth
                color="primary"
                type="submit"
                variant="contained"
              >
                Sign in
              </Button>
            )}
            {isSubmitting && <CircularProgress />}
          </Box>
        </form>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid item container direction="column" alignItems="center" xs={12}>
          <Typography
            component={Link}
            to={SignupPath}
            variant="subtitle1"
            color={'primary'}
            sx={{ textDecoration: 'none' }}
          >
            Don&apos;t have an account?
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
