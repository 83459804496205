import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Switch,
  Typography,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import * as Yup from 'yup';
import { ColorPicker } from '../../Components/UI/ColorPicker';
import { HelpTooltip } from '../../Components/UI/HelpTooltip';
import { SiteContext } from '../../Contexts/SiteContext';
import { SlotType } from '../../Model/SlotType';
import {
  useAddSlotTypeMutation,
  useUpdateSlotTypeMutation,
} from '../../Service/SlotTypes';
import { getErrorMessage } from '../../Util/Error';

type AddEditMemberTypeDialogProps = {
  open: boolean;
  title: string;
  slotType?: SlotType;
  onSlotTypeSaved?: (slotType: SlotType) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
};
export const AddEditSlotTypeDialog = (props: AddEditMemberTypeDialogProps) => {
  const schema = Yup.object().shape({
    Name: Yup.string().required('Name is required'),
    ID: Yup.number(),
    Description: Yup.string(),
    Color: Yup.string(),
    IsRestrictive: Yup.boolean(),
    SiteID: Yup.number().required(),
  });

  const siteID = useContext(SiteContext).site.id;

  const values: SlotType = useMemo(() => {
    return {
      ID: props.slotType?.ID || 0,
      Name: props.slotType?.Name || '',
      Color: props.slotType?.Color || '',
      IsRestrictive: props.slotType ? props.slotType.IsRestrictive : true,
      Description: props.slotType?.Description || '',
      SiteID: props.slotType ? props.slotType.SiteID : siteID,
    };
  }, [props.slotType, siteID]);

  const {
    control,
    handleSubmit,
    setError,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: values,
    resolver: yupResolver(schema),
  });

  const [addSlotType, { isLoading: isSlotTypeAdding }] =
    useAddSlotTypeMutation();
  const [updateSlotType, { isLoading: isSlotTypeUpdating }] =
    useUpdateSlotTypeMutation();

  useEffect(() => {
    if (!props.open) {
      reset(values);
    }
  }, [props.open, reset, values]);

  const isSaving = isSlotTypeAdding || isSlotTypeUpdating;
  const handlePostSlotType = async (slotType: SlotType): Promise<void> => {
    try {
      await addSlotType(slotType);
      props.setOpen(false);
    } catch (e: any) {
      const msg = getErrorMessage(e);
      setError('root', { message: msg });
    }
  };

  const handlePutSlotType = async (slotType: SlotType): Promise<void> => {
    try {
      await updateSlotType(slotType);
      props.setOpen(false);
    } catch (e: any) {
      const msg = getErrorMessage(e);
      setError('root', { message: msg });
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (data.ID === 0) {
      handlePostSlotType(data as SlotType);
    } else {
      handlePutSlotType(data as SlotType);
    }
  };

  const handleCancel = (): void => {
    props.setOpen(false);
  };

  const isRestrictiveHelpText =
    'If enabled, slots of this type will be ' +
    'marked as unavailable if a slot rule does not exist for the specific ' +
    'member type (or public). Otherwise, slot availability will be determined ' +
    'by membership rules, if any.';

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        fullWidth
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
        }}
      >
        <DialogTitle textAlign={'center'}>{props.title}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ py: 2 }} alignItems={'center'}>
            <input type="hidden" {...register('SiteID')} />
            <FormControl fullWidth error={!!errors.Name}>
              <InputLabel htmlFor="Name">Name</InputLabel>
              <Controller
                name="Name"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    autoComplete="slot-type-name"
                    disabled={isSaving}
                    label="Name"
                  />
                )}
              />
              <FormHelperText>{errors.Name?.message}</FormHelperText>
            </FormControl>
            <FormControl fullWidth error={!!errors.Description}>
              <InputLabel htmlFor="Description">Description</InputLabel>
              <Controller
                name="Description"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    disabled={isSaving}
                    autoComplete="slot-type-description"
                    label="Description"
                  />
                )}
              />
              <FormHelperText>{errors.Description?.message}</FormHelperText>
            </FormControl>
            <FormControl fullWidth error={!!errors.Color}>
              <Controller
                name="Color"
                control={control}
                render={({ field }) => <ColorPicker {...field} label="Color" />}
              />
              <FormHelperText>{errors.Color?.message}</FormHelperText>
            </FormControl>
            <FormControl fullWidth error={!!errors.IsRestrictive}>
              <Controller
                name="IsRestrictive"
                control={control}
                render={({ field }) => (
                  <HelpTooltip text={isRestrictiveHelpText}>
                    <FormControlLabel
                      {...field}
                      checked={field.value}
                      disabled={isSaving}
                      control={<Switch />}
                      label="Is Restrictive"
                    />
                  </HelpTooltip>
                )}
              />
              <FormHelperText>{errors.IsRestrictive?.message}</FormHelperText>
            </FormControl>
            <Typography color="error">{errors?.root?.message}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton type="submit" color="primary" loading={isSaving}>
            Save
          </LoadingButton>
          <Button color="inherit" onClick={handleCancel} disabled={isSaving}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
