import { Logout, Password, Settings } from '@mui/icons-material';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Fade,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoutPath, ProfilePath, UpdatePasswordPath } from '../../../Routes';
import { useGetMeQuery } from '../../../Service/Me';
import MainCard from '../../UI/MainCard';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export const ProfileMenu = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const navigate = useNavigate();

  const anchorRef = useRef<any>(null);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClose = (event: MouseEvent | TouchEvent): void => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleMenuClick = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  const { data: me } = useGetMeQuery({});

  const fullName =
    me?.FirstName.toUpperCase() + ' ' + me?.LastName.toUpperCase();
  return (
    <>
      <IconButton ref={anchorRef} onClick={() => setOpen((prev) => !prev)}>
        <Avatar {...stringAvatar(fullName)} />
      </IconButton>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        <Fade in timeout={350}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MainCard
                border={false}
                elevation={16}
                content={false}
                boxShadow
                shadow={theme.shadows[16]}
              >
                <Box sx={{ p: 2 }}>
                  <List>
                    <ListItemButton
                      onClick={() => handleMenuClick(ProfilePath)}
                      sx={{ borderRadius: `${theme.border.radius}` }}
                    >
                      <ListItemIcon>
                        <Settings stroke="1.5" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">Edit Profile</Typography>
                        }
                      />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => handleMenuClick(UpdatePasswordPath)}
                      sx={{ borderRadius: `${theme.border.radius}` }}
                    >
                      <ListItemIcon>
                        <Password stroke="1.5" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">
                            Update Password
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ borderRadius: `${theme.border.radius}` }}
                      onClick={() => handleMenuClick(LogoutPath)}
                    >
                      <ListItemIcon>
                        <Logout stroke="1.5" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">Logout</Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </Box>
              </MainCard>
            </ClickAwayListener>
          </Paper>
        </Fade>
      </Popper>
    </>
  );
};
