export enum Weekday {
  Sun,
  Mon,
  Tue,
  Wed,
  Thu,
  Fri,
  Sat,
}

export const WeekdayNames = Object.values(Weekday).filter(
  (value) => typeof value !== 'string'
) as Weekday[];
