import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { PeriodType } from '../../Model/Time/PeriodType';

type Duration = {
  Value?: number;
  PeriodType?: PeriodType;
};

type Props = {
  value?: Duration;
  onChange?: (value?: Duration) => void;
};

export const DurationInput = (props: Props) => {
  return (
    <Stack display={'flex'} alignItems="center" direction="row" spacing={1}>
      <FormControl style={{ maxWidth: 120 }}>
        <NumericFormat
          label="Grace Period"
          customInput={TextField}
          value={props.value?.Value}
          onChange={(e) => {
            if (props.onChange) {
              props.onChange({
                Value: e.target.value ? Number(e.target.value) : undefined,
                PeriodType: props.value?.PeriodType,
              });
            }
          }}
          fixedDecimalScale
          thousandSeparator
        />
      </FormControl>
      <FormControl style={{ minWidth: 140 }}>
        <InputLabel>Period Type</InputLabel>
        <Select
          label="Period Type"
          value={props.value?.PeriodType}
          onChange={(e) =>
            props.onChange?.({
              Value: props.value?.Value,
              PeriodType: e.target.value as PeriodType,
            })
          }
        >
          <MenuItem value={PeriodType.Hour}>Hours</MenuItem>
          <MenuItem value={PeriodType.Day}>Days</MenuItem>
          <MenuItem value={PeriodType.Week}>Weeks</MenuItem>
          <MenuItem value={PeriodType.Month}>Months</MenuItem>
          <MenuItem value={PeriodType.Year}>Years</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
};
