import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { SiteContext } from '../Contexts/SiteContext';
import {
  getAssociatedSiteIDs,
  useGetRolesAndMembershipsQuery,
} from '../Service/Me';

export const MemberRoute = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  const { children } = props;

  const { data: rm } = useGetRolesAndMembershipsQuery();
  const siteCtx = useContext(SiteContext);

  const isMember = getAssociatedSiteIDs(rm).includes(siteCtx.site.id);

  return <>{isMember ? children : <Navigate to="/" />}</>;
};
