import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { PermsContext, PermsState } from '../Contexts/PermissionsContext';
import { SiteContext } from '../Contexts/SiteContext';

export const SitePermsRoute = (props: {
  children: React.ReactNode;
  perm: (state: PermsState, siteID: number) => boolean;
}) => {
  const { children } = props;

  const permsCtx = useContext(PermsContext);
  const siteCtx = useContext(SiteContext);
  const isPermitted = props.perm(permsCtx.perms, siteCtx.site.id);
  if (!isPermitted) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};
