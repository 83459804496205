import { MinutesToLabel } from './SlotBucket';
import { Weekday } from './Time/Weekday';

export interface Slot {
  ID: number;
  CourtID: number;
  Weekday: Weekday;
  StartTimeMinutes: number;
  EndTimeMinutes: number;
  SlotTypeID: number;
}

export const GroupByDay = (slots: Slot[]): Slot[][] => {
  const grouped: Slot[][] = [];
  for (let i = 0; i < 7; i++) {
    grouped.push(slots.filter((slot) => slot.Weekday === i));
  }
  return grouped;
};

export const LabelWithDay = (slot?: Slot): string => {
  if (!slot) {
    return '';
  }
  return `${Weekday[slot.Weekday]} ${MinutesToLabel(slot.StartTimeMinutes)}`;
};

export const LabelFromIDs = (ids: number[], slots: Slot[]): string => {
  const slotMap = new Map(slots.map((slot) => [slot.ID, slot]));

  return ids
    .map((id) => slotMap.get(id))
    .map((slot) => LabelWithDay(slot))
    .join(', ');
};
