import { Add, Delete } from '@mui/icons-material';
import {
  Fab,
  Grid,
  LinearProgress,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useContext, useState } from 'react';
import { ConfirmDialog } from '../../../Components/Dialog/ConfirmDialog';
import {
  HasSiteSlotAdminPerms,
  PermsContext,
} from '../../../Contexts/PermissionsContext';

import { Court } from '../../../Model/Court';
import { LabelWithDay, Slot } from '../../../Model/Slot';
import { SnackBarInfo } from '../../../Model/SnackBarInfo';
import {
  useDeleteCourtSlotsMutation,
  useDeleteSlotMutation,
  useGetSlotsQuery,
} from '../../../Service/Slots';
import { getErrorMessage } from '../../../Util/Error';
import { AddSlotBucketsDialog } from './AddSlotBucketsDialog';
import { SlotsView } from './SlotsView';

type ManageCourtSlotsProps = {
  court: Court;
};
export const ManageCourtSlots = ({ court }: ManageCourtSlotsProps) => {
  const [openAddSlotDialog, setOpenAddSlotDialog] = useState(false);

  const handleOpenAddSlotDialog = () => {
    setOpenAddSlotDialog(true);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [slotToDelete, setSlotToDelete] = useState<Slot>();

  const [openDeleteAllSlotsDialog, setOpenDeleteAllSlotsDialog] =
    useState(false);

  const [deleteSlot] = useDeleteSlotMutation();
  async function handleDeleteSlot(): Promise<void> {
    if (!slotToDelete) {
      return;
    }

    try {
      await deleteSlot({ siteID: court.SiteID, slot: slotToDelete }).unwrap();
    } catch (e) {
      const msg = getErrorMessage(e);
      setSnackBarInfo({ open: true, message: msg, severity: 'error' });
    } finally {
      setSlotToDelete(undefined);
    }
  }

  const { perms: permissions } = useContext(PermsContext);
  const hasSlotPerms = HasSiteSlotAdminPerms(permissions, court.SiteID);

  const handleDeleteRequested = (slot: Slot) => {
    setSlotToDelete(slot);
    setOpenDeleteDialog(true);
  };

  const handleOpenConfirmDeleteAllSlots = () => {
    setOpenDeleteAllSlotsDialog(true);
  };

  const [deleteCourtSlots] = useDeleteCourtSlotsMutation();

  const handleDeleteAllSlots = async () => {
    try {
      await deleteCourtSlots({
        siteID: court.SiteID,
        courtID: court.ID,
      }).unwrap();

      setSnackBarInfo({
        open: true,
        message: 'All slots deleted successfully',
        severity: 'success',
      });
    } catch (e) {
      const msg = getErrorMessage(e);
      setSnackBarInfo({ open: true, message: msg, severity: 'error' });
    }
    setOpenDeleteAllSlotsDialog(false);
  };

  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarInfo>({
    open: false,
    message: '',
    severity: 'success',
  });

  const deleteDialogMsg = `Are you sure you want to delete the '${LabelWithDay(
    slotToDelete
  )}' slot? All related bookings will also be deleted!`;

  const { data: slots, isLoading } = useGetSlotsQuery({
    siteID: court.SiteID,
    courtID: court.ID,
  });

  return (
    <Fragment>
      <Grid container sx={{ p: 2 }}>
        <Grid item xs={12} sm={6}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="flex-start"
          >
            <Tooltip title="Add Slot">
              <Fab
                color="primary"
                variant="extended"
                size="small"
                onClick={handleOpenAddSlotDialog}
                aria-label="add-slot"
              >
                <Add />
              </Fab>
            </Tooltip>
            <Typography variant="body1">Add Slots</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="flex-start"
          >
            <Tooltip title="Delete All Court Slots">
              <Fab
                color="error"
                variant="extended"
                size="small"
                onClick={handleOpenConfirmDeleteAllSlots}
                aria-label="delete-slot"
              >
                <Delete />
              </Fab>
            </Tooltip>
            <Typography variant="body1">Delete Court Slots</Typography>
          </Stack>
        </Grid>
        <Grid my={2} item xs={12}>
          {isLoading && <LinearProgress />}
          {!isLoading && (
            <SlotsView
              onDeleteClicked={
                hasSlotPerms ? (slot) => handleDeleteRequested(slot) : undefined
              }
              slots={slots}
            />
          )}
        </Grid>
      </Grid>
      <AddSlotBucketsDialog
        open={openAddSlotDialog}
        court={court}
        setOpen={setOpenAddSlotDialog}
      />
      <ConfirmDialog
        danger
        message={deleteDialogMsg}
        title="Delete Slot?"
        onConfirm={handleDeleteSlot}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
      />
      <ConfirmDialog
        danger
        message="Are you sure you want to delete all slots for this court?"
        title="Delete All Slots?"
        onConfirm={handleDeleteAllSlots}
        open={openDeleteAllSlotsDialog}
        setOpen={setOpenDeleteAllSlotsDialog}
      />
      <Snackbar
        open={snackBarInfo.open}
        autoHideDuration={6000}
        onClose={() => setSnackBarInfo({ ...snackBarInfo, open: false })}
        title={snackBarInfo.message}
        color={snackBarInfo.severity}
      />
    </Fragment>
  );
};
