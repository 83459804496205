import { Box, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { AddCostsToGameTypes, Event, FormatCosts } from '../../Model/Event';
import { useGetKBQuery } from '../../Service/KB';
import DateLabel from './DateLabel';
import SlotButton from './SlotButton';

type DaySlotsProps = {
  date: DateTime;
  events: Event[];
  handleClick?: (slot: Event) => void;
};

const DaySlots = (props: DaySlotsProps) => {
  const isToday = props.date.day === DateTime.now().day;

  function handleClick(e: Event): void {
    if (props.handleClick) {
      props.handleClick(e);
    }
  }

  const now = DateTime.now();
  const filtered = useMemo(() => {
    return props.events.filter((event) => {
      return DateTime.fromISO(event.Start || '') >= now;
    });
  }, [props.events, now]);

  const { data: kb } = useGetKBQuery();

  return (
    <Stack direction={'column'} alignItems={'center'} spacing={1}>
      <Box sx={{ '& .MuiBox-root': { marginBottom: '1rem' } }}>
        <DateLabel
          variant={isToday ? 'prominent' : 'normal'}
          date={props.date}
        />
      </Box>
      {filtered.map(
        (event) =>
          !event.Booking && (
            <SlotButton
              key={`${event.Start}-${event.CourtID}`}
              middleText={event.CourtName}
              upperText={DateTime.fromISO(event.Start || '').toFormat('HH:mm')}
              lowerText={FormatCosts(
                AddCostsToGameTypes(kb?.GameTypes ?? [], event.GameTypeCosts)
              )}
              onClick={() => handleClick(event)}
            />
          )
      )}
    </Stack>
  );
};

export default DaySlots;
