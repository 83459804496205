export enum PeriodType {
  None = 0,
  Hour = 1,
  Day = 2,
  Week = 3,
  Month = 4,
  Year = 5,
}

export function GetPeriodTypeMinutes(periodType: PeriodType): number {
  switch (periodType) {
    case PeriodType.None:
      return 0;
    case PeriodType.Hour:
      return 60;
    case PeriodType.Day:
      return 60 * 24;
    case PeriodType.Week:
      return 60 * 24 * 7;
    case PeriodType.Month:
      throw new Error('Not supported');
    case PeriodType.Year:
      return 60 * 24 * 365;
    default:
      return 0;
  }
}

export const PeriodTypes: PeriodType[] = [
  PeriodType.None,
  PeriodType.Hour,
  PeriodType.Day,
  PeriodType.Week,
  PeriodType.Month,
  PeriodType.Year,
];

export function GetPeriodTypeName(periodType: PeriodType): string {
  switch (periodType) {
    case PeriodType.None:
      return 'None';
    case PeriodType.Hour:
      return 'Hour';
    case PeriodType.Day:
      return 'Day';
    case PeriodType.Week:
      return 'Week';
    case PeriodType.Month:
      return 'Month';
    case PeriodType.Year:
      return 'Year';
    default:
      return 'Unknown';
  }
}
