import { Box, Stack, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';

export interface DateLabelProps {
  date?: DateTime;
  variant?: 'prominent' | 'normal';
}

const DateLabel = (props: DateLabelProps) => {
  if (!props.date) {
    props.date = DateTime.now();
  }

  let weekday = props.date.weekdayShort;
  if (!weekday) {
    weekday = 'Undefined';
  }

  let variant = 'normal';
  if (props.variant) {
    variant = props.variant;
  }

  const theme = useTheme();

  let backGround = 'white';
  weekday = weekday?.substring(0, 3);
  if (variant === 'prominent') {
    backGround = theme.palette.primary.main;
    weekday = weekday?.substring(0, 1);
  }

  return (
    <Box
      sx={{
        backgroundColor: `${backGround}`,
        width: '3.25rem',
        height: '3.25rem',
        borderRadius: '50%',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <Stack direction={'column'} justifyItems={'center'}>
        <Typography
          variant="subtitle2"
          style={{
            padding: 0,
            margin: 0,
            marginBottom: '-0.15rem',
            fontWeight: 600,
          }}
        >
          {weekday}
        </Typography>
        <Typography
          variant="h6"
          style={{
            padding: 0,
            margin: 0,
            marginTop: '-0.15rem',
          }}
        >
          {props.date.day}
        </Typography>
      </Stack>
    </Box>
  );
};

export default DateLabel;
