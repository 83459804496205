import { yupResolver } from '@hookform/resolvers/yup';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import * as Yup from 'yup';
import { Court } from '../../Model/Court';
import {
  useAddCourtMutation,
  useEditCourtMutation,
} from '../../Service/Courts';
import { getErrorMessage } from '../../Util/Error';

type AddEditCourtDialogProps = {
  open: boolean;
  title: string;
  siteId: number;
  court?: Court | null;
  onCourtSaved?: (court: Court) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
};
export const AddEditCourtDialog = (props: AddEditCourtDialogProps) => {
  const { court } = props;

  const schema = Yup.object().shape({
    Name: Yup.string().required('Name is required'),
    Location: Yup.string(),
    Description: Yup.string(),
    SiteID: Yup.number().required(),
  });

  const values = useRef({
    ID: court?.ID ? court.ID : 0,
    Name: court?.Name ? court.Name : '',
    SiteID: props.siteId,
    Location: court?.Location ? court.Location : '',
    Description: court?.Description ? court.Description : '',
  });

  const {
    control,
    handleSubmit,
    setError,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: values.current as Court,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (props.open) {
      reset(values.current);
    }
  }, [props.open, values, reset]);

  const [addCourt, { isLoading: addCourtWorking }] = useAddCourtMutation();
  const [editCourt, { isLoading: editCourtWorking }] = useEditCourtMutation();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      if (data.ID === 0) {
        await addCourt(data as Court).unwrap();
      } else {
        await editCourt(data as Court).unwrap();
      }
      props.setOpen(false);
    } catch (e: any) {
      const msg = getErrorMessage(e);
      setError('root', { message: msg });
    }
  };

  function handleCancel(_event: any): void {
    reset();
    props.setOpen(false);
  }

  return (
    <Dialog
      aria-label="add-court-dialog"
      fullWidth
      onClose={() => props.setOpen(false)}
      open={props.open}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(onSubmit),
      }}
    >
      <DialogTitle textAlign={'center'}>{props.title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ py: 2 }} alignItems={'center'}>
          <input type="hidden" {...register('SiteID')} />
          <FormControl fullWidth error={Boolean(errors.Name)}>
            <InputLabel htmlFor="Name">Name</InputLabel>
            <Controller
              name="Name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  autoComplete="court-name"
                  label="Name"
                />
              )}
            />
            <FormHelperText error>{errors.Name?.message}</FormHelperText>
          </FormControl>
          <FormControl fullWidth error={Boolean(errors.Location)}>
            <InputLabel htmlFor="Location">Location</InputLabel>
            <Controller
              name="Location"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  autoComplete="court-location"
                  label="Location"
                />
              )}
            />
            <FormHelperText error>{errors.Location?.message}</FormHelperText>
          </FormControl>
          <FormControl fullWidth error={Boolean(errors.Description)}>
            <InputLabel htmlFor="Description">Description</InputLabel>
            <Controller
              name="Description"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  autoComplete="court-description"
                  label="Description"
                />
              )}
            />
            <FormHelperText error>{errors.Description?.message}</FormHelperText>
          </FormControl>
          {errors.root && (
            <Box my={2} sx={{ width: '100%', textAlign: 'center' }}>
              <Typography color={'error'}>{errors.root?.message}</Typography>
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          color="primary"
          disabled={addCourtWorking || editCourtWorking}
        >
          {addCourtWorking || editCourtWorking ? 'Saving...' : 'Save'}
        </Button>
        <Button color="inherit" onClick={handleCancel}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
