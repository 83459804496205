import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { ConfirmDialog } from '../../../Components/Dialog/ConfirmDialog';
import { SiteContext } from '../../../Contexts/SiteContext';
import { BookingRule, NewBookingRule } from '../../../Model/BookingRule';
import { GetPriorityTypeName } from '../../../Model/Priority';
import { SlotType } from '../../../Model/SlotType';
import { GetPeriodTypeName, PeriodType } from '../../../Model/Time/PeriodType';
import { AnchorType, GetAnchorString } from '../../../Model/Time/TimeRange';
import { useDeleteBookingRuleMutation } from '../../../Service/BookingRules';
import { useGetKBQuery } from '../../../Service/KB';
import { useGetMemberTypesQuery } from '../../../Service/MemberTypes';
import { formatCurrency, formatQuantity } from '../../../Util/Format';
import { AddEditRuleDialog } from './AddEditRuleDialog';

function formatPeriod(
  periodValue: number,
  periodType: PeriodType,
  anchorType: AnchorType
): string {
  if (periodValue === 0) {
    return 'Anytime';
  }

  return (
    periodValue +
    ' ' +
    GetPeriodTypeName(periodType) +
    ' ' +
    GetAnchorString(anchorType)
  );
}
type BookingRuleViewProps = {
  slotType?: SlotType;
  rule?: BookingRule;
};
export const BookingRuleView = (props: BookingRuleViewProps) => {
  const siteCtx = useContext(SiteContext);

  const rule = props.rule || NewBookingRule();

  const { data: memberTypes } = useGetMemberTypesQuery(siteCtx.site.id);
  const memberType = memberTypes?.find((mt) => mt.ID === rule.MemberTypeID);
  const memberName = memberType ? memberType.Name : 'Public Booking';

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  function handleDeleteRequest(): void {
    setDeleteDialogOpen(true);
  }

  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const [deleteRule] = useDeleteBookingRuleMutation();
  async function handleDeleteConfirm(): Promise<void> {
    try {
      await deleteRule({ siteID: siteCtx.site.id, id: rule.ID });
    } catch (e) {
      //TODO: Feedback to user
      console.log(e);
    }
  }
  const { data: kb } = useGetKBQuery();

  const gameType = kb?.GameTypes.find((gt) => gt.ID === rule.GameTypeID);
  let gameName = gameType?.Name || 'All Game Types';
  const gameSubName = gameType?.Subtype;

  if (gameSubName && gameSubName.length > 0) {
    gameName += ' - ' + gameSubName;
  }

  function formatSlotQuantity(quantity: number, pt: PeriodType): string {
    return `${formatQuantity(quantity)} per ${GetPeriodTypeName(pt)}`;
  }

  return (
    <>
      <Card>
        {rule.SlotTypeID > 0 && (
          <CardHeader title={memberName} subheader={gameName} />
        )}
        {rule.SlotTypeID === 0 && (
          <CardHeader title={gameName} subheader="Rule" />
        )}

        <Divider />
        <CardContent>
          <Grid container alignItems={'center'} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ textDecoration: 'underline' }}>
                Amounts Category
              </Typography>
            </Grid>
            {props.slotType && (
              <>
                <Grid item xs={6}>
                  <Typography variant="body1">Amounts Priority</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {GetPriorityTypeName(rule.AmountsPriorityType)}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <Typography variant="body1">Max Simultaneous Bookings</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {formatQuantity(rule.MaxSimultaneousBookings)}
              </Typography>
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
              <Divider variant="middle" sx={{ width: '25%' }} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Free Slots</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {formatSlotQuantity(rule.NumFree, rule.NumFreePeriodType)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Paid Slots</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {formatSlotQuantity(rule.NumPaid, rule.NumPaidPeriodType)}
              </Typography>
            </Grid>
            {rule.GameTypeID > 0 && rule.NumPaid !== 0 && (
              <>
                <Grid item xs={6}>
                  <Typography variant="body1">Slot Price</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {formatCurrency(rule.PaidPrice)}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <Typography variant="body1">Max Slots</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {formatSlotQuantity(rule.NumMax, rule.NumMaxPeriodType)}
              </Typography>
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
              <Divider variant="middle" sx={{ width: '25%' }} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Free Public Invites</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {formatSlotQuantity(
                  rule.NumFreePublicInvites,
                  rule.NumFreePeriodType
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Paid Public Invites</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {formatSlotQuantity(
                  rule.NumPaidPublicInvites,
                  rule.NumPaidPeriodType
                )}
              </Typography>
            </Grid>
            {rule.GameTypeID > 0 && rule.NumPaidPublicInvites !== 0 && (
              <>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    Paid Public Invite Price
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {formatCurrency(rule.PaidInvitePrice)}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <Typography variant="body1">Max Public Invites</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {formatSlotQuantity(
                  rule.NumMaxPublicInvites,
                  rule.NumMaxPeriodType
                )}
              </Typography>
            </Grid>
            {rule.GameTypeID > 0 && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    style={{ textDecoration: 'underline' }}
                  >
                    Time Range Category
                  </Typography>
                </Grid>
                {props.slotType && (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        Time Range Priority
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        {GetPriorityTypeName(rule.TimeRangePriorityType)}
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                  <Divider variant="middle" sx={{ width: '25%' }} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Booking Start</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {formatPeriod(
                      rule.BookingStartPeriodValue,
                      rule.BookingStartPeriodType,
                      rule.BookingAnchorType
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Booking End</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {formatPeriod(
                      rule.BookingEndPeriodValue,
                      rule.BookingEndPeriodType,
                      rule.BookingAnchorType
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Cancellation Start</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {formatPeriod(
                      rule.CancellationStartPeriodValue,
                      rule.CancellationStartPeriodType,
                      rule.CancellationAnchorType
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Cancellation End</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {formatPeriod(
                      rule.CancellationEndPeriodValue,
                      rule.CancellationEndPeriodType,
                      rule.CancellationAnchorType
                    )}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button color="primary" onClick={() => setEditDialogOpen(true)}>
            Edit Rule
          </Button>
          <Button color="error" onClick={handleDeleteRequest}>
            Delete Rule
          </Button>
        </CardActions>
      </Card>
      <ConfirmDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        title="Delete Rule"
        message="Are you sure you want to delete this rule?"
        danger
        onConfirm={handleDeleteConfirm}
      />
      <AddEditRuleDialog
        rule={rule}
        setOpen={setEditDialogOpen}
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
      />
    </>
  );
};
