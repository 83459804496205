import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import DateSelector from '../../Components/UI/DateSelector';
import { today } from '../../Util/Dates';

type ToolBarType = {
  initialDate: DateTime;
  minDate?: DateTime;
  maxDate?: DateTime;
  onDateSet?: (date: DateTime) => void;
};

const ToolBar = (props: ToolBarType) => {
  const [date, setDate] = useState<DateTime>(props.initialDate);
  const minDate = props.minDate?.startOf('day') || today();
  const maxDate = props.maxDate?.startOf('day') || today();

  const handlePrevious = () => {
    if (date <= minDate) {
      return;
    }

    setDate((oldDate) => {
      const newDate = oldDate.minus({ days: 1 });
      if (props.onDateSet) {
        props.onDateSet(newDate);
      }

      return newDate;
    });
  };

  const handleNext = () => {
    if (date >= maxDate) {
      return;
    }
    setDate((oldDate) => {
      const newDate = oldDate.plus({ days: 1 });
      if (props.onDateSet) {
        props.onDateSet(newDate);
      }

      return newDate;
    });
  };

  const handleToday = () => {
    setDate(today());
    if (props.onDateSet) {
      props.onDateSet(today());
    }
  };

  function handleSetDate(date: DateTime<boolean>): void {
    if (date >= maxDate) {
      return;
    }
    if (date <= minDate) {
      return;
    }
    setDate(date);
    if (props.onDateSet) {
      props.onDateSet(date);
    }
  }

  const dateLabel = date.toFormat('EEE dd LLL');

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12}>
        <Stack justifyContent={'center'} direction="row" alignItems="center">
          <Button
            variant="outlined"
            onClick={handleToday}
            sx={{
              mx: 1,
            }}
          >
            Today
          </Button>
          <IconButton onClick={handlePrevious} disabled={date <= minDate}>
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton onClick={handleNext} disabled={date >= maxDate}>
            <KeyboardArrowRight />
          </IconButton>
          <DateSelector
            date={date}
            onSelectDate={handleSetDate}
            minDate={props.minDate}
            maxDate={props.maxDate}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" color="textSecondary">
          {dateLabel}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ToolBar;
