import { DateTime } from 'luxon';
import { Booking } from './Booking';
import { GameType, toShorthand } from './GameType';

export interface GuestAllowances {
  FreeLeft: number;
  PaidLeft: number;
  Cost: number;
}

export interface GameTypeCost {
  GameTypeID: number;
  Cost: number;
  GuestAllowances: GuestAllowances;
}

export interface GameTypeWithCost {
  GameType: GameType;
  Cost: number;
  GuestAllowances: GuestAllowances;
}

export function AddCostsToGameTypes(
  gameTypes: GameType[],
  costs?: GameTypeCost[],
  withAdminGames?: boolean
): GameTypeWithCost[] {
  if (!costs) {
    return [];
  }

  const gameTypeWithCosts = costs.map((c) => {
    const gameType = gameTypes.find(
      (g) => g.ID === c.GameTypeID && !g.AdminOnly
    );
    if (!gameType) {
      return {
        GameType: { ID: 0, Name: '', Subtype: '' },
        Cost: 0,
      } as GameTypeWithCost;
    }
    return {
      GameType: gameType,
      Cost: c.Cost,
      GuestAllowances: c.GuestAllowances,
    } as GameTypeWithCost;
  });

  if (withAdminGames) {
    gameTypes.forEach((gameType) => {
      if (!gameType.AdminOnly) {
        return;
      }

      gameTypeWithCosts.push({
        GameType: gameType,
        Cost: 0,
        GuestAllowances: {
          FreeLeft: 100,
          PaidLeft: 100,
          Cost: 0,
        },
      } as GameTypeWithCost);
    });
  }

  return gameTypeWithCosts;
}

export function FormatCosts(costs: GameTypeWithCost[]): string {
  if (!costs) {
    return '';
  }
  return costs
    .map((c) => {
      let formattedString = toShorthand(c.GameType);
      if (c.Cost > 0) {
        formattedString += `(€${c.Cost})`;
      }
      return formattedString;
    })
    .join(' | ')
    .toString();
}

export interface Event {
  SiteID: number;
  SlotID: number;
  SlotTypeID: number;
  CourtID: number;
  CourtName: string;
  Title: string;
  Start: string;
  End: string;
  GameTypeCosts?: GameTypeCost[];
  Booking?: Booking;
}

export function ToGameTypes(
  gt: GameType[],
  costs?: GameTypeCost[]
): GameType[] {
  if (!costs) {
    return [];
  }

  return gt.filter((g) => costs.find((c) => c.GameTypeID === g.ID));
}
const SortEvents = (a: Event, b: Event): number => {
  if (a.Start < b.Start) {
    return -1;
  }
  if (a.Start > b.Start) {
    return 1;
  }
  return 0;
};

export const SortByStartTime = (e: Event[]) => e.sort(SortEvents);

export type GroupedEvents = {
  [key: string]: Event[];
};

export const GroupEvents = (
  events: Event[],
  skipPassed: boolean = true
): GroupedEvents => {
  const now = DateTime.now();
  if (skipPassed) {
    events = events.filter((e) => DateTime.fromISO(e.Start) > now);
  }

  const sorted = SortByStartTime(events);
  const grouped = sorted.reduce((acc, e) => {
    const date = DateTime.fromISO(e.Start).toFormat('yyyy-MM-dd');
    acc[date] = acc[date] || [];
    acc[date].push(e);
    return acc;
  }, Object.create(null));

  return grouped;
};

export const filterEventsByDate = (
  events: GroupedEvents,
  startDate: DateTime,
  endDate: DateTime
): GroupedEvents => {
  const filteredEvents: GroupedEvents = {};
  Object.keys(events).forEach((key) => {
    const date = DateTime.fromISO(key);
    if (date >= startDate && date <= endDate) {
      filteredEvents[key] = events[key];
    }
  });
  return filteredEvents;
};
