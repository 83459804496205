import React from 'react';
import { Navigate } from 'react-router-dom';
import { hasToken } from '../Util/Store';

export const PrivateRoute = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  const { children } = props;

  return <>{hasToken() ? children : <Navigate to="/auth/login" />}</>;
};
