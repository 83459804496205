import { Chip, Grid } from '@mui/material';
import { Weekday, WeekdayNames } from '../../Model/Time/Weekday';

export type WeekdaySelectorProps = {
  value?: Weekday[];
  onSelect?(value: Weekday[]): void;
};

export const WeekdaySelector = (props: WeekdaySelectorProps) => {
  const { onSelect, value } = props;

  //TODO: Do we keep Weekday in a separate package?
  const toggleWeekday = (weekday: Weekday) => {
    if (!onSelect || !value) {
      return;
    }

    if (value?.includes(weekday)) {
      onSelect(value.filter((d) => d !== weekday));
    } else {
      onSelect([...value, weekday]);
    }
  };

  return (
    <Grid container my="0.5rem" spacing={1}>
      {Object.values(WeekdayNames).map((weekday) => (
        <Grid item key={weekday}>
          <Chip
            key={weekday}
            size="small"
            label={Weekday[weekday]}
            color={value?.includes(weekday) ? 'primary' : 'default'}
            onClick={() => toggleWeekday(weekday)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
