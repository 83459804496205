import { Add, Delete, Edit } from '@mui/icons-material';
import {
  Alert,
  Box,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Fragment, useState } from 'react';
import { ConfirmDialog } from '../../Components/Dialog/ConfirmDialog';
import { MemberType } from '../../Model/MemberType';
import { SnackBarInfo } from '../../Model/SnackBarInfo';
import { GetPeriodTypeName } from '../../Model/Time/PeriodType';
import { UserMembership } from '../../Model/UserMembership';
import {
  useDeleteMembershipMutation,
  useGetMembershipsQuery,
} from '../../Service/MemberTypes';
import { getErrorMessage } from '../../Util/Error';
import { AddMemberDialog } from './AddMemberDialog';
import { EditMemberDialog } from './EditMemberDialog';
import { UserMembershipChip } from './UserMembershipChip';

type ManageMembersProps = {
  memberType: MemberType;
};

export const ManageMembersGrid = (props: ManageMembersProps) => {
  const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);

  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarInfo>();
  const siteId = props.memberType.SiteID;
  const memberTypeId = props.memberType.ID;

  const handleOpenAddMemberDialog = (): void => {
    setOpenAddMemberDialog(true);
  };

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState<UserMembership>();

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [memberToEdit, setMemberToEdit] = useState<UserMembership>();

  const { data: memberships, isFetching } = useGetMembershipsQuery({
    SiteID: siteId,
    MemberTypeID: memberTypeId,
  });

  const [deleteMembership] = useDeleteMembershipMutation();
  const handleRemoveUser = async (um: UserMembership): Promise<void> => {
    try {
      await deleteMembership({
        SiteID: siteId,
        MemberTypeID: memberTypeId,
        UserID: um.ID,
        ID: um.ID,
      });
    } catch (error) {
      setSnackBarInfo({
        message: getErrorMessage(error),
        severity: 'error',
        open: true,
      });
    }
  };

  const handleOnCloseSnackBar = (): void => {
    setSnackBarInfo({
      message: '',
      severity: 'success',
      open: false,
    });
  };

  const columns = [
    {
      field: 'FirstName',
      headerName: 'First Name',
      flex: 1,
    },
    {
      field: 'LastName',
      headerName: 'Last Name',
      flex: 1,
    },
    {
      field: 'Email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'GracePeriodType',
      flex: 1,
      renderCell: (params: any) => {
        const member = params.row as UserMembership;
        return `${member.GracePeriodValue} ${GetPeriodTypeName(
          member.GracePeriodType
        )}(s)`;
      },
    },
    {
      field: 'ExpirationDate',
      headerName: 'Expiration Date',
      flex: 1,
      valueFormatter: (value: string) => {
        const date = new Date(value);
        return date.toLocaleDateString('en-US');
      },
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: any) => {
        const member = params.row as UserMembership;
        return (
          <>
            <IconButton
              title={`Edit ${member.FirstName}`}
              size="small"
              onClick={() => {
                setMemberToEdit(member);
                setOpenEditDialog(true);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              title={`Remove ${member.FirstName}`}
              size="small"
              onClick={() => {
                setMemberToDelete(member);
                setOpenConfirmDeleteDialog(true);
              }}
            >
              <Delete />
            </IconButton>
          </>
        );
      },
    },
  ];

  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <Fragment>
      <Grid container sx={{ p: 2 }} spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="flex-start"
          >
            <Fab
              color="primary"
              variant="extended"
              size="small"
              onClick={handleOpenAddMemberDialog}
              aria-label="add-member"
            >
              <Add />
            </Fab>
            <Typography>Add Members</Typography>
          </Stack>
        </Grid>
        {isFetching && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
        <Grid item xs={12}>
          {isMobile &&
            Array.from(memberships?.items || [])
              .sort((a, b) => a.FirstName.localeCompare(b.FirstName))
              .map((m) => (
                <UserMembershipChip
                  key={m.UserID}
                  membership={m}
                  onClick={() => {
                    setMemberToEdit(m);
                    setOpenEditDialog(true);
                  }}
                  onDelete={() => {
                    setMemberToDelete(m);
                    setOpenConfirmDeleteDialog(true);
                  }}
                />
              ))}
        </Grid>
      </Grid>
      {!isMobile && (
        <Box>
          <DataGrid
            disableMultipleRowSelection
            hideFooterPagination
            getRowId={(row) => row.UserID}
            columns={columns}
            rows={memberships?.items}
          ></DataGrid>
        </Box>
      )}
      <AddMemberDialog
        open={openAddMemberDialog}
        setOpen={setOpenAddMemberDialog}
        memberType={props.memberType}
      />
      <Snackbar
        open={snackBarInfo?.open}
        autoHideDuration={6000}
        onClose={handleOnCloseSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleOnCloseSnackBar}
          severity={snackBarInfo?.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackBarInfo?.message}
        </Alert>
      </Snackbar>
      <EditMemberDialog
        open={openEditDialog}
        setOpen={setOpenEditDialog}
        membership={memberToEdit}
      />
      <ConfirmDialog
        danger
        open={openConfirmDeleteDialog}
        value={memberToDelete}
        setOpen={setOpenConfirmDeleteDialog}
        onConfirm={handleRemoveUser}
        title="Remove Member"
        message="Are you sure you want to remove this member?"
      />
    </Fragment>
  );
};
