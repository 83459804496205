export enum LoadingStatus {
  LOADING,
  LOADED,
  ERROR,
  UNAUTHORIZED,
  FORBIDDEN,
}

export function isError(status: LoadingStatus): boolean {
  return (
    status === LoadingStatus.ERROR ||
    status === LoadingStatus.UNAUTHORIZED ||
    status === LoadingStatus.FORBIDDEN
  );
}
