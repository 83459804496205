import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { VivaWallet } from '../../../Model/Payments/VivaWallet';

export type Props = {
  value?: VivaWallet;
  onChange?: (value?: VivaWallet) => void;
  onBlur?: () => void;
  errors?: any;
  disabled?: boolean;
};
export const VivaSettings = forwardRef(function VivaSettings(
  props: Props,
  ref: any
) {
  const handleOnChange = (value?: VivaWallet): void => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <Grid container spacing={2} ref={ref}>
      <Grid item xs={12}>
        <Typography variant="h6">Viva Wallet</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl
          disabled={props.disabled}
          fullWidth
          error={!!props.errors?.ClientID}
        >
          <InputLabel>Client ID</InputLabel>
          <OutlinedInput
            value={props.value?.ClientID}
            label="Client ID"
            onChange={(e) =>
              handleOnChange({
                ...props.value,
                ClientID: e.target.value,
              } as VivaWallet)
            }
          />
          <FormHelperText>{props.errors?.ClientID?.message}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl
          disabled={props.disabled}
          fullWidth
          error={!!props.errors?.ClientSecret}
        >
          <InputLabel>Client Secret</InputLabel>
          <OutlinedInput
            value={props.value?.ClientSecret}
            label="Client Secret"
            onChange={(e) =>
              handleOnChange({
                ...props.value,
                ClientSecret: e.target.value,
              } as VivaWallet)
            }
          />
          <FormHelperText>{props.errors?.ClientSecret?.message}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl
          disabled={props.disabled}
          fullWidth
          error={!!props.errors?.MerchantID}
        >
          <InputLabel>Merchant ID</InputLabel>
          <OutlinedInput
            value={props.value?.MerchantID}
            label="Merchant ID"
            onChange={(e) =>
              handleOnChange({
                ...props.value,
                MerchantID: e.target.value,
              } as VivaWallet)
            }
          />
          <FormHelperText>{props.errors?.MerchantID?.message}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl
          disabled={props.disabled}
          fullWidth
          error={!!props.errors?.APIKey}
        >
          <InputLabel>API Key</InputLabel>
          <OutlinedInput
            value={props.value?.APIKey}
            label="API Key"
            onChange={(e) =>
              handleOnChange({
                ...props.value,
                APIKey: e.target.value,
              } as VivaWallet)
            }
          />
          <FormHelperText>{props.errors?.APIKey?.message}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl
          disabled={props.disabled}
          fullWidth
          error={!!props.errors?.BaseURL}
        >
          <InputLabel>BaseURL</InputLabel>
          <OutlinedInput
            value={props.value?.BaseURL}
            label="Base URL"
            onChange={(e) =>
              handleOnChange({
                ...props.value,
                BaseURL: e.target.value,
              } as VivaWallet)
            }
          />
          <FormHelperText>{props.errors?.BaseURL?.message}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl
          disabled={props.disabled}
          fullWidth
          error={!!props.errors?.PaymentSource}
        >
          <InputLabel>Payment Source</InputLabel>
          <NumericFormat
            value={props.value?.PaymentSource}
            label="Payment Source"
            min={0}
            onValueChange={({ floatValue }) => {
              handleOnChange({
                ...props.value,
                PaymentSource: floatValue,
              } as VivaWallet);
            }}
            customInput={OutlinedInput}
          />
          <FormHelperText>
            {props.errors?.PaymentSource?.message}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
});
