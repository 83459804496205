import { createApi } from '@reduxjs/toolkit/query/react';
import { Site } from '../Model/Site';
import { baseQuery } from './BaseQuery';
import { ErrorTags, getErrorTag } from './Error';

export type UpdateSiteRequest = {
  ID: number;
  Name: string;
  Description: string;
  Address: string;
  City: string;
  Province: string;
  PostalCode: string;
  Phone: string;
  Email: string;
  CountryID: number;
  Timezone: string;
  IsPrivate: boolean;
  AllowAnonymousGuest: boolean;
};

export const siteApi = createApi({
  reducerPath: 'siteApi',
  baseQuery,
  tagTypes: [...ErrorTags, 'Site'],
  endpoints: (build) => ({
    getSite: build.query<Site, number>({
      query: (id: number) => '/sites/' + id,
      providesTags: (_, error, id) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return ['Site-' + id];
      },
    }),
    updateSite: build.mutation<Site, UpdateSiteRequest>({
      query: (site: UpdateSiteRequest) => ({
        url: '/sites/' + site.ID,
        method: 'PUT',
        body: site,
      }),
      invalidatesTags: (_, error, site) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return ['Site-' + site.ID];
      },
    }),
  }),
});

export const { useGetSiteQuery, useUpdateSiteMutation } = siteApi;
