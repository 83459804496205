import { Box, Button, Divider, useTheme } from '@mui/material';
import { Fragment } from 'react';

type SlotButtonProps = {
  upperText: string;
  middleText: string;
  lowerText?: string;
  isPeakHours?: boolean;
  onClick?: () => void;
};
const SlotButton = (props: SlotButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      variant="outlined"
      onClick={props.onClick}
      color={props.isPeakHours ? 'secondary' : 'primary'}
      sx={{
        borderRadius: `${theme.border.radius}`,
        py: 0.5,
        px: 3,
        ':hover': {
          backgroundColor: `${theme.palette.primary.main}`,
          color: `white`,
        },
      }}
    >
      <Box>
        <Box fontSize={'1.2rem'}>{props.upperText}</Box>
        <Divider />
        <Box fontSize={'0.8rem'}>{props.middleText}</Box>
        {props.lowerText && (
          <Fragment>
            <Divider />
            <Box mt={0.5} fontSize={'0.5rem'}>
              {props.lowerText}
            </Box>
          </Fragment>
        )}
      </Box>
    </Button>
  );
};

export default SlotButton;
