import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import {
  GetPeriodTypeName,
  PeriodType,
  PeriodTypes,
} from '../../Model/Time/PeriodType';
import {
  AnchorType,
  Anchors,
  GetAnchorString,
  TimeRange,
  Validate,
} from '../../Model/Time/TimeRange';
import { NumberInput } from './NumberInput';

type RelativeTimeRangeInputProps = {
  value: TimeRange;
  onChange?: (value: TimeRange, valid?: boolean) => void;
};
export const RelativeTimeRangeInput = (props: RelativeTimeRangeInputProps) => {
  let periodTypes = [PeriodType.None, PeriodType.Hour];

  if (props.value.AnchorType !== AnchorType.AfterStartOfDay) {
    periodTypes = PeriodTypes;
  } else {
    if (props.value.StartPeriodType > PeriodType.Hour) {
      props.value.StartPeriodType = PeriodType.Hour;
    }
    if (props.value.EndPeriodType > PeriodType.Hour) {
      props.value.EndPeriodType = PeriodType.Hour;
    }
  }

  const [error, setError] = useState<string | undefined>(Validate(props.value));
  function handleOnChange(value: TimeRange): void {
    if (value.AnchorType === AnchorType.None) {
      value.EndPeriodType = PeriodType.None;
      value.EndPeriodValue = 0;
      value.StartPeriodType = PeriodType.None;
      value.StartPeriodValue = 0;
    }

    if (value.StartPeriodType === PeriodType.None) {
      value.StartPeriodValue = 0;
    } else if (value.StartPeriodValue === 0) {
      value.StartPeriodValue = 1;
    }

    if (value.EndPeriodType === PeriodType.None) {
      value.EndPeriodValue = 0;
    } else if (value.EndPeriodValue === 0) {
      value.EndPeriodValue = 1;
    }

    const errMsg = Validate(value);
    setError(errMsg);
    if (props.onChange) {
      props.onChange(value, errMsg === undefined);
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="anchor-select-label">Anchor</InputLabel>
            <Select
              variant="outlined"
              label="Anchor"
              fullWidth
              value={props.value?.AnchorType || AnchorType.None}
              onChange={(event) => {
                handleOnChange({
                  ...props.value,
                  AnchorType: Number(event.target.value),
                });
              }}
            >
              {Anchors.map((anchor) => (
                <MenuItem key={anchor} value={anchor}>
                  {anchor === AnchorType.None && (
                    <em>{GetAnchorString(anchor)}</em>
                  )}
                  {anchor !== AnchorType.None && GetAnchorString(anchor)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack direction="column" spacing={1}>
            <FormControl
              fullWidth
              disabled={props.value?.AnchorType === AnchorType.None}
            >
              <InputLabel id="period-select-label">
                Start Period Type
              </InputLabel>
              <Select
                variant="outlined"
                value={props.value?.StartPeriodType}
                onChange={(event) => {
                  handleOnChange({
                    ...props.value,
                    StartPeriodType: Number(event.target.value),
                  });
                }}
                label="Start Period Type"
                fullWidth
              >
                {periodTypes.map((periodType) => (
                  <MenuItem key={periodType} value={periodType}>
                    {periodType === PeriodType.None && (
                      <em>{GetPeriodTypeName(periodType)}</em>
                    )}
                    {periodType !== PeriodType.None &&
                      GetPeriodTypeName(periodType)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              disabled={props.value?.AnchorType === AnchorType.None}
            >
              <NumberInput
                value={props.value?.StartPeriodValue}
                disabled={
                  props.value?.AnchorType === AnchorType.None ||
                  props.value?.StartPeriodType === PeriodType.None
                }
                resetValue={1}
                min={1}
                onChange={(value) => {
                  handleOnChange({
                    ...props.value,
                    StartPeriodValue: value,
                  });
                }}
                label="Start Value"
              />
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack direction="column" spacing={1}>
            <FormControl
              fullWidth
              disabled={props.value?.AnchorType === AnchorType.None}
            >
              <InputLabel id="period-select-label">End Period Type</InputLabel>
              <Select
                variant="outlined"
                value={props.value?.EndPeriodType}
                onChange={(event) => {
                  handleOnChange({
                    ...props.value,
                    EndPeriodType: Number(event.target.value),
                  });
                }}
                label="End Period Type"
                fullWidth
              >
                {periodTypes.map((periodType) => (
                  <MenuItem key={periodType} value={periodType}>
                    {periodType === PeriodType.None && (
                      <em>{GetPeriodTypeName(periodType)}</em>
                    )}
                    {periodType !== PeriodType.None &&
                      GetPeriodTypeName(periodType)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              disabled={props.value?.AnchorType === AnchorType.None}
            >
              <NumberInput
                resetValue={1}
                min={1}
                disabled={
                  props.value?.AnchorType === AnchorType.None ||
                  props.value?.EndPeriodType === PeriodType.None
                }
                value={props.value?.EndPeriodValue}
                onChange={(value) => {
                  handleOnChange({
                    ...props.value,
                    EndPeriodValue: value,
                  });
                }}
                label="End Value"
              />
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <FormHelperText error id="standard-weight-helper-text-time-range">
            {error}
          </FormHelperText>
        </Grid>
      </Grid>
    </>
  );
};
