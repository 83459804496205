import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { LoginPath } from '../../Routes';
import { removeToken } from '../../Util/Store';

export const Logout = () => {
  removeToken();

  return (
    <Grid spacing={2} container justifyContent={'center'}>
      <Grid item>
        <Typography variant="body1">You have been logged out!</Typography>
      </Grid>
      <Grid item>
        <Typography
          component={Link}
          to={LoginPath}
          variant="subtitle1"
          color={'primary'}
          sx={{ textDecoration: 'none' }}
        >
          Click here to log back in
        </Typography>
      </Grid>
    </Grid>
  );
};
