import { CircularProgress, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { LoginPath, SendEmailConfirmationLinkPath } from '../../Routes';
import { toApiUrl } from '../../Util/Api';

export const ConfirmEmail = () => {
  const [searchParams] = useSearchParams();

  const [result, setResult] = useState<any>({
    success: false,
    error: false,
    message: '',
  });
  const token = searchParams.get('token');

  const onLoad = async () => {
    if (!token) {
      setResult({
        success: false,
        error: true,
        message: 'Token is missing',
      });
      return;
    }

    try {
      const res = await axios.post(toApiUrl('/auth/confirm-email'), { token });

      setResult({
        success: true,
        error: false,
        message: 'Your email has been confirmed!',
      });
      if (res.status === 208) {
        console.log('email already confirmed');
      }
    } catch (e: any) {
      if (e.response) {
        setResult({
          success: false,
          error: true,
          message: e.response.data.message,
        });
      } else if (e.message) {
        setResult({
          success: false,
          error: true,
          message: e.message,
        });
      } else {
        setResult({
          success: false,
          error: true,
          message: 'Something went wrong.',
        });
      }
    }
  };

  useEffect(() => {
    if (!result.success && !result.error) {
      onLoad();
    }
  });

  return (
    <Grid container spacing={2} direction="column" alignItems={'center'}>
      <Grid item alignItems={'center'}>
        <Typography variant="h6">Email Confirmation</Typography>
      </Grid>
      <Grid item>
        {token && !result.success && !result.error && (
          <Typography variant="subtitle1">
            Your email is being verified...
          </Typography>
        )}
      </Grid>
      {result.error && (
        <Grid item>
          <Typography color="error" variant="subtitle1">
            {result.message}
          </Typography>
        </Grid>
      )}
      {token && !result.success && !result.error && (
        <Grid item>
          <CircularProgress />
        </Grid>
      )}
      {result.success && (
        <>
          <Grid item>
            <Typography variant="subtitle1">{result.message}</Typography>
          </Grid>
          <Grid item>
            <Typography
              component={Link}
              color={'primary'}
              to={LoginPath}
              variant="subtitle1"
              sx={{ textDecoration: 'none' }}
            >
              Click here to login
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              Didn't receive an email? Check your spam or{' '}
              <Typography
                component={Link}
                color={'primary'}
                to={SendEmailConfirmationLinkPath}
                variant="subtitle1"
                sx={{ textDecoration: 'none' }}
              >
                click here to re-send it
              </Typography>
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
