import { Box, ButtonBase, Stack } from '@mui/material';
import './Logo.css';

const Logo = () => {
  return (
    <Stack direction={'row'} alignItems={'center'} mr={{ xs: 0, sm: 5 }}>
      <Box sx={{ mr: 1, ml: 1 }}>
        <ButtonBase>
          <img
            height="36"
            src="/images/full_trimmed_transparent_customcolor.png"
            alt="COURTGO"
          />
        </ButtonBase>
      </Box>
    </Stack>
  );
};

export default Logo;
