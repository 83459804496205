import { CircularProgress, Stack, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { SiteContext } from '../../../Contexts/SiteContext';
import { PaymentProviderType } from '../../../Model/Payments/PaymentProviderType';
import { OpenSlotsPath } from '../../../Routes';
import {
  useVerifyPaymentMutation,
  VerifyPaymentRequest,
} from '../../../Service/Payments';

type verificationStatus = 'pending' | 'success' | 'failed' | 'invalid';

export const PaymentSuccess = () => {
  let [params] = useSearchParams();

  const t = params.get('t');
  const s = params.get('s');

  const [verifyPayment] = useVerifyPaymentMutation();
  const siteID = useContext(SiteContext).site.id;

  const r: VerifyPaymentRequest = useMemo(() => {
    return {
      ProviderType: PaymentProviderType.VivaWallet,
      OrderID: s || '',
      TransactionID: t || '',
      SiteID: siteID,
    };
  }, [s, t, siteID]);

  const [verificationStatus, setVerificationStatus] =
    useState<verificationStatus>('pending');

  const handleVerifyPayment = useCallback(async () => {
    if (r.OrderID === '' || r.TransactionID === '' || r.SiteID === 0) {
      setVerificationStatus('invalid');
      return;
    }

    try {
      await verifyPayment(r).unwrap();
      setVerificationStatus('success');
    } catch (e) {
      setVerificationStatus('failed');
    }
  }, [verifyPayment, r]);

  useEffect(() => {
    handleVerifyPayment();
  }, [handleVerifyPayment]);

  return (
    <Stack
      direction="column"
      spacing={2}
      alignItems="center"
      textAlign={'center'}
    >
      {verificationStatus === 'pending' && <CircularProgress />}
      <Typography variant="h6">
        {verificationStatus === 'success' && 'Verification Successful'}
        {verificationStatus === 'failed' && 'Verification Failed'}
        {verificationStatus === 'pending' && 'Verifying Payment...'}
        {verificationStatus === 'invalid' && 'Invalid Request'}
      </Typography>
      <Typography variant="body1">
        {verificationStatus === 'success' && (
          <>Your payment has been verified.</>
        )}
        {verificationStatus === 'failed' && (
          <>
            Your payment with order ID '{s}' has failed. This doesn't mean it
            was cancelled though. Please contact the site administrator for more
            information.
          </>
        )}
        {verificationStatus === 'pending' && 'Verifying payment...'}
      </Typography>
      <Link to={OpenSlotsPath}>Return to Open Slots</Link>
    </Stack>
  );
};
