const storageKey = 'expansionState';

export function setExpanded(key: string, expanded: boolean): void {
  const expansions = JSON.parse(localStorage[storageKey] || '{}');
  expansions[key] = expanded;
  localStorage[storageKey] = JSON.stringify(expansions);
}

export function isExpanded(key: string): boolean {
  const expansions = JSON.parse(localStorage[storageKey] || '{}');
  return expansions[key] || false;
}

export function clearExpansionState(): void {
  localStorage.removeItem(storageKey);
}
