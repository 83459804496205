export interface SlotBucket {
  StartTimeMinutes: number;
  NumSlots: number;
  DurationMinutes: number;
  SlotTypeID: number;
}

export const SlotBucketEndMinutes = (bucket: SlotBucket): number => {
  return bucket.StartTimeMinutes + SlotBucketTotalDuration(bucket);
};

export const SlotBucketTotalDuration = (bucket: SlotBucket): number => {
  return bucket.DurationMinutes * bucket.NumSlots;
};

export const SlotBucketStartTimeLabel = (bucket: SlotBucket): string => {
  return MinutesToLabel(bucket.StartTimeMinutes);
};

export const SlotBucketEndTimeLabel = (bucket: SlotBucket): string => {
  return MinutesToLabel(SlotBucketEndMinutes(bucket));
};

export function MinutesToLabel(minutes: number): string {
  const hour = Math.floor(minutes / 60);
  const minute = minutes % 60;
  const minuteLabel = minute < 10 ? `0${minute}` : `${minute}`;
  const hourLabel = hour < 10 ? `0${hour}` : `${hour}`;
  return `${hourLabel}:${minuteLabel}`;
}
