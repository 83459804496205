import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import { forwardRef } from 'react';

// constant
const headerSX = {
  '& .MuiCardHeader-action': { mr: 0 },
};

// ==============================|| CUSTOM MAIN CARD ||============================== //
type MainCardProps = {
  border?: boolean;
  boxShadow?: boolean;
  children: React.ReactNode;
  content?: boolean;
  contentClass?: string;
  contentSX?: object;
  darkTitle?: boolean;
  secondary?: React.ReactNode;
  shadow?: string;
  sx?: object;
  title?: string | React.ReactNode;
  elevation?: number;
};
const MainCard = forwardRef<HTMLDivElement, MainCardProps>(
  (props: MainCardProps, ref) => {
    const theme = useTheme();

    return (
      <Card
        ref={ref}
        elevation={props.elevation || 0}
        sx={{
          border: props.border ? '1px solid' : 'none',
          borderRadius: `${theme.border.radius}`,
          borderColor: theme.palette.primary.main + 25,
          ...props.sx,
        }}
      >
        {/* card header and action */}
        {props.title && (
          <CardHeader
            sx={headerSX}
            title={
              props.darkTitle ? (
                <Typography variant="h3">{props.title}</Typography>
              ) : (
                props.title
              )
            }
            action={props.secondary}
          />
        )}

        {/* content & header divider */}
        {props.title && <Divider />}

        {/* card content */}
        {props.content && (
          <CardContent sx={props.contentSX} className={props.contentClass}>
            {props.children}
          </CardContent>
        )}
        {!props.content && props.children}
      </Card>
    );
  }
);

export default MainCard;
