import React from 'react';
import { Event } from '../../Model/Event';

type CalendarStateType = {
  date: Date;
  setDate(date: Date): void;
  maxDate?: Date;
  setMaxDate(date: Date): void;
  Events: Event[];
  setEvents(events: Event[]): void;
};

const initialCalendarState: CalendarStateType = {
  date: new Date(),
  Events: [],
  setMaxDate: () => {},
  setDate: () => {},
  setEvents: () => {},
};

const calendarStateReducer = (state: CalendarStateType, action: any) => {
  switch (action.type) {
    case 'setDate':
      return {
        ...state,
        date: action.payload,
      };
    case 'setSlots':
      return {
        ...state,
        Slots: action.payload,
      };
    case 'setMaxDate':
      return {
        ...state,
        maxDate: action.payload,
      };
    default:
      return state;
  }
};

const CalendarContext =
  React.createContext<CalendarStateType>(initialCalendarState);

export const CalendarContextProvider = (props: any) => {
  const [calendarState, dispatchCalendarState] = React.useReducer(
    calendarStateReducer,
    initialCalendarState
  );

  const setDateHandler = (date: Date) => {
    dispatchCalendarState({ type: 'setDate', payload: date });
  };

  const setSlotsHandler = (slots: Event[]) => {
    dispatchCalendarState({ type: 'setSlots', payload: slots });
  };

  const setMaxDateHandler = (date: Date) => {
    dispatchCalendarState({ type: 'setMaxDate', payload: date });
  };

  return (
    <CalendarContext.Provider
      value={{
        ...calendarState,
        setDate: setDateHandler,
        setEvents: setSlotsHandler,
        setMaxDate: setMaxDateHandler,
      }}
    >
      {props.children}
    </CalendarContext.Provider>
  );
};

export default CalendarContext;
