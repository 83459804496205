import { Add, Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Divider,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useContext, useState } from 'react';
import { ConfirmDialog } from '../../Components/Dialog/ConfirmDialog';
import {
  HasSitePerms,
  PermAddSiteEventType,
  PermsContext,
} from '../../Contexts/PermissionsContext';
import { SiteContext } from '../../Contexts/SiteContext';
import { EventType } from '../../Model/EventType';
import {
  useDeleteEventTypeMutation,
  useGetEventTypesQuery,
} from '../../Service/EventTypes';
import { AddEditEventTypeDialog } from './AddEditEventTypeDialog';

export const EventTypesPage = () => {
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const siteId = useContext(SiteContext).site.id;
  const {
    data: eventTypes,
    isLoading,
    isError,
  } = useGetEventTypesQuery(siteId);
  const permsCtx = useContext(PermsContext);
  const siteCtx = useContext(SiteContext);
  function handleOpenDialog(action: string, et?: EventType): void {
    setEditedEventType(et);
    switch (action) {
      case 'add':
      case 'edit':
        setOpenAddEditDialog(true);
        break;
      case 'delete':
        setOpenConfirmDeleteDialog(true);
        break;
    }
  }

  const [editedEventType, setEditedEventType] = useState<
    EventType | undefined
  >();

  const [deleteEventType] = useDeleteEventTypeMutation();
  async function handleConfirmDelete(value?: any): Promise<void> {
    console.debug('handleConfirmDelete', value);
    if (!value) {
      return;
    }
    await deleteEventType(value as EventType);
  }

  return (
    <>
      <Stack spacing={2}>
        <Stack spacing={3} alignItems="center" direction="row">
          {HasSitePerms(
            permsCtx.perms,
            siteCtx.site.id,
            PermAddSiteEventType
          ) && (
            <Tooltip title="Add Event Type">
              <Fab
                size="small"
                onClick={() => handleOpenDialog('add')}
                color="primary"
                aria-label="add-event-type"
              >
                <Add />
              </Fab>
            </Tooltip>
          )}
          <Typography variant="h6">Manage Event Types</Typography>
        </Stack>
        <Divider />
        <Grid
          container
          columns={{ xs: 3, sm: 4 }}
          display={'flex'}
          alignItems={'center'}
          textAlign="center"
          spacing={3}
        >
          <Grid item xs={1}>
            <Typography variant="body1">Name</Typography>
          </Grid>
          <Grid item xs={1} display={{ xs: 'none', sm: 'table-cell' }}>
            <Typography variant="body1">Description</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1">Color</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1">Actions</Typography>
          </Grid>
          <Grid item xs={4}>
            <Divider />
          </Grid>
          {isLoading && <LinearProgress />}
          {isError && <Typography color={'error'}>Failed to load</Typography>}
          {eventTypes?.map((et) => (
            <Fragment key={et.ID}>
              <Grid item xs={1}>
                <Typography variant="body2">{et.Name}</Typography>
              </Grid>
              <Grid item xs={1} display={{ xs: 'none', sm: 'table-cell' }}>
                <Typography variant="body2">{et.Description}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Box
                  display={'inline-block'}
                  sx={{
                    backgroundColor: et.Color,
                    height: '20px',
                    width: '20px',
                    borderRadius: '5px',
                  }}
                ></Box>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => handleOpenDialog('edit', et)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleOpenDialog('delete', et)}>
                  <Delete />
                </IconButton>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Stack>
      <ConfirmDialog
        message="Are you sure you want to delete this event type?"
        onConfirm={handleConfirmDelete}
        open={openConfirmDeleteDialog}
        setOpen={setOpenConfirmDeleteDialog}
        value={editedEventType}
        danger
        title="Delete Event Type"
      />
      <AddEditEventTypeDialog
        eventType={editedEventType}
        open={openAddEditDialog}
        setOpen={setOpenAddEditDialog}
      />
    </>
  );
};
