import { createApi } from '@reduxjs/toolkit/query/react';
import { Slot } from '../Model/Slot';
import { SlotBuckets } from '../Model/SlotBucketList';
import { baseQuery } from './BaseQuery';
import { ErrorTags, getErrorTag } from './Error';

export const slotsApi = createApi({
  reducerPath: 'slotsApi',
  baseQuery,
  tagTypes: [...ErrorTags, 'Slots'],
  endpoints: (build) => ({
    getSlots: build.query<Slot[], { siteID: number; courtID: number }>({
      query: ({ siteID, courtID }) =>
        `/sites/${siteID}/courts/${courtID}/slots`,
      providesTags: (result, error, args) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }

        return [{ type: 'Slots', id: `LIST-${args.courtID}` }];
      },
    }),
    addSlotBuckets: build.mutation<
      Slot[],
      { siteID: number; buckets: SlotBuckets }
    >({
      query: ({ siteID, buckets }) => ({
        url: `/sites/${siteID}/courts/${buckets.CourtID}/slots/buckets`,
        method: 'POST',
        body: buckets,
      }),
      invalidatesTags(result, error, args) {
        if (error?.status) {
          return getErrorTag(error?.status);
        }
        return [{ type: 'Slots', id: `LIST-${args.buckets.CourtID}` }];
      },
    }),
    deleteCourtSlots: build.mutation<void, { siteID: number; courtID: number }>(
      {
        query: ({ siteID, courtID }) => ({
          url: `/sites/${siteID}/courts/${courtID}/slots`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, { courtID }) => {
          if (error?.status) {
            return getErrorTag(error?.status);
          }

          return [{ type: 'Slots', id: `LIST-${courtID}` }];
        },
      }
    ),
    deleteSlot: build.mutation<void, { siteID: number; slot: Slot }>({
      query: ({ siteID, slot }) => ({
        url: `/sites/${siteID}/courts/${slot.CourtID}/slots/${slot.ID}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { slot }) => {
        if (error?.status) {
          return getErrorTag(error?.status);
        }

        return [{ type: 'Slots', id: `LIST-${slot.CourtID}` }];
      },
    }),
  }),
});

export const {
  useGetSlotsQuery,
  useAddSlotBucketsMutation,
  useDeleteSlotMutation,
  useDeleteCourtSlotsMutation,
} = slotsApi;
